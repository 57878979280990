/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { convertFromServer, convertFromTaskLocationInfoServer, WaterTask } from '../interfaces/water-task';
import { Itac } from '../interfaces/itac';
import { Counter } from '../interfaces/counter';
import { WaterRoute } from '../interfaces/water-route';
import { environment } from '../../environments/environment';
import { ActivationLog } from '../interfaces/activation-log';
import { RadiusModule } from '../interfaces/radius-module';
import { IntegrationItelazpi } from '../interfaces/integration-itelazpi';
import { Side } from '../interfaces/side';
import { TaskLocationInfo } from '../interfaces/task-location-info';
import { PendingCall } from '../interfaces/pending-call';

@Injectable({
    providedIn: 'root',
})
export class MySqlService {
    url_server: string = environment.url_server;

    private _tasksTableName = 'water-task'; //@Controller in nest backend
    private _sidesTableName = 'water-task/sides'; //@Controller in nest backend
    private _itacsTableName = 'itac'; //@Controller in nest backend
    private _countersTableName = 'counter'; //@Controller in nest backend
    private _activationLogsTableName = 'activation-logs'; //@Controller in nest backend
    private _pendingCallsTableName = 'vonage'; //@Controller in nest backend
    private _integrationItelazpisTableName = 'integration-itelazpi'; //@Controller in nest backend
    private _radiusModulesTableName = 'radius-module'; //@Controller in nest backend
    private _waterRoutesTableName = 'water-route'; //@Controller in nest backend

    last_select?: string;
    last_where?: string;
    last_order?: string;
    last_group_by?: string;
    last_offset?: string = '0';
    last_limit?: string = '10';

    filter_select?: string;
    filter_where?: string;
    filter_order?: string;
    filter_offset?: string = '0';
    filter_limit?: string = '10';

    last_side_select?: string;
    last_side_where?: string;
    last_side_order?: string;
    last_side_offset?: string = '0';
    last_side_limit?: string = '10';

    filter_side_select?: string;
    filter_side_where?: string;
    filter_side_order?: string;
    filter_side_offset?: string = '0';
    filter_side_limit?: string = '10';

    last_itac_select?: string;
    last_itac_where?: string;
    last_itac_order?: string;
    last_itac_offset?: string = '0';
    last_itac_limit?: string = '10';

    filter_itac_select?: string;
    filter_itac_where?: string;
    filter_itac_order?: string;
    filter_itac_offset?: string = '0';
    filter_itac_limit?: string = '10';

    last_counter_select?: string;
    last_counter_where?: string;
    last_counter_order?: string;
    last_counter_offset?: string = '0';
    last_counter_limit?: string = '10';

    filter_counter_select?: string;
    filter_counter_where?: string;
    filter_counter_order?: string;
    filter_counter_offset?: string = '0';
    filter_counter_limit?: string = '10';

    last_waterRoute_select?: string;
    last_waterRoute_where?: string;
    last_waterRoute_order?: string;
    last_waterRoute_offset?: string = '0';
    last_waterRoute_limit?: string = '10';

    filter_waterRoute_select?: string;
    filter_waterRoute_where?: string;
    filter_waterRoute_order?: string;
    filter_waterRoute_offset?: string = '0';
    filter_waterRoute_limit?: string = '10';

    last_activationLog_select?: string;
    last_activationLog_where?: string;
    last_activationLog_order?: string;
    last_activationLog_offset?: string = '0';
    last_activationLog_limit?: string = '10';

    filter_activationLog_select?: string;
    filter_activationLog_where?: string;
    filter_activationLog_order?: string;
    filter_activationLog_offset?: string = '0';
    filter_activationLog_limit?: string = '10';

    last_pendingCall_select?: string;
    last_pendingCall_where?: string;
    last_pendingCall_order?: string;
    last_pendingCall_offset?: string = '0';
    last_pendingCall_limit?: string = '10';

    filter_pendingCall_select?: string;
    filter_pendingCall_where?: string;
    filter_pendingCall_order?: string;
    filter_pendingCall_offset?: string = '0';
    filter_pendingCall_limit?: string = '10';

    last_integrationItelazpi_select?: string;
    last_integrationItelazpi_where?: string;
    last_integrationItelazpi_order?: string;
    last_integrationItelazpi_offset?: string = '0';
    last_integrationItelazpi_limit?: string = '10';

    filter_integrationItelazpi_select?: string;
    filter_integrationItelazpi_where?: string;
    filter_integrationItelazpi_order?: string;
    filter_integrationItelazpi_offset?: string = '0';
    filter_integrationItelazpi_limit?: string = '10';

    last_radiusModule_select?: string;
    last_radiusModule_where?: string;
    last_radiusModule_order?: string;
    last_radiusModule_offset?: string = '0';
    last_radiusModule_limit?: string = '10';

    filter_radiusModule_select?: string;
    filter_radiusModule_where?: string;
    filter_radiusModule_order?: string;
    filter_radiusModule_offset?: string = '0';
    filter_radiusModule_limit?: string = '10';

    public get tasksTableName() {
        return this._tasksTableName;
    }
    public set tasksTableName(value) {
        this._tasksTableName = value;
    }

    public get sidesTableName() {
        return this._sidesTableName;
    }
    public set sidesTableName(value) {
        this._sidesTableName = value;
    }

    public get itacsTableName() {
        return this._itacsTableName;
    }
    public set itacsTableName(value) {
        this._itacsTableName = value;
    }

    public get countersTableName() {
        return this._countersTableName;
    }
    public set countersTableName(value) {
        this._countersTableName = value;
    }

    public get activationLogsTableName() {
        return this._activationLogsTableName;
    }
    public set activationLogsTableName(value) {
        this._activationLogsTableName = value;
    }

    public get pendingCallsTableName() {
        return this._pendingCallsTableName;
    }
    public set pendingCallsTableName(value) {
        this._pendingCallsTableName = value;
    }

    public get integrationItelazpisTableName() {
        return this._integrationItelazpisTableName;
    }
    public set integrationItelazpisTableName(value) {
        this._integrationItelazpisTableName = value;
    }

    public get radiusModulesTableName() {
        return this._radiusModulesTableName;
    }
    public set radiusModulesTableName(value) {
        this._radiusModulesTableName = value;
    }

    public get waterRoutesTableName() {
        return this._waterRoutesTableName;
    }
    public set waterRoutesTableName(value) {
        this._waterRoutesTableName = value;
    }

    constructor(private http: HttpClient) {}

    async getTaskTypeSummary(taskIds: number[]): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            if (taskIds && taskIds.length) {
                const params = {
                    ids: taskIds,
                };
                this.http
                    .post(`${this.url_server}/${this.tasksTableName}/types_summary`, params)
                    .subscribe(async (data: any) => {
                        if (data) {
                            let mySqlResults: any[] = [];
                            const mySqlAnswer = data;
                            mySqlAnswer.forEach((mySqlResult: any) => {
                                mySqlResults.push(
                                    `${mySqlResult['total']} - ${mySqlResult['tipo_tarea']}`
                                );
                            });
                            resolve(mySqlResults);
                        } else {
                            resolve([]);
                        }
                    });
            } else {
                reject([]);
            }
        });
    }

    async getTaskTypeSummaryWhere(where_clause: string): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};
            if (where_clause) params.where = where_clause;
            this.http
                .post(`${this.url_server}/${this.tasksTableName}/types_summary_where`, params)
                .subscribe(async (data: any) => {
                    if (data) {
                        let mySqlResults: any[] = [];
                        const mySqlAnswer = data;
                        mySqlAnswer.forEach((mySqlResult: any) => {
                            mySqlResults.push(
                                `${mySqlResult['total']} - ${mySqlResult['tipo_tarea']}`
                            );
                        });
                        resolve(mySqlResults);
                    } else {
                        resolve([]);
                    }
                });
        });
    }

    async getTaskTypeSummaryByEmplacement(
        emplacement_codes: string[],
        status: number, 
        absent: boolean, 
        pendent_date: boolean
    ): Promise<any[]> {
        return new Promise<any[]>(async (resolve, reject) => {
            if (emplacement_codes && emplacement_codes.length) {
                const params = {
                    emplacement_codes: emplacement_codes,
                    company: parseInt(localStorage.getItem('company')!),
                    manager: parseInt(localStorage.getItem('manager')!),
                    status: status,
                    absent: absent,
                    pendent_date: pendent_date
                };
                this.http
                    .post(`${this.url_server}/${this.tasksTableName}/types_summary_by_emplacement_code`, params)
                    .subscribe(async (data: any) => {
                        if (data) resolve(data);
                        else resolve([]);
                        
                    });
            } else {
                reject([]);
            }
        });
    }

    getCompanyWhereClause(where_clause?: string) {
        let company = localStorage.getItem('company');
        let whereJson = JSON.parse(where_clause || '[]');
        whereJson.push(this.getFieldJson('company', company));
        return JSON.stringify(whereJson);
    }

    getCompanyManagerWhereClause(where_clause?: string) {
        let company = localStorage.getItem('company');
        let manager = localStorage.getItem('manager');
        let whereJson = JSON.parse(where_clause || '[]');
        whereJson.push(this.getFieldJson('company', company));
        whereJson.push(this.getFieldJson('GESTOR', manager));
        return JSON.stringify(whereJson);
    }

    getItacCompanyManagerWhereClause(where_clause?: string) {
        let company = localStorage.getItem('company');
        let manager = localStorage.getItem('manager');
        let whereJson = JSON.parse(where_clause || '[]');
        whereJson.push(this.getFieldJson('company', company));
        whereJson.push(this.getFieldJson('gestor', manager));
        return JSON.stringify(whereJson);
    }

    getExtraCompanyManagerWhereClause(where_clause?: string) {
        let company = localStorage.getItem('company');
        let manager = localStorage.getItem('manager');
        let whereJson = JSON.parse(where_clause || '[]');
        whereJson.push(this.getFieldJson('company', company));
        whereJson.push(this.getFieldJson('manager', manager));
        return JSON.stringify(whereJson);
    }

    getFieldJson(field: string, value: any){
        let companyJson: any = {};
        companyJson['field'] = field;
        companyJson['value'] = value;
        companyJson['type'] = 'AND';
        return companyJson;
    }

    async getTasksFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        group_by_clause?: string,
        having_clause?: string
    ) {
        where_clause = this.getCompanyManagerWhereClause(where_clause);
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_select = params.select;
            this.filter_where = params.where;
            this.filter_order = params.order;
            this.filter_offset = params.offset;
            this.filter_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.tasksTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getSidesFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        group_by_clause?: string,
        having_clause?: string
    ) {
        where_clause = this.getCompanyManagerWhereClause(where_clause);
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_side_select = params.select;
            this.filter_side_where = params.where;
            this.filter_side_order = params.order;
            this.filter_side_offset = params.offset;
            this.filter_side_limit = params.limit;
        
            this.http
                .post(`${this.url_server}/${this.tasksTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getItacsFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        group_by_clause?: string,
        having_clause?: string
    ) {
        where_clause = this.getItacCompanyManagerWhereClause(where_clause);
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_itac_select = params.select;
            this.filter_itac_where = params.where;
            this.filter_itac_order = params.order;
            this.filter_itac_offset = params.offset;
            this.filter_itac_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.itacsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastTasksFieldValues(offset: string) {
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};

            if (this.filter_select) params.select = this.filter_select;
            if (this.filter_where) params.where = this.filter_where;
            if (this.filter_order) params.order = this.filter_order;
            if (this.filter_limit) params.limit = this.filter_limit;
            this.filter_offset = (
                parseInt(this.filter_offset!) + parseInt(this.filter_limit!)
            ).toString();
            if (this.filter_offset) params.offset = this.filter_offset;

            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${this.tasksTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastSidesFieldValues(offset: string) {
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};

            if (this.filter_side_select) params.select = this.filter_side_select;
            if (this.filter_side_where) params.where = this.filter_side_where;
            if (this.filter_side_order) params.order = this.filter_side_order;
            if (this.filter_side_limit) params.limit = this.filter_side_limit;
            this.filter_side_offset = (
                parseInt(this.filter_side_offset!) + parseInt(this.filter_side_limit!)
            ).toString();
            if (this.filter_side_offset) params.offset = this.filter_side_offset;

            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${this.tasksTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastItacsFieldValues(offset: string) {
        return new Promise<WaterTask[]>(async (resolve, reject) => {
            const params: any = {};

            if (this.filter_itac_select) params.select = this.filter_itac_select;
            if (this.filter_itac_where) params.where = this.filter_itac_where;
            if (this.filter_itac_order) params.order = this.filter_itac_order;
            if (this.filter_itac_limit) params.limit = this.filter_itac_limit;
            this.filter_itac_offset = (
                parseInt(this.filter_itac_offset!) + parseInt(this.filter_itac_limit!)
            ).toString();
            if (this.filter_itac_offset) params.offset = this.filter_itac_offset;

            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${this.itacsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getCountersFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_counter_select = params.select;
            this.filter_counter_where = params.where;
            this.filter_counter_order = params.order;
            this.filter_counter_offset = params.offset;
            this.filter_counter_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.countersTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getActivationLogsFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_activationLog_select = params.select;
            this.filter_activationLog_where = params.where;
            this.filter_activationLog_order = params.order;
            this.filter_activationLog_offset = params.offset;
            this.filter_activationLog_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.activationLogsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getPendingCallsFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_pendingCall_select = params.select;
            this.filter_pendingCall_where = params.where;
            this.filter_pendingCall_order = params.order;
            this.filter_pendingCall_offset = params.offset;
            this.filter_pendingCall_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.pendingCallsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getIntegrationItelazpisFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_integrationItelazpi_select = params.select;
            this.filter_integrationItelazpi_where = params.where;
            this.filter_integrationItelazpi_order = params.order;
            this.filter_integrationItelazpi_offset = params.offset;
            this.filter_integrationItelazpi_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.integrationItelazpisTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getRadiusModulesFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_radiusModule_select = params.select;
            this.filter_radiusModule_where = params.where;
            this.filter_radiusModule_order = params.order;
            this.filter_radiusModule_offset = params.offset;
            this.filter_radiusModule_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.radiusModulesTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastCountersFieldValues(offset: string) {
        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};

            params.select = this.filter_counter_select;
            params.where = this.filter_counter_where;
            params.order = this.filter_counter_order;
            params.offset = this.filter_counter_offset;
            params.limit = this.filter_counter_limit;

            this.filter_counter_offset = (
                parseInt(this.filter_counter_offset!) + parseInt(this.filter_counter_limit!)
            ).toString();
            if (this.filter_counter_offset) params.offset = this.filter_counter_offset;
            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${this.countersTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getWaterRouteFieldValues(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ) {
        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);

        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.filter_waterRoute_select = params.select;
            this.filter_waterRoute_where = params.where;
            this.filter_waterRoute_order = params.order;
            this.filter_waterRoute_offset = params.offset;
            this.filter_waterRoute_limit = params.limit;

            this.http
                .post(`${this.url_server}/${this.waterRoutesTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastWaterRouteTableFieldValues(table_name: string, offset: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};

            params.select = this.filter_waterRoute_select;
            params.where = this.filter_waterRoute_where;
            params.order = this.filter_waterRoute_order;
            params.limit = this.filter_waterRoute_limit;

            this.filter_waterRoute_offset = (
                parseInt(this.filter_waterRoute_offset!) + parseInt(this.filter_waterRoute_limit!)
            ).toString();
            if (this.filter_waterRoute_offset) params.offset = this.filter_waterRoute_offset;
            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${table_name}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastActivationLogTableFieldValues(table_name: string, offset: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};

            params.select = this.filter_activationLog_select;
            params.where = this.filter_activationLog_where;
            params.order = this.filter_activationLog_order;
            params.limit = this.filter_activationLog_limit;

            this.filter_activationLog_offset = (
                parseInt(this.filter_activationLog_offset!) + parseInt(this.filter_activationLog_limit!)
            ).toString();
            if (this.filter_activationLog_offset) params.offset = this.filter_activationLog_offset;
            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${table_name}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastPendingCallTableFieldValues(table_name: string, offset: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};

            params.select = this.filter_pendingCall_select;
            params.where = this.filter_pendingCall_where;
            params.order = this.filter_pendingCall_order;
            params.limit = this.filter_pendingCall_limit;

            this.filter_pendingCall_offset = (
                parseInt(this.filter_pendingCall_offset!) + parseInt(this.filter_pendingCall_limit!)
            ).toString();
            if (this.filter_pendingCall_offset) params.offset = this.filter_pendingCall_offset;
            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${table_name}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getLastIntegrationItelazpiTableFieldValues(table_name: string, offset: string) {
        return new Promise<any[]>(async (resolve, reject) => {
            const params: any = {};

            params.select = this.filter_integrationItelazpi_select;
            params.where = this.filter_integrationItelazpi_where;
            params.order = this.filter_integrationItelazpi_order;
            params.limit = this.filter_integrationItelazpi_limit;

            this.filter_integrationItelazpi_offset = (
                parseInt(this.filter_integrationItelazpi_offset!) + parseInt(this.filter_integrationItelazpi_limit!)
            ).toString();
            if (this.filter_integrationItelazpi_offset) params.offset = this.filter_integrationItelazpi_offset;
            if (offset) params.offset = offset;

            this.http
                .post(`${this.url_server}/${table_name}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getTasks(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        group_by_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        save_query: boolean = true
    ): Promise<{waterTasks: WaterTask[], count: number}> {
        if (save_query) {
            this.last_select = select_clause;
            this.last_where = where_clause;
            this.last_order = order_clause;
            this.last_group_by = group_by_clause;
            this.last_offset = offset_clause;
            this.last_limit = limit_clause;
        }
        where_clause = this.getCompanyManagerWhereClause(where_clause);

        return new Promise<{waterTasks: WaterTask[], count: number}>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (group_by_clause) params.group_by = group_by_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.tasksTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        let waterTasks: WaterTask[] = [];
                        waterTasks = data.data.map((task: any) => convertFromServer(task));
                        resolve({ waterTasks: waterTasks, count: parseInt(data.count) });
                    } else {
                        reject();
                    }
                });
        });
    }

    async getTasksLocationInfo(
        where_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
    ): Promise<TaskLocationInfo[]> {
        where_clause = this.getCompanyManagerWhereClause(where_clause);

        return new Promise<TaskLocationInfo[]>(async (resolve, reject) => {
            const params: any = {};
            if (where_clause) params.where = where_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.tasksTableName}/location-info`, params)
                .subscribe(async (data: any) => {
                    if (data) {
                        let tasksLocationInfo: TaskLocationInfo[] = [];
                        tasksLocationInfo = data.map((t: any) => convertFromTaskLocationInfoServer(t));
                        resolve(tasksLocationInfo);
                    } else reject();
                });
        });
    }

    async getSides(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        save_query: boolean = true
    ): Promise<Side[]> {
        if (save_query) {
            this.last_side_select = select_clause;
            this.last_side_where = where_clause;
            this.last_side_order = order_clause;
            this.last_side_offset = offset_clause;
            this.last_side_limit = limit_clause;
        }

        where_clause = this.getCompanyManagerWhereClause(where_clause);

        return new Promise<Side[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http.post(`${this.url_server}/${this.sidesTableName}`, params).subscribe(async (data: any) => {
                if (data) resolve(data);
                else reject();
            });
        });
    }

    async getItacs(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string,
        save_query: boolean = true
    ): Promise<Itac[]> {
        if (save_query) {
            this.last_itac_select = select_clause;
            this.last_itac_where = where_clause;
            this.last_itac_order = order_clause;
            this.last_itac_offset = offset_clause;
            this.last_itac_limit = limit_clause;
        }

        where_clause = this.getItacCompanyManagerWhereClause(where_clause);

        return new Promise<Itac[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http.post(`${this.url_server}/${this.itacsTableName}/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    resolve(data.data);
                } else {
                    reject();
                }
            });
        });
    }

    async getCounters(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<Counter[]> {
        this.last_counter_select = select_clause;
        this.last_counter_where = where_clause;
        this.last_counter_order = order_clause;
        this.last_counter_offset = offset_clause;
        this.last_counter_limit = limit_clause;

        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<Counter[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.countersTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getActivationLogs(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<ActivationLog[]> {
        this.last_activationLog_select = select_clause;
        this.last_activationLog_where = where_clause;
        this.last_activationLog_order = order_clause;
        this.last_activationLog_offset = offset_clause;
        this.last_activationLog_limit = limit_clause;

        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<ActivationLog[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.activationLogsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) resolve(data.data);
                    else reject();
                });
        });
    }

    async getPendingCalls(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<PendingCall[]> {
        this.last_pendingCall_select = select_clause;
        this.last_pendingCall_where = where_clause;
        this.last_pendingCall_order = order_clause;
        this.last_pendingCall_offset = offset_clause;
        this.last_pendingCall_limit = limit_clause;

        // where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<PendingCall[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.pendingCallsTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) resolve(data.data);
                    else reject();
                });
        });
    }

    async getIntegrationItelazpis(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<IntegrationItelazpi[]> {
        this.last_integrationItelazpi_select = select_clause;
        this.last_integrationItelazpi_where = where_clause;
        this.last_integrationItelazpi_order = order_clause;
        this.last_integrationItelazpi_offset = offset_clause;
        this.last_integrationItelazpi_limit = limit_clause;

        where_clause = this.getCompanyWhereClause(where_clause);

        return new Promise<IntegrationItelazpi[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.integrationItelazpisTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getRadiusModules(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<RadiusModule[]> {
        this.last_radiusModule_select = select_clause;
        this.last_radiusModule_where = where_clause;
        this.last_radiusModule_order = order_clause;
        this.last_radiusModule_offset = offset_clause;
        this.last_radiusModule_limit = limit_clause;

        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);

        return new Promise<RadiusModule[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.radiusModulesTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getWaterRoutes(
        select_clause?: string,
        where_clause?: string,
        order_clause?: string,
        offset_clause?: string,
        limit_clause?: string
    ): Promise<WaterRoute[]> {
        this.last_waterRoute_select = select_clause;
        this.last_waterRoute_where = where_clause;
        this.last_waterRoute_order = order_clause;
        this.last_waterRoute_offset = offset_clause;
        this.last_waterRoute_limit = limit_clause;

        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);

        return new Promise<WaterRoute[]>(async (resolve, reject) => {
            const params: any = {};
            if (select_clause) params.select = select_clause;
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;
            if (offset_clause) params.offset = offset_clause;
            if (limit_clause) params.limit = limit_clause;

            this.http
                .post(`${this.url_server}/${this.waterRoutesTableName}/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * @param  {string} where_clause:
     */
    async checkIfTaskExist(where_clause?: string) {
        const count = await this.getTasksCount(where_clause);
        return count > 0;
    }

    async getTasksCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getCompanyManagerWhereClause(where_clause);
        return this.getCount(this.tasksTableName, where_clause, order_clause);
    }

    async getSidesCount(where_clause?: string): Promise<number> {
        where_clause = this.getCompanyManagerWhereClause(where_clause);
        return this.getCount(this.sidesTableName, where_clause);
    }

    async getItacsCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getItacCompanyManagerWhereClause(where_clause);
        return this.getCount(this.itacsTableName, where_clause, order_clause);
    }

    async getCountersCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getCompanyWhereClause(where_clause);
        return this.getCount(this.countersTableName, where_clause, order_clause);
    }

    async getActivationLogsCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getCompanyWhereClause(where_clause);
        return this.getCount(this.activationLogsTableName, where_clause, order_clause);
    }

    async getPendingCallsCount(where_clause?: string, order_clause?: string): Promise<number> {
        // where_clause = this.getCompanyWhereClause(where_clause);
        return this.getCount(this.pendingCallsTableName, where_clause, order_clause);
    }

    async getIntegrationItelazpisCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getCompanyWhereClause(where_clause);
        return this.getCount(this.integrationItelazpisTableName, where_clause, order_clause);
    }

    async getRadiusModulesCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);
        return this.getCount(this.radiusModulesTableName, where_clause, order_clause);
    }

    async getWaterRoutesCount(where_clause?: string, order_clause?: string): Promise<number> {
        where_clause = this.getExtraCompanyManagerWhereClause(where_clause);
        return this.getCount(this.waterRoutesTableName, where_clause, order_clause);
    }

    async getCount(
        table_name: string,
        where_clause?: string,
        order_clause?: string
    ): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            const params: any = {};
            if (where_clause) params.where = where_clause;
            if (order_clause) params.order = order_clause;

            this.http
                .post(`${this.url_server}/${table_name}/count`, params)
                .subscribe(async (data: any) => {
                    if (data) resolve(parseInt(data.count));
                    else reject();
                });
        });
    }

    async isActivationPendentCounters(): Promise<boolean> {
        const company = localStorage.getItem('company');
        const where =
            '[{ "field": "activation_pendent", "value": "1", "type": "AND" },' +
            `{ "field": "company", "value": "${company}", "type": "AND" }]`;

        const count: number = await this.getCount(this.countersTableName, where);
        if (count) return true;
        return false;
    }

    async getNextTasksPage(jump: number): Promise<{waterTasks: WaterTask[], count: number}> {
        let limit: number = 500;
        let offset: number = 0;

        if (!this.last_limit) {
            this.last_limit = '500';
        } else {
            limit = parseInt(this.last_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_offset) {
            this.last_offset = '0';
        }
        offset = parseInt(this.last_offset);
        if (!offset) {
            offset = 0;
        }
        offset += limit * jump;

        return this.getTasks(
            this.last_select,
            this.last_where,
            this.last_order,
            this.last_group_by,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousTasksPage(jump: number): Promise<{waterTasks: WaterTask[], count: number}> {
        let limit: number = 500;
        let offset: number = 0;

        if (!this.last_limit) {
            this.last_limit = '500';
        } else {
            limit = parseInt(this.last_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_offset) {
            this.last_offset = '0';
        }
        offset = parseInt(this.last_offset);
        if (!offset) {
            offset = 0;
        }
        offset -= limit * jump;
        if (offset < 0) {
            offset = 0;
        }

        return this.getTasks(
            this.last_select,
            this.last_where,
            this.last_order,
            this.last_group_by,
            offset.toString(),
            limit.toString()
        );
    }

    async getNextSidesPage(jump: number): Promise<Side[]> {
        let limit: number = 500;
        let offset: number = 0;

        if (!this.last_side_limit) this.last_side_limit = '500';
        else {
            limit = parseInt(this.last_side_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_side_offset) this.last_side_offset = '0';
        offset = parseInt(this.last_side_offset);

        if (!offset) offset = 0;
        offset += limit * jump;

        return this.getSides(
            this.last_side_select,
            this.last_side_where,
            this.last_side_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousSidesPage(jump: number): Promise<Side[]> {
        let limit: number = 500;
        let offset: number = 0;

        if (!this.last_side_limit) this.last_side_limit = '500';
        else {
            limit = parseInt(this.last_side_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_side_offset) this.last_side_offset = '0';
        
        offset = parseInt(this.last_side_offset);
        if (!offset) offset = 0;
        
        offset -= limit * jump;
        if (offset < 0) offset = 0;
        
        return this.getSides(
            this.last_side_select,
            this.last_side_where,
            this.last_side_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastTasksPage(): Promise<{waterTasks: WaterTask[], count: number}> {
        return this.getTasks(
            this.last_select,
            this.last_where,
            this.last_order,
            this.last_group_by,
            this.last_offset,
            this.last_limit
        );
    }

    async getNextItacsPage(jump: number): Promise<Itac[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_itac_limit) {
            this.last_itac_limit = '500';
        } else {
            limit = parseInt(this.last_itac_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_itac_offset) {
            this.last_itac_offset = '0';
        }
        offset = parseInt(this.last_itac_offset);
        if (!offset) {
            offset = 0;
        }
        offset += limit * jump;

        return this.getItacs(
            this.last_itac_select,
            this.last_itac_where,
            this.last_itac_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousItacsPage(jump: number): Promise<Itac[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_itac_limit) {
            this.last_itac_limit = '500';
        } else {
            limit = parseInt(this.last_itac_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_itac_offset) {
            this.last_itac_offset = '0';
        }
        offset = parseInt(this.last_itac_offset);
        if (!offset) {
            offset = 0;
        }
        offset -= limit * jump;
        if (offset < 0) {
            offset = 0;
        }

        return this.getItacs(
            this.last_itac_select,
            this.last_itac_where,
            this.last_itac_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastItacsPage(): Promise<Itac[]> {
        return this.getItacs(
            this.last_itac_select,
            this.last_itac_where,
            this.last_itac_order,
            this.last_itac_offset,
            this.last_itac_limit
        );
    }

    async getNextCountersPage(jump: number): Promise<Counter[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_counter_limit) {
            this.last_counter_limit = '500';
        } else {
            limit = parseInt(this.last_counter_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_counter_offset) {
            this.last_counter_offset = '0';
        }
        offset = parseInt(this.last_counter_offset);
        if (!offset) {
            offset = 0;
        }
        offset += limit * jump;

        return this.getCounters(
            this.last_counter_select,
            this.last_counter_where,
            this.last_counter_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousCountersPage(jump: number): Promise<Counter[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_counter_limit) {
            this.last_counter_limit = '500';
        } else {
            limit = parseInt(this.last_counter_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_counter_offset) {
            this.last_counter_offset = '0';
        }
        offset = parseInt(this.last_counter_offset);
        if (!offset) {
            offset = 0;
        }
        offset -= limit * jump;
        if (offset < 0) {
            offset = 0;
        }

        return this.getCounters(
            this.last_counter_select,
            this.last_counter_where,
            this.last_counter_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastCountersPage(): Promise<Counter[]> {
        return this.getCounters(
            this.last_counter_select,
            this.last_counter_where,
            this.last_counter_order,
            this.last_counter_offset,
            this.last_counter_limit
        );
    }

    async getNextActivationLogsPage(jump: number): Promise<ActivationLog[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_activationLog_limit) this.last_activationLog_limit = '500';
        else {
            limit = parseInt(this.last_activationLog_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_activationLog_offset) this.last_activationLog_offset = '0';
        
        offset = parseInt(this.last_activationLog_offset);
        if (!offset) offset = 0;
        
        offset += limit * jump;

        return this.getActivationLogs(
            this.last_activationLog_select,
            this.last_activationLog_where,
            this.last_activationLog_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousActivationLogsPage(jump: number): Promise<ActivationLog[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_activationLog_limit) this.last_activationLog_limit = '500';
        else {
            limit = parseInt(this.last_activationLog_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_activationLog_offset) this.last_activationLog_offset = '0';
        
        offset = parseInt(this.last_activationLog_offset);
        if (!offset) offset = 0;
        
        offset -= limit * jump;
        if (offset < 0) offset = 0;

        return this.getActivationLogs(
            this.last_activationLog_select,
            this.last_activationLog_where,
            this.last_activationLog_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastActivationLogsPage(): Promise<ActivationLog[]> {
        return this.getActivationLogs(
            this.last_activationLog_select,
            this.last_activationLog_where,
            this.last_activationLog_order,
            this.last_activationLog_offset,
            this.last_activationLog_limit
        );
    }

    async getNextPendingCallsPage(jump: number): Promise<PendingCall[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_pendingCall_limit) this.last_pendingCall_limit = '500';
        else {
            limit = parseInt(this.last_pendingCall_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_pendingCall_offset) this.last_pendingCall_offset = '0';
        
        offset = parseInt(this.last_pendingCall_offset);
        if (!offset) offset = 0;
        
        offset += limit * jump;

        return this.getPendingCalls(
            this.last_pendingCall_select,
            this.last_pendingCall_where,
            this.last_pendingCall_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousPendingCallsPage(jump: number): Promise<PendingCall[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_pendingCall_limit) this.last_pendingCall_limit = '500';
        else {
            limit = parseInt(this.last_pendingCall_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_pendingCall_offset) this.last_pendingCall_offset = '0';
        
        offset = parseInt(this.last_pendingCall_offset);
        if (!offset) offset = 0;
        
        offset -= limit * jump;
        if (offset < 0) offset = 0;

        return this.getPendingCalls(
            this.last_pendingCall_select,
            this.last_pendingCall_where,
            this.last_pendingCall_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastPendingCallsPage(): Promise<PendingCall[]> {
        return this.getPendingCalls(
            this.last_pendingCall_select,
            this.last_pendingCall_where,
            this.last_pendingCall_order,
            this.last_pendingCall_offset,
            this.last_pendingCall_limit
        );
    }

    async getNextIntegrationItelazpisPage(jump: number): Promise<IntegrationItelazpi[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_integrationItelazpi_limit) this.last_integrationItelazpi_limit = '500';
        else {
            limit = parseInt(this.last_integrationItelazpi_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_integrationItelazpi_offset) this.last_integrationItelazpi_offset = '0';
        
        offset = parseInt(this.last_integrationItelazpi_offset);
        if (!offset) offset = 0;
        
        offset += limit * jump;

        return this.getIntegrationItelazpis(
            this.last_integrationItelazpi_select,
            this.last_integrationItelazpi_where,
            this.last_integrationItelazpi_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousIntegrationItelazpisPage(jump: number): Promise<IntegrationItelazpi[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_integrationItelazpi_limit) this.last_integrationItelazpi_limit = '500';
        else {
            limit = parseInt(this.last_integrationItelazpi_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_integrationItelazpi_offset) this.last_integrationItelazpi_offset = '0';
        
        offset = parseInt(this.last_integrationItelazpi_offset);
        if (!offset) offset = 0;
        
        offset -= limit * jump;
        if (offset < 0) offset = 0;

        return this.getIntegrationItelazpis(
            this.last_integrationItelazpi_select,
            this.last_integrationItelazpi_where,
            this.last_integrationItelazpi_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getLastIntegrationItelazpisPage(): Promise<IntegrationItelazpi[]> {
        return this.getIntegrationItelazpis(
            this.last_integrationItelazpi_select,
            this.last_integrationItelazpi_where,
            this.last_integrationItelazpi_order,
            this.last_integrationItelazpi_offset,
            this.last_integrationItelazpi_limit
        );
    }

    async getNextRadiusModulesPage(jump: number): Promise<RadiusModule[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_radiusModule_limit) this.last_radiusModule_limit = '500';
        else {
            limit = parseInt(this.last_radiusModule_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_radiusModule_offset) this.last_radiusModule_offset = '0';
        
        offset = parseInt(this.last_radiusModule_offset);
        if (!offset) offset = 0;
        
        offset += limit * jump;

        return this.getRadiusModules(
            this.last_radiusModule_select,
            this.last_radiusModule_where,
            this.last_radiusModule_order,
            offset.toString(),
            limit.toString()
        );
    }

    async getPreviousRadiusModulesPage(jump: number): Promise<RadiusModule[]> {
        let limit: number = 500, offset = 0;
        if (!this.last_radiusModule_limit) this.last_radiusModule_limit = '500';
        else {
            limit = parseInt(this.last_radiusModule_limit);
            if (!limit) limit = 500;
        }
        if (!this.last_radiusModule_offset) this.last_radiusModule_offset = '0';
        
        offset = parseInt(this.last_radiusModule_offset);
        if (!offset) offset = 0;
        
        offset -= limit * jump;
        if (offset < 0) offset = 0;

        return this.getRadiusModules(
            this.last_radiusModule_select,
            this.last_radiusModule_where,
            this.last_radiusModule_order,
            offset.toString(),
            limit.toString()
        );
    }
    
    async getLastRadiusModulesPage(): Promise<RadiusModule[]> {
        return this.getRadiusModules(
            this.last_radiusModule_select,
            this.last_radiusModule_where,
            this.last_radiusModule_order,
            this.last_radiusModule_offset,
            this.last_radiusModule_limit
        );
    }

    async getNextWaterRoutesPage(jump: number): Promise<WaterRoute[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_waterRoute_limit) {
            this.last_waterRoute_limit = '500';
        } else {
            limit = parseInt(this.last_waterRoute_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_waterRoute_offset) {
            this.last_waterRoute_offset = '0';
        }
        offset = parseInt(this.last_waterRoute_offset);
        if (!offset) {
            offset = 0;
        }
        offset += limit * jump;

        return this.getWaterRoutes(
            this.last_waterRoute_select,
            this.last_waterRoute_where,
            this.last_waterRoute_order,
            offset.toString(),
            limit.toString()
        );
    }
    async getPreviousWaterRoutesPage(jump: number): Promise<WaterRoute[]> {
        let limit: number = 500,
            offset = 0;
        if (!this.last_waterRoute_limit) {
            this.last_waterRoute_limit = '500';
        } else {
            limit = parseInt(this.last_waterRoute_limit);
            if (!limit) {
                limit = 500;
            }
        }
        if (!this.last_waterRoute_offset) {
            this.last_waterRoute_offset = '0';
        }
        offset = parseInt(this.last_waterRoute_offset);
        if (!offset) {
            offset = 0;
        }
        offset -= limit * jump;
        if (offset < 0) {
            offset = 0;
        }

        return this.getWaterRoutes(
            this.last_waterRoute_select,
            this.last_waterRoute_where,
            this.last_waterRoute_order,
            offset.toString(),
            limit.toString()
        );
    }
    async getLastWaterRoutesPage(): Promise<WaterRoute[]> {
        return this.getWaterRoutes(
            this.last_waterRoute_select,
            this.last_waterRoute_where,
            this.last_waterRoute_order,
            this.last_waterRoute_offset,
            this.last_waterRoute_limit
        );
    }
}
