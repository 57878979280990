import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IpcService } from '../../../services/ipc.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
    event: any;
    currentRoute: string | undefined = 'unknown';

    @Output() sendOnFileChange: EventEmitter<any>;
    @Output() sendOpenSettings: EventEmitter<any>;
    @Output() sendInformTasks: EventEmitter<any>;
    @Output() sendMigrateTaskFiles: EventEmitter<any>;
    @Output() sendMigrateItacFiles: EventEmitter<any>;
    @Output() sendMigrateTables: EventEmitter<any>;
    @Output() sendMigrateItacTables: EventEmitter<any>;
    @Output() sendCheckInServices: EventEmitter<any>;
    @Output() sendExportAqualia: EventEmitter<any>;

    /**
     * Creates an instance of the DrawerComponent.
     * @param _utilsService - The UtilsService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param _electronService - The IpcService instance.
     */
    constructor(
        public _utilsService: UtilsService,
        private router: Router,
        private route: ActivatedRoute,
        private _electronService: IpcService
    ) {
        const routeConfig = this.route.snapshot.routeConfig;
        if(routeConfig) this.currentRoute = routeConfig.path;
        
        this.sendOnFileChange = new EventEmitter();
        this.sendOpenSettings = new EventEmitter();
        this.sendInformTasks = new EventEmitter();
        this.sendMigrateTaskFiles = new EventEmitter();
        this.sendMigrateItacFiles = new EventEmitter();
        this.sendMigrateTables = new EventEmitter();
        this.sendMigrateItacTables = new EventEmitter();
        this.sendCheckInServices = new EventEmitter();
        this.sendExportAqualia = new EventEmitter();
    }

    /**
     * Initializes the component.
     * This lifecycle hook is called after Angular has initialized all data-bound properties of a directive.
     * It is a good place to perform initialization logic, such as retrieving data from a server.
     */
    ngOnInit(): void {}

    /**
     * Opens a selector dialog to choose a table and navigates to the corresponding route.
     * If the user is a super user, additional tables are available for selection.
     */
    async selectTable() {
        const tables: any = {
            Tareas: 'home',
            Bandos: 'sides',
            Itacs: 'itacs',
            Contadores: 'counters',
            'Módulos de radio': 'radius-modules',
            'Llamadas pendientes': 'pending-calls',
            Rutas: 'water_routes',
            Calibres: 'calibers',
            Causas: 'causes',
            Clases: 'class_counters',
            Emplazamientos: 'emplacements',
            Longitudes: 'longitudes',
            Marcas: 'marks',
            Observaciones: 'observations',
            Piezas: 'parts',
            Resultados: 'results',
            Planificacion: 'plannings',
            'Detalle de planificación': 'planning-details',
            'Detalle extra de planificación': 'planning-detail-extras',
            'Tipos de Radio': 'type_radius',
            'Tipos de Contador': 'type_counters',
            Equipos: 'teams',
            'Sectores P': 'zones',
            Información: 'infos',
            Activaciones: 'activation-logs',
            'Activaciones Itelazpi': 'integration-itelazpis',
        };
        if (this._utilsService.isSuperUser()) {
            tables['Usuarios'] = 'users';
            tables['Agrupaciones'] = 'agrupations';
            tables['Gestores'] = 'managers';
            tables['Empresas'] = 'companies';
            tables['Información'] = 'infos';
        }
        try {
            const table = await this._utilsService.openSelectorDialog(
                'Seleccione tabla',
                Object.keys(tables)
            );
            this.router.navigate([`/${tables[table]}`]);
        } catch (error) {}
    }

    /**
     * Opens the folder options for the given input file.
     * @param input_file - The input file element.
     * @returns {Promise<void>} - A promise that resolves when the folder options are opened.
     */
    async openFolderOptions(input_file: any): Promise<void> {
        const event = {
            file_option: '',
            file_type: '',
            task_type: '',
        };
        try {
            let optionsToSelect = [];
            if(this.currentRoute?.includes('home')) optionsToSelect.push('Importar tareas');
            if (!this._utilsService.isClientUser()) {
                if(this.currentRoute?.includes('counters')) optionsToSelect.push('Importar contadores');
                if(this.currentRoute?.includes('counters')) optionsToSelect.push('Buscar contadores de excel');
                if(this.currentRoute?.includes('itacs')) optionsToSelect.push('Importar itacs');
                if(this.currentRoute?.includes('radius-modules')) optionsToSelect.push('Importar módulos de radio');
                if(this.currentRoute?.includes('home')) optionsToSelect.push('Buscar tareas de excel');
                // optionsToSelect.push('Informar');
                // optionsToSelect.push('Facturar servicios');
            }
            if (this._utilsService.isSuperUser()) { 
                if (this.currentRoute?.includes('home')) optionsToSelect.push('Exportar a Aqualia');
            }
            if (this._utilsService.isDeveloper()) { 
                // optionsToSelect.push('Importar calibres');
                // optionsToSelect.push('Importar causas');
                // optionsToSelect.push('Importar clases');
                // optionsToSelect.push('Importar emplazamientos');
                // optionsToSelect.push('Importar longitudes');
                // optionsToSelect.push('Importar marcas');
                // optionsToSelect.push('Importar observaciones');
                // optionsToSelect.push('Importar piezas');
                // optionsToSelect.push('Importar rutas');
                // optionsToSelect.push('Importar tipos');
                // optionsToSelect.push('Importar zonas');
                // optionsToSelect.push('Importar detalles de planificación');
                if(this.currentRoute?.includes('home')) optionsToSelect.push('Fix excel fields');
                // optionsToSelect.push('Importar GeoJson');
            }
            if (this._electronService.isElectronApp() && this._utilsService.isDeveloper()) {
                optionsToSelect.push('Migrate tables');
                optionsToSelect.push('Migrate itac tables');
                optionsToSelect.push('Migrate task files'); //Copy fotos from folder to tasks in DB
                optionsToSelect.push('Migrate itac files'); //Copy fotos from folder to itacs in DB
                // optionsToSelect.push('Importar ficheros de tareas');
                // optionsToSelect.push('Importar ficheros de itacs');
            }
            const option = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                optionsToSelect
            );

            if (option == 'Importar tareas') {
                event['file_option'] = 'Importar tareas';
                let fileTypeOption = 'Excel';
                if (!this._utilsService.isClientUser()) {
                    fileTypeOption = await this._utilsService.openSelectorDialog(
                        'Seleccione tipo de archivo',
                        ['Excel', 'DAT']
                    );
                }
                if (fileTypeOption) {
                    event['file_type'] = fileTypeOption;
                    let orderTypeOption = 'DIARIAS';
                    if (!this._utilsService.isClientUser()) {
                        orderTypeOption = await this._utilsService.openSelectorDialog(
                            'Seleccione orden',
                            ['DIARIAS', 'MASIVAS', 'ESPECIALES']
                        );
                    }
                    if (orderTypeOption) {
                        event['task_type'] = orderTypeOption;
                        this.event = event;
                        input_file.click();
                    }
                }
            } else if (option == 'Importar ficheros de tareas') {
                // this._utilsService.goToLink('https://products.aspose.app/gis/conversion');
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar GeoJson') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar ficheros de itacs') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar contadores') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar módulos de radio') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar calibres') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar causas') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar clases') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar emplazamientos') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar longitudes') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar marcas') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar observaciones') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar piezas') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar resultados') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar rutas') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar tipos') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar detalles de planificación') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar zonas') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Importar itacs') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Informar') {
                this.sendInformTasks.emit();
            } else if (option == 'Migrate tables') {
                this.sendMigrateTables.emit();
            } else if (option == 'Migrate itac tables') {
                this.sendMigrateItacTables.emit();
            } else if (option == 'Migrate task files') {
                this.sendMigrateTaskFiles.emit();
            } else if (option == 'Migrate itac files') {
                this.sendMigrateItacFiles.emit();
            } else if (option == 'Facturar servicios') {
                this.sendCheckInServices.emit();
            } else if (option == 'Exportar a Aqualia') {
                this.sendExportAqualia.emit();
            } else if (option == 'Buscar tareas de excel') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            }  else if (option == 'Buscar contadores de excel') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            } else if (option == 'Fix excel fields') {
                event['file_option'] = option;
                this.event = event;
                input_file.click();
            }
        } catch (err) {}
    }

    /**
     * Handles the file change event.
     * 
     * @param ev - The file change event object.
     */
    onFileChange(ev: any) {
        this.event['file'] = ev;
        this.sendOnFileChange.emit(this.event);
    }

    /**
     * Opens the settings.
     * Emits an event to notify the parent component.
     */
    async openSettings() {
        this.sendOpenSettings.emit();
    }

    /**
     * Opens the statistics total page based on the user's selection.
     * Displays a selector dialog to choose between 'Promedios' and 'Totales'.
     * Navigates to the corresponding statistics page based on the selected option.
     */
    async openStatisticsTotal() {
        try {
            const opts = ['Promedios', 'Totales'];
            const option = await this._utilsService.openSelectorDialog('Seleccione', opts);
            if (option === 'Promedios') {
                this.router.navigate([`/statistics-avg`]);
            }
            else if(option === 'Totales') {
                this.router.navigate([`/statistics-total`]);
            }
        } catch (err) {}
    }

    /**
     * Opens the help dialog.
     * @returns {Promise<void>} A promise that resolves when the help dialog is opened.
     */
    async openHelp() {
        await this._utilsService.openHelpDialog();
    }
}
