/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { getFormControls, order_status, priority_status, WaterTask } from 'src/app/interfaces/water-task';
import { UtilsService } from 'src/app/services/utils.service';

import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { task_status } from '../../../interfaces/water-task';
import { TypeRadius } from '../../../interfaces/type-radius';

export interface ServicesInformData {
    task: WaterTask;
}

@Component({
    selector: 'app-services-inform',
    templateUrl: './services-inform.component.html',
    styleUrls: ['./services-inform.component.scss'],
})
export class ServicesInformComponent implements OnInit {
    taskFormData: FormGroup = getFormControls();
    task: WaterTask = {};

    suplies: string[] = [];
    services: string[];
    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ServicesInformData,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService,
        private _utilsService: UtilsService
    ) {
        this.task = data.task;
        this.services = this._utilsService.services;
    }

    async ngOnInit(): Promise<void> {
        this.taskFormData = this._utilsService.getFormFromTask(this.task);
        this.suplies = this._utilsService.suplies;
        const radios = (await this._apiService.getDocuments<TypeRadius>('type_radius'))
            .map((typeRadius: TypeRadius) => `${typeRadius.radio}`)
            .sort();
        for (const radio of radios) {
            const suply = `Módulo ${radio}`;
            if (!this.suplies.includes(suply)) this.suplies.push(suply);
        }
    }
    
    /**
     * Closes the current task by saving form data, updating the task via an API call,
     * and providing feedback to the user through a snackbar notification.
     * 
     * @returns {Promise<void>} A promise that resolves when the task is closed.
     * 
     * @remarks
     * - This method shows a loading indicator while the task is being updated.
     * - If the task update is successful, a success message is shown.
     * - If the task update fails, an error message is shown.
     * - The services inform dialog is closed after the task update attempt.
     */
    async closeTask(): Promise<void> {
        try {
            this.saveFormData();
            if (this._utilsService.isArrayNotValid(this.task.servicios)) {
                const q = '¿Está seguro de cerrar la tarea sin haber seleccionado un servicio?';
                const opt = await this._utilsService.openQuestionDialog('Sin servicio', q);
                if(!opt) return;
            }
            await this.executeCloseTask();
        } catch (err) {}
    }

    /**
     * Executes the task closure process.
     * 
     * This method performs the following steps:
     * 1. Shows a loading indicator.
     * 2. Calls the API service to update the task status to closed.
     * 3. Hides the loading indicator.
     * 4. Displays a success or error message based on the result of the API call.
     * 5. Closes the services inform dialog with the result of the task closure.
     * 
     * @returns {Promise<void>} A promise that resolves when the task closure process is complete.
     */
    async executeCloseTask(): Promise<void> {
        this.showLoading(true);
        const result: boolean = await this._apiService.updateTask(
            this.task.id!.toString(),
            this.task,
            true
        );
        this.showLoading(false);
        if (result) this._utilsService.openSnackBar('Tarea cerrada correctamente');
        else this._utilsService.openSnackBar('Error cerrando tarea', 'error');
        
        this._utilsService.closeServicesInformDialog(result);
    }

    saveFormData() {
        if(this.task){
            this.task = this._utilsService.saveTaskFormData(this.taskFormData, this.task);

            if (this.task.servicios && this.task.servicios.length) {
                this.task.last_service = this.task.servicios[0].value;
            }
            this.task.status_tarea = task_status.CLOSED;
            this.task.FECH_CIERRE = this.task.date_time_modified;
        }
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('taskSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('taskSpinner');
        }
    }
}
