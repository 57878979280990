import { FormControl, FormGroup } from '@angular/forms';
import { MyLatLng } from 'src/app/interfaces/lat-lng';

export enum counter_status {
    AVAILABLE,
    INSTALLED,
}

export interface Counter {
    ID?: number; //useless only for displaying info
    id?: number;
    anno_o_prefijo?: string;
    calibre?: string;
    clase?: string;
    codigo_clase?: string;
    codigo_marca?: string;
    company?: number;
    manager?: number;
    date_time_modified?: Date;
    encargado?: string;
    equipo_encargado?: string;
    fecha_instalado?: Date;
    gestor?: string;
    lectura_inicial?: number;
    longitud?: string;
    marca?: string;
    modelo?: string;
    numero_serie_contador?: string;
    numero_serie_modulo?: string;
    ruedas?: string;
    upload_pendent?: boolean;
    last_modification_operator_uid?: string;
    status_contador?: number;
    tipo_fluido?: string;
    tipo_radio?: string;
    agrupationId?: number;
    activation_error_code?: number;
    radio_activated?: boolean; //Radio activation  only for LORA now
    activate_in_field_enabled?: boolean;
    activation_pendent?: boolean;
    geolocalizacion?: MyLatLng;
    image?: string;

    inventory_sync?: boolean;
    inventory_user_id?: number;
    deposit?: string;
    inventory_date?: Date;
    control_digit?: string;
}

export function getCounterDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'GESTOR',
        'Numero serie',
        'Año o prefijo',
        'Calibre',
        'Longitud',
        'Ruedas',
        'C.Marca',
        'Marca',
        'Modelo',
        'C.Clase',
        'Clase',
        'T.Fluido',
        'T.Radio',
        'Lectura',
        'Encargado',
        'Estado',
        'Módulo',
        'Activación habilitada',
        'Pendiente activación',
        'Activado',
        'Fecha de Instalación',
        'Inventariado',
        'Fecha de Inventario',
        'Depósito',
        'Dígito de control',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getCounterField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'GESTOR':
            value = 'manager';
            break;
        case 'Numero serie':
            value = 'numero_serie_contador';
            break;
        case 'Módulo':
            value = 'numero_serie_modulo';
            break;
        case 'Año o prefijo':
            value = 'anno_o_prefijo';
            break;
        case 'Calibre':
            value = 'calibre';
            break;
        case 'Longitud':
            value = 'longitud';
            break;
        case 'Ruedas':
            value = 'ruedas';
            break;
        case 'C.Marca':
            value = 'codigo_marca';
            break;
        case 'Marca':
            value = 'marca';
            break;
        case 'Modelo':
            value = 'modelo';
            break;
        case 'C.Clase':
            value = 'codigo_clase';
            break;
        case 'Clase':
            value = 'clase';
            break;
        case 'T.Fluido':
            value = 'tipo_fluido';
            break;
        case 'T.Radio':
            value = 'tipo_radio';
            break;
        case 'Lectura':
            value = 'lectura_inicial';
            break;
        case 'Encargado':
            value = 'encargado';
            break;
        case 'Estado':
            value = 'status_contador';
            break;
        case 'Activación habilitada':
            value = 'activate_in_field_enabled';
            break;
        case 'GESTOR':
            value = 'manager';
            break;
        case 'Pendiente activación':
            value = 'activation_pendent';
            break;
        case 'Activado':
            value = 'radio_activated';
            break;
        case 'Fecha de Instalación':
            value = 'fecha_instalado';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;

        case 'Inventariado':
            value = 'inventory_sync';
            break;
        case 'Fecha de Inventario':
            value = 'inventory_date';
            break;
        case 'Depósito':
            value = 'deposit';
            break;
        case 'Dígito de control':
            value = 'control_digit';
            break;

    }
    return value;
}

export function getCounterFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'manager':
            value = 'GESTOR';
            break;
        case 'numero_serie_contador':
            value = 'Numero serie';
            break;
        case 'numero_serie_modulo':
            value = 'Módulo';
            break;
        case 'anno_o_prefijo':
            value = 'Año o prefijo';
            break;
        case 'calibre':
            value = 'Calibre';
            break;
        case 'longitud':
            value = 'Longitud';
            break;
        case 'ruedas':
            value = 'Ruedas';
            break;
        case 'codigo_marca':
            value = 'C.Marca';
            break;
        case 'marca':
            value = 'Marca';
            break;
        case 'modelo':
            value = 'Modelo';
            break;
        case 'codigo_clase':
            value = 'C.Clase';
            break;
        case 'clase':
            value = 'Clase';
            break;
        case 'tipo_fluido':
            value = 'T.Fluido';
            break;
        case 'tipo_radio':
            value = 'T.Radio';
            break;
        case 'lectura_inicial':
            value = 'Lectura';
            break;
        case 'encargado':
            value = 'Encargado';
            break;
        case 'status_contador':
            value = 'Estado';
            break;
        case 'activate_in_field_enabled':
            value = 'Activación habilitada';
            break;
        case 'activation_pendent':
            value = 'Pendiente activación';
            break;
        case 'radio_activated':
            value = 'Activado';
            break;
        case 'fecha_instalado':
            value = 'Fecha de Instalación';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;

        case 'inventory_sync':
            value = 'Inventariado';
            break;
        case 'inventory_date':
            value = 'Fecha de Inventario';
            break;
        case 'deposit':
            value = 'Depósito';
            break;
        case 'control_digit':
            value = 'Dígito de control';
            break;
    }
    return value;
}

export function getCounterFormControls(): FormGroup {
    const counterFormData: FormGroup = new FormGroup({
        anno_o_prefijo: new FormControl(),
        calibre: new FormControl(),
        clase: new FormControl(),
        codigo_clase: new FormControl(),
        codigo_marca: new FormControl(),
        company: new FormControl(),
        manager: new FormControl(),
        date_time_modified: new FormControl(),
        encargado: new FormControl(),
        equipo_encargado: new FormControl(),
        fecha_instalado: new FormControl(),
        gestor: new FormControl(),
        id: new FormControl(),
        lectura_inicial: new FormControl(),
        longitud: new FormControl(),
        marca: new FormControl(),
        modelo: new FormControl(),
        numero_serie_contador: new FormControl(),
        numero_serie_modulo: new FormControl(),
        ruedas: new FormControl(),
        status_contador: new FormControl(),
        tipo_fluido: new FormControl(),
        tipo_radio: new FormControl(),
        upload_pendent: new FormControl(),
        last_modification_operator_uid: new FormControl(),
        agrupationId: new FormControl(),
        activation_error_code: new FormControl(),
        radio_activated: new FormControl(),
        activate_in_field_enabled: new FormControl(),
        activation_pendent: new FormControl(),
        geolocalizacion: new FormControl(),
        image: new FormControl(),

        inventory_sync: new FormControl(),
        inventory_user_id: new FormControl(),
        deposit: new FormControl(),
        inventory_date: new FormControl(),
        control_digit: new FormControl(),
    });
    return counterFormData;
}

export function getCounterFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'anno_o_prefijo':
            value = 'string';
            break;
        case 'manager':
            value = 'number';
            break;
        case 'calibre':
            value = 'string';
            break;
        case 'clase':
            value = 'string';
            break;
        case 'codigo_clase':
            value = 'string';
            break;
        case 'codigo_marca':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'encargado':
            value = 'string';
            break;
        case 'equipo_encargado':
            value = 'string';
            break;
        case 'fecha_instalado':
            value = 'Date';
            break;
        case 'gestor':
            value = 'string';
            break;
        case 'id':
            value = 'number';
            break;
        case 'lectura_inicial':
            value = 'number';
            break;
        case 'longitud':
            value = 'string';
            break;
        case 'marca':
            value = 'string';
            break;
        case 'modelo':
            value = 'string';
            break;
        case 'numero_serie_contador':
            value = 'string';
            break;
        case 'numero_serie_modulo':
            value = 'string';
            break;
        case 'ruedas':
            value = 'string';
            break;
        case 'status_contador':
            value = 'number';
            break;
        case 'last_modification_operator_uid':
            value = 'string';
            break;
        case 'agrupationId':
            value = 'number';
            break;
        case 'activation_error_code':
            value = 'number';
            break;
        case 'radio_activated':
            value = 'number';
            break;
        case 'activate_in_field_enabled':
            value = 'number';
            break;
        case 'activation_pendent':
            value = 'number';
            break;
        case 'tipo_fluido':
            value = 'string';
            break;
        case 'tipo_radio':
            value = 'string';
            break;

        case 'inventory_sync':
            value = 'number';
            break;
        case 'inventory_user_id':
            value = 'number';
            break;
        case 'deposit':
            value = 'string';
            break;
        case 'inventory_date':
            value = 'Date';
            break;
        case 'control_digit':
            value = 'string';
            break;
    }
    return value;
}

export function getCounterExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'número serie de contador':
            value = 'numero_serie_contador';
            break;
        case 'número serie de módulo':
            value = 'numero_serie_modulo';
            break;
        case 'numero serie de módulo':
            value = 'numero_serie_modulo';
            break;
        case 'número serie del módulo':
            value = 'numero_serie_modulo';
            break;
        case 'número serie del modulo':
            value = 'numero_serie_modulo';
            break;
        case 'numero serie del módulo':
            value = 'numero_serie_modulo';
            break;
        case 'numero serie del modulo':
            value = 'numero_serie_modulo';
            break;
        case 'numero serie de modulo':
            value = 'numero_serie_modulo';
            break;
        case 'Numero Serie de Contador':
            value = 'numero_serie_contador';
            break;
        case 'numero serie':
            value = 'numero_serie_contador';
            break;
        case 'serie':
            value = 'numero_serie_contador';
            break;
        case 'año o prefijo':
            value = 'anno_o_prefijo';
            break;
        case 'calibre':
            value = 'calibre';
            break;
        case 'longitud':
            value = 'longitud';
            break;
        case 'ruedas':
            value = 'ruedas';
            break;
        case 'c.marca':
            value = 'codigo_marca';
            break;
        case 'marca':
            value = 'marca';
            break;
        case 'modelo':
            value = 'modelo';
            break;
        case 'c.clase':
            value = 'codigo_clase';
            break;
        case 'clase':
            value = 'clase';
            break;
        case 'tipo de fluido':
            value = 'tipo_fluido';
            break;
        case 't.fluido':
            value = 'tipo_fluido';
            break;
        case 'tipo de radio':
            value = 'tipo_radio';
            break;
        case 't.radio':
            value = 'tipo_radio';
            break;
        case 'lectura':
            value = 'lectura_inicial';
            break;
        case 'encargado':
            value = 'encargado';
            break;
        case 'estado':
            value = 'status_contador';
            break;
        case 'pendiente activación':
            value = 'activation_pendent';
            break;
        case 'fecha de instalación':
            value = 'fecha_instalado';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'módulo':
            value = 'numero_serie_modulo';
            break;

        case 'inventariado':
            value = 'inventory_sync';
            break;
        case 'fecha de inventario':
            value = 'inventory_date';
            break;
        case 'depósito':
            value = 'deposit';
            break;
        case 'deposito':
            value = 'deposit';
            break;
        case 'dígito de control':
            value = 'control_digit';
            break;
        case 'digito de control':
            value = 'control_digit';
            break;
    }
    return value;
}

export function getCounterExcelExportColumns(): string[] {
    const columns = [
        'Numero serie',
        'Año o prefijo',
        'Módulo',
        'Calibre',
        'Longitud',
        'Ruedas',
        'C.Marca',
        'Marca',
        'Modelo',
        'C.Clase',
        'Clase',
        'T.Fluido',
        'T.Radio',
        'Lectura',
        'Encargado',
        'Estado',
        'Activado',
        'Pendiente activación',
        'Fecha de Instalación',
        'Inventariado',
        'Fecha de Inventario',
        'Depósito',
        'Dígito de control',
        'Fecha de Modificación',
    ];
    return columns;
}
