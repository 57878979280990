<mat-drawer-container [hasBackdrop]="true" class="animate__animated animate__fadeIn">
    <mat-drawer color="primary" #drawer [mode]="'over'" class="drawer-background">
        <app-drawer (sendOnFileChange)="fileEvent($event)" (sendOpenSettings)="openSettings()">
        </app-drawer>
    </mat-drawer>

    <mat-drawer-content>
        <app-toolbar
            [showCleanFilter]="true"
            [marginImage]="false"
            (sendLoading)="showLoading($event)"
            (sendSearchValue)="searchValue($event)"
            (sendToggleDrawer)="drawer.toggle()"
            (sendCompanyAndGestorChanged)="getCounters()"
            (sendSelectAll)="selectAll()"
        >
        </app-toolbar>

        <div class="flex-container">
            <div class="table-container">
                <div class="inner-table-container">
                    <div
                        *ngIf="!loading && dataSource!.data.length > 0"
                        class="animate__animated animate__fadeIn"
                        class="app-table-container"
                        infiniteScroll
                        [infiniteScrollDistance]="5"
                        [infiniteScrollThrottle]="rowsLimit"
                        (scrolled)="onScroll()"
                        [scrollWindow]="false"
                    >
                        <app-table
                            [dataSource]="dataSource"
                            [tableName]="tableName"
                            [displayedColumns]="displayedColumns"
                            [displayedColumnsField]="displayedColumnsField"
                            [clickedRows]="clickedRows"
                            [tableStyle]="'width: 3400px;'"
                            [menuOptions]="menuOptions"
                            (sendFilterBy)="filterBy($event)"
                            (sendOrderBy)="orderBy($event)"
                            (sendDoubleClickedRow)="doubleClickedRow($event)"
                            (sendClickedRow)="clickedRow($event)"
                            (sendMenuOptionSelected)="onMenuOptionSelected($event)"
                            (sendSelectedRows)="selectedRows($event)"
                            (sendReload)="reload()"
                        >
                        </app-table>
                    </div>
                </div>
            </div>
        </div>

        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0)"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="innerSpinner"
        >
        </ngx-spinner>

        <div class="floating-bottom-left">
            <button
                mat-fab
                color="primary"
                matTooltip="Añadir multiples contadores"
                (click)="openAddMultipleCounters()"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="is_pendent_activations" class="floating-bottom-left-plus bounce">
            <button
                mat-fab
                color="primary"
                matTooltip="Ver o activar contadores pendientes"
                (click)="selectRadioOption()"
                title="Options"
            >
                <fa-icon
                    style="font-size: 18px; color: #ffffff"
                    [icon]="faBroadcastTower"
                ></fa-icon>
            </button>
        </div>

        <app-footer
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="lastPageIndex"
            [pageSizeOptions]="pageSizeOptions"
            [showMapButton]="false"
            (sendAddRow)="addNewRow($event)"
            (sendExportTable)="exportCountersInTable()"
            (sendDelete)="deleteCounters()"
            (sendPageEvent)="pageEvent($event)"
        >
        </app-footer>

        <div *ngIf="!loading && dataSource!.data.length == 0" class="center-child">
            <div style="text-align: center">
                <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
            </div>
            <div style="margin: 15px">
                <span style="font-size: 20px; color: #777777">No hay contadores para mostrar</span>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">{{ loadingText }}</p>
</ngx-spinner>
