/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Counter,
    getCounterDisplayColumns,
    getCounterExcelExportColumns,
    getCounterExcelFieldName,
    getCounterField,
    getCounterFieldType,
} from 'src/app/interfaces/counter';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import { MySqlService } from '../../services/mysql.service';
import { LoraResponse } from '../../interfaces/lora-response';
import * as moment from 'moment';
import { IpcService } from '../../services/ipc.service';

@Component({
    selector: 'app-counters',
    templateUrl: './counters.component.html',
    styleUrls: ['./counters.component.scss'],
})
export class CountersComponent implements OnInit {
    @ViewChild('drawer') homeDrawer?: any;

    faBroadcastTower = faBroadcastTower;
    faInbox = faInbox;
    loading: boolean = true;
    counters: Counter[] = [];
    dataSource: MatTableDataSource<Counter> = new MatTableDataSource();

    length = 0; //counter count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'counters';
    displayedColumns: string[] = [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<Counter>();
    allSelected = false;

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    is_pendent_activations?: boolean;

    menuOptions: string[] = ['Asignar campos', 'Activar', 'Ver activaciones', 'Exportar a EXCEL'/* , 'Add zeros' */, 'Eliminar'];

    /**
     * Constructs a new instance of the CountersComponent.
     * 
     * @param _apiService - The ApiService instance.
     * @param _mySqlService - The MySqlService instance.
     * @param _utilsService - The UtilsService instance.
     * @param _windowRefService - The WindowRefService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param _spinner - The NgxSpinnerService instance.
     */
    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _electronService: IpcService,
        private _spinner: NgxSpinnerService
    ) {
        this.pageSize = parseInt(localStorage.getItem('counter_pageSize') || `${this.rowsLimit}`);

        this.displayedColumns = this._utilsService.setDisplayColumns(
            this.displayedColumns,
            this.tableName,
            getCounterDisplayColumns,
        );

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getCounterField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) return;
            
            const updateNeeded = localStorage.getItem('counterUpdateNeeded');
            if (updateNeeded == 'true') {
                this.scrollOffset = 50;
                localStorage.setItem('counterUpdateNeeded', 'false');
                this.showLoading(true);
                this.setCountersInTable(await this._mySqlService.getLastCountersPage());
                this.showLoading(false);
            }
        });
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any initialization logic.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        await this.getCounters();
        this.is_pendent_activations = await this._mySqlService.isActivationPendentCounters();
    }

    /**
     * Handles the scroll event and updates the data source.
     */
    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.counters.slice(0, this.scrollOffset);
    }

    /**
     * Clears the counter filter and order settings.
     * Resets the orderCounters and filterCounters properties of the _utilsService.
     * Clears the orderCounters and filterCounters values from the sessionStorage.
     */
    clearCounterFilterAndOrder() {
        this._utilsService.orderCounters = [];
        this._utilsService.filterCounters = {};
        sessionStorage.setItem('orderCounters', '');
        sessionStorage.setItem('filterCounters', '');
    }

    /**
     * Reloads the current route.
     */
    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Handles the file event triggered by the user.
     * @param event - The file event object.
     */
    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar contadores') {
            this.processExcelFile(event);
        } else if (event['file_option'] == 'Buscar contadores de excel') {
            this.searchExcelFile(event);
        }
    }

    /**
     * Searches for an Excel file and processes its data.
     * @param event - The event object containing the selected file.
     */
    async searchExcelFile(event: any) { 
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this.homeDrawer?.toggle();
            this._spinner.show();
            this.loadingText = `Procesando contadores ...`;
            
            const { jsonData, sheets } = this._utilsService.getExcelSheetAndData(reader);

            const { searchField, excelColumn } = await this.getSearchField(jsonData, sheets); 
            if (!searchField) return;
            if (!excelColumn) return;
            
            const values = this._utilsService.getExcelValues(jsonData, sheets, excelColumn);
            this.loadingText = `Buscando ...`;
            this._spinner.show();
            this.applyFilter(values, searchField);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);
    }
    
    /**
     * Retrieves the search field and excel column based on the provided JSON data and sheet names.
     * @param jsonData - The JSON data.
     * @param sheets - The sheet names.
     * @returns An object containing the search field and excel column.
     */
    async getSearchField(jsonData: any, sheets: string[]) {
        const keys = this._utilsService.getExcelKeys(jsonData, sheets);
        this._spinner.hide();
        let searchField: string = '', excelColumn: string = '';
        try {
            excelColumn = await this._utilsService.openSelectorDialog('Columna en excel', keys);
            if (excelColumn) searchField = getCounterExcelFieldName(excelColumn);
            if (searchField) return {searchField, excelColumn};
            const text = 'A continuación seleccione la columna que le corresponde en la tabla de contadores';
            await this._utilsService.openInformationDialog('Columna no registrada', [text]);
            const column = await this._utilsService.openSelectorDialog('Columna en aplicación', getCounterDisplayColumns());
            searchField = getCounterField(column);
        } catch (err) { }
        return { searchField, excelColumn };
    }

    /**
     * Processes the selected Excel file.
     * @param event - The event object containing the selected file.
     */
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        let workBook: XLSX.WorkBook = XLSX.utils.book_new();
        let jsonData: any = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            await this.parseCounters(workBook, jsonData, reader);
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    /**
     * Parses the counters from the provided workbook and imports them.
     * @param workBook - The workbook containing the counters data.
     * @param jsonData - The JSON data extracted from the workbook.
     * @param reader - The file reader used to read the workbook data.
     */
    async parseCounters(workBook: XLSX.WorkBook, jsonData: any, reader: FileReader) {
        this.homeDrawer?.toggle();
            this._spinner.show();
            this.loadingText = `Procesando contadores ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayCounters: Counter[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonCounter of jsonData[sheet]) {
                    let counter = await this.parseCounter(jsonCounter) as Counter;
                    if (
                        counter &&
                        this._utilsService.isFieldValid(counter.numero_serie_contador)
                    ) {
                        arrayCounters.push(counter);
                    }
                }
            }
            await this.importCounters(arrayCounters);
            this._spinner.hide();    
    }

    /**
     * Parses the given JSON counter object and returns the parsed counter data.
     * @param jsonCounter - The JSON counter object to parse.
     * @returns The parsed counter data.
     */
    async parseCounter(jsonCounter: any) {
        let counterData: any = {};
        Object.keys(jsonCounter).forEach((key) => {
            let field: string = getCounterExcelFieldName(key);
            if (this._utilsService.isFieldValid(field)) {
                let value = jsonCounter[key];
                try {
                    if (typeof value === 'number') {
                        if (getCounterFieldType(field) == 'number') {
                            counterData[`${field}`] = value;
                        } else {
                            counterData[`${field}`] = value.toString().trim();
                        }
                    } else if (
                        typeof value === 'string' &&
                        this._utilsService.isFieldValid(value)
                    ) {
                        if (getCounterFieldType(field) == 'string') {
                            counterData[`${field}`] = value.trim();
                        } else {
                            counterData[`${field}`] = parseInt(value);
                        }
                    }
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
            }
        });
        return counterData;
    }

    /**
     * Imports an array of counters.
     * 
     * @param counters - The array of counters to import.
     * @returns A boolean indicating whether the import was successful or not.
     */
    async importCounters(counters: Counter[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo contadores ...`;
        let i = 0,
            errorIds = [];
        for (let counter of counters) {
            this.loadingText = `Añadiendo contadores ${++i} de ${counters.length}`;
            if (!(await this._apiService.addCounter(counter))) {
                errorIds.push(counter.numero_serie_contador);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo contadores`, 'error');
        } else {
            this._utilsService.openSnackBar(`Contadores subidos correctamente`);
        }
        await this.getCounters();
        return !(errorIds.length > 0);
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - The loading state. Set to `true` to show the spinner, or `false` to hide it.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Sets the counters in the table.
     * 
     * @param counters - An array of Counter objects.
     */
    setCountersInTable(counters: Counter[]) {
        this.dataSource.data = [];
        this.counters = counters;
        for (let [index, counter] of this.counters.entries()) {
            counter.ID = index + 1;
        }
        this.dataSource.data = this.counters.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    /**
     * Retrieves the counters and sets them in the table.
     * @returns {Promise<void>} A promise that resolves when the counters are set in the table.
     */
    async getCounters() {
        this.setCountersInTable(await this.selectCounters());
    }

    /**
     * Retrieves counters based on the specified filter, order, offset, and page size.
     * @returns {Promise<any>} A promise that resolves to the retrieved counters.
     */
    async selectCounters(): Promise<any> {
        this.showLoading(true);
        const filter = this._utilsService.filterCounters;
        let where_clause = undefined;
        if (filter && filter.fields) where_clause = this._utilsService.getWhereClauseFromFilter(filter);

        this.length = await this._mySqlService.getCountersCount(where_clause);
        const order = this._utilsService.orderCounters;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexCounter');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexCounter', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        return await this._mySqlService.getCounters(undefined, where_clause, order_clause, offset, this.pageSize.toString());
    }

    /**
     * Selects all counters and adds them to the clickedRows set.
     * Clears the clickedRows set before adding the counters.
     * Sets the allSelected flag to true.
     * Displays a snackbar message with the number of selected counters.
     */
    async selectAll() {
        this.clickedRows.clear();
        const counters = this.counters;
        for (const counter of counters) {
            if (!this.clickedRows.has(counter)) {
                this.clickedRows.add(counter);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} contadores`);
    }

    /**
     * Filters the data source based on the provided search value.
     * @param event - The search value to filter the data source.
     */
    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Deletes the selected counters.
     * 
     * @remarks
     * This method deletes the counters that have been selected by the user. It prompts the user for confirmation before deleting the counters.
     * If any errors occur during the deletion process, an error message is displayed.
     * 
     * @returns A Promise that resolves when the counters have been deleted.
     */
    async deleteCounters() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un contador', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar los contadores seleccionados?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando contadores ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.counters;
            this.clickedRows.forEach(async (row) => {
                const index = this.counters.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let counters = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    counters.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando contadores ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (!(await this._apiService.deleteCounter(oldDataSource[i].id!.toString()))) {
                        console.log('****************** deleteCounter error *************');
                        errorIds.push(oldDataSource[i].id);
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando contadores`, 'error');
            } else {
                this._utilsService.openSnackBar(`Contadores eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setCountersInTable(counters);
            this.allSelected = false;

            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    /**
     * Handles the page event triggered by the pagination component.
     * @param event - The page event object containing information about the page index and page size.
     */
    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setCountersInTable(
                    await this._mySqlService.getNextCountersPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setCountersInTable(
                    await this._mySqlService.getPreviousCountersPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('counter_pageSize', this.pageSize.toString());
            await this.getCounters();
        }
        this.scrollOffset = 50;
    }

    /**
     * Filters the counters based on the specified condition.
     * 
     * @param where - The condition to filter the counters. (optional)
     * @returns A promise that resolves to the filtered counters.
     */
    async filterCounter(where?: string) {
        this.length = await this._mySqlService.getCountersCount(where);
        const order = this._utilsService.orderCounters;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getCounters(
            undefined,
            where,
            order_clause,
            '0',
            this.pageSize.toString()
        );
    }

    /**
     * Applies a filter to the counters and updates the table with the filtered results.
     * 
     * @param values - The filter values.
     * @param column - The column to filter on.
     * @param not_empty - Optional. Specifies whether to include only non-empty values in the filter.
     * @param empties_checked - Optional. Specifies whether to include empty values in the filter.
     */
    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterCounters!,
                values,
                column,
                getCounterFieldType(column),
                this._mySqlService.countersTableName,
                true, 
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexCounter', '');
        this.lastPageIndex = 0;
        this.setCountersInTable(await this.filterCounter(where_clause));
    }

    /**
     * Filters the data based on the specified column.
     * @param column - The column to filter by.
     */
    async filterBy(column: string) {
        this.filteredColumn = getCounterField(column);

        if (getCounterFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.countersTableName,
                this._utilsService.filterCounters
            );
            if (result && result.data) {
                this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }

    /**
     * Handles the event when a date is selected.
     * @param dateRange - The selected date range.
     * @param timeRange - The optional selected time range.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * Orders the counters based on the specified column and order type.
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getCounterField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.countersTableName,
                this._utilsService.orderCounters,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterCounters;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const counters = await this._mySqlService.getCounters(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexCounter', '');
        this.lastPageIndex = 0;
        this.setCountersInTable(counters);
    }

    /**
     * Adds a new row to the counters array.
     * @param event - The event object.
     */
    async addNewRow(event: any) {
        const counter = await this._utilsService.openCounterDialog('');
        console.log(counter);
        if (counter) {
            this.counters.push(counter);
            this.dataSource.data = [];
            this.dataSource.data = this.counters.slice(0, this.scrollOffset);
        }
    }

    /**
     * Handles the double click event on a row in the counters component.
     * @param row - The row object that was double clicked.
     * @returns void
     */
    async doubleClickedRow(row: any) {
        localStorage.setItem('lastPageIndexCounter', this.lastPageIndex.toString());

        let counter = await this._utilsService.openCounterDialog(row.id);

        if (counter) {
            counter.ID = this.counters[row.rowIndex].ID;
            this.counters[row.rowIndex] = counter;
            this.dataSource.data = [];
            this.dataSource.data = this.counters.slice(0, this.scrollOffset);
        }
    }

    /**
     * Handles the click event on a row.
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
     * Handles the selection of rows in the component.
     * 
     * @param event - The event object containing the selected rows.
     */
    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * Selects rows between two indexes and adds them to the clickedRows set.
     * If showSnackBar is true and more than one row is selected, it displays a snackbar with the count of selected rows.
     *
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional. Specifies whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.counters[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} contadores`);
        }
    }

    /**
     * Toggles the specified row in the component's clickedRows set.
     * If the row is already in the set, it will be removed. Otherwise, it will be added.
     * 
     * @param row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    /**
     * Exports the counters in a table.
     * If there are selected counters, it prompts the user to choose whether to export only the selected counters or all counters.
     * If the user chooses to export only the selected counters, it exports the selected counters.
     * If the user chooses to export all counters or there are no selected counters, it exports all counters by fetching them from the server.
     * The counters are exported in an Excel file.
     * 
     * @returns {Promise<void>} A promise that resolves when the export is completed.
     */
    async exportCountersInTable(): Promise<void> {
        let result: boolean = false;
        if (this.clickedRows.size) {
            result = await this._utilsService.openQuestionDialog(
                'Seleccione',
                '¿Exportar solo los contadores seleccionados?',
                'Seleccionados',
                'Todos'
            );
        }

        if (result) this.exportExcel(this.clickedRows);
        else {
            this.loadingText = `Descargando para exportación ...`;
            this._spinner.show();
            const savedOffset = this._mySqlService.last_counter_offset;
            let counters: Counter[] = [];
            const filter = this._utilsService.filterCounters;
            let where_clause = undefined;
            if (filter && filter.fields) {
                where_clause = this._utilsService.getWhereClauseFromFilter(filter);
            }
            const count = await this._mySqlService.getCountersCount(where_clause);
            const order = this._utilsService.orderCounters;
            let order_clause = undefined;
            if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
            let limit: number = 1000;
            for (let offset = 0; offset < count; offset += limit) {
                this.loadingText = `Descargando para exportación (${offset} de ${count}) ...`;
                const serverCounters = await this._mySqlService.getCounters(
                    undefined,
                    where_clause,
                    order_clause,
                    offset.toString(),
                    limit.toString()
                );
                counters = counters.concat(serverCounters);
            }
            this._mySqlService.last_counter_offset = savedOffset;
            this.exportExcel(counters);
            this._spinner.hide();
        }
    }

    /**
     * Activates the counters in the table.
     * If there are selected rows, it sends an API request to activate the counters.
     * Displays success or error messages based on the API response.
     * Finally, it reloads the data and updates the UI accordingly.
     */
    async activateCountersInTable() {
        if (this.clickedRows.size > 0) {
            const agrupationId = await this._apiService.selectAgrupdationId();
            const counters: Counter[] = Array.from(this.clickedRows);
            for(const counter of counters) {
                if (this._utilsService.isFieldNotValid(counter.agrupationId) && agrupationId) {
                    counter.agrupationId = agrupationId;
                }
            }
            this.showLoading(true);
            let errors = false;
            const loraResponses: LoraResponse[] = await this._apiService.activateCounters(counters);
            if (loraResponses && loraResponses.length > 0) {
                for (let loraResponse of loraResponses) if (!loraResponse.status) errors = true;
            }
            else errors = true;
            if (errors) {
                this._utilsService.openSnackBar('Error activando contadores', 'error');
                const dateString = moment(new Date()).format('yyyy_MM_dd__HH_mm_ss');
                this._utilsService.exportExcel(loraResponses, `errores_de_activacion_${dateString}`);
            }
            else this._utilsService.openSnackBar('Contadores activados');

            this.showLoading(false);
            this.reload();
        }
        else this._utilsService.openSnackBar('No hay contadores seleccionados', 'warning');
    }

    /**
     * Opens the activation logs for the counter.
     * If the application is running in an Electron environment, it closes the counter dialog and navigates to the activation logs page.
     * If the application is running in a web browser, it opens a new tab with the activation logs page.
     */
    async openActivations() {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un contador', 'warning');
            return;
        }
        if (this.clickedRows.size != 1) {
            this._utilsService.openSnackBar('Debe seleccionar solo un contador', 'warning');
            return;
        }
        let serial_number: string = '';
        const counter: Counter | undefined = this.clickedRows.values().next().value;
        if (counter && counter.numero_serie_contador) serial_number = counter.numero_serie_contador;
        
        if (this._electronService.isElectronApp()) this.router.navigate(['/activation-logs', serial_number]);
        else {
            const urlTree = this.router.createUrlTree(['/activation-logs', serial_number]);
            const url = this.router.serializeUrl(urlTree);
            window.open(url, '_blank');
        }
    }

    /**
     * Exports the counters data to an Excel file.
     * @param counters - The array of counters to export.
     */
    exportExcel(counters: any) {
        let excelFormatCounters = [];
        for (let counter of counters) {
            let data: any = {};
            const columns = getCounterExcelExportColumns();
            columns.forEach((column) => {
                data[column] = this._utilsService.fieldPipe(
                    counter[getCounterExcelFieldName(column)],
                    column
                );
            });
            excelFormatCounters.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatCounters);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Contadoress_Exportados.xlsx');
    }

    /**
     * Handles the selection of a menu option.
     * @param option - The selected menu option.
     */
    async onMenuOptionSelected(option: string) {
        if (option == 'Asignar campos') {
            await this.openCounterAssingnation();
        } else if (option == 'Activar') {
            this.activateCountersInTable();
        }  else if (option == 'Ver activaciones') {
            this.openActivations();
        } else if (option == 'Exportar a EXCEL') {
            this.exportCountersInTable();
        } else if (option == 'Eliminar') {
            await this.deleteCounters();
        } else if (option == 'Add zeros') {
            await this.addZeroToCounters();
        }
    }

    /**
     * Opens the counter assignment dialog and assigns fields to selected counters.
     * If no counters are selected, displays a warning message.
     * @returns {Promise<void>} A promise that resolves when the assignment is complete.
     */
    async openCounterAssingnation(): Promise<void> {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un contador', 'warning');
            return;
        }
        try {
            const counter = await this._utilsService.openCounterAssignDialog(true);
            let errorIds = [];
            if (counter) {
                const keys = Object.keys(counter);
                this.loadingText = `Asignando campos a contadores ...`;

                let res = true;
                if (this.allSelected) {
                    res = await this._utilsService.openQuestionDialog(
                        'Tipo de asignación',
                        'Desea asignar solo a la página actual o a todas los contadores del filtro seleccionado',
                        'Página',
                        'Todas'
                    );
                }
                this._spinner.show();
                if (res) {
                    let ids: number[] = [];
                    for (let row of this.clickedRows) {
                        if (row.id) ids.push(row.id);
                    }
                    try {
                        const result = await this._apiService.updateCounters(ids, counter, false);
                        if (result) {
                            this.updateActualPageInfo(counter, keys);
                        } else {
                            errorIds = ids;
                        }
                    } catch (err) {
                        errorIds = ids;
                    }
                } else {
                    try {
                        let where_clause = '';
                        if (
                            this._utilsService.filterCounters &&
                            this._utilsService.filterCounters.fields
                        ) {
                            where_clause = this._utilsService.getWhereClauseFromFilter(
                                this._utilsService.filterCounters!
                            );
                        }
                        const result = await this._apiService.updateMultipleCounters(
                            where_clause,
                            counter,
                            false
                        );
                        if (result) this.updateActualPageInfo(counter, keys);
                        else if (this.counters.length > 0) errorIds.push(this.counters[0]);
                    } catch (err) {
                        if (this.counters.length > 0) errorIds.push(this.counters[0]);
                        console.log('============= err =============');
                        console.log(err);
                    }
                }
                if (errorIds.length > 0) {
                    this._utilsService.openSnackBar(`Hubo errores asignando contadores`, 'error');
                } else {
                    this._utilsService.openSnackBar(`Contadores asignadas correctamente`);
                }
                this._spinner.hide();
            }
        } catch (err) {}
    }

    /**
     * Updates the actual page information for the specified counter and keys.
     * @param counter - The counter object to update.
     * @param keys - The keys to update in the counter object.
     */
    updateActualPageInfo(counter: any, keys: any) {
        for (let row of this.clickedRows) {
            const index = this.counters.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof Counter;
                let value = counter[keyIndex]!; //this.task[key as keyof Itac];
                row[keyIndex] = value as any;
            }
            this.counters[index] = row;
        }
    }

    /**
     * Selects a radio option based on user input.
     * Opens a selector dialog and performs actions based on the selected option.
     * If "Activar radios pendientes" is selected, it activates pending counters.
     * If "Ver radios pendientes" is selected, it clears the counter filter and order, and applies a filter for activation pending counters.
     */
    async selectRadioOption() {
        const selection = await this._utilsService.openSelectorDialog('Selecione opción', [
            'Activar radios pendientes',
            'Ver radios pendientes',
        ]);
        if (selection == 'Activar radios pendientes') {
            await this.activatePendentCounters();
        } else if (selection == 'Ver radios pendientes') {
            this.clearCounterFilterAndOrder();
            await this.applyFilter([1], 'activation_pendent');
        }
    }

    /**
     * Activates the pending counters.
     * This method retrieves the pending counters from the API, activates them,
     * and updates the UI accordingly.
     * @returns {Promise<void>} A promise that resolves when the activation process is complete.
     */
    async activatePendentCounters(): Promise<void> {
        const agrupationId = await this._apiService.selectAgrupdationId();

        this.loadingText = 'Activando contadores ...';
        this._spinner.show();
        const counters: Counter[] = await this._apiService.getActivationPendentCounters();

        for(const counter of counters) {
            if (this._utilsService.isFieldNotValid(counter.agrupationId) && agrupationId) {
                counter.agrupationId = agrupationId;
            }
        }
        const text = counters.length == 1 ? 'contador' : 'contadores';
        this.loadingText = `Activando ${counters.length} ${text} ...`;

        let loraResponses: LoraResponse[] = await this._apiService.activateCounters(counters);
        
        this.is_pendent_activations = await this._mySqlService.isActivationPendentCounters();
        if (this.is_pendent_activations) {
            this._utilsService.openSnackBar('Error activando contadores, reintente', 'error');
            const dateString = moment(new Date()).format('yyyy_MM_dd__HH_mm_ss');
            this._utilsService.exportExcel(loraResponses, `errores_de_activacion_${dateString}`);
        }
        else this._utilsService.openSnackBar('Contadores activados correctamente');

        this.clearCounterFilterAndOrder();
        await this.applyFilter([1], 'activation_pendent');

        this._spinner.hide();
    }

    /**
     * Opens a dialog to add multiple counters.
     * 
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async openAddMultipleCounters(): Promise<void> {
        const counterModelRange = await this._utilsService.openCounterAssignDialog(false);
        if (counterModelRange) {
            this.showLoading(true);
            const company = localStorage.getItem('company');
            const manager = localStorage.getItem('manager');
            const startCounterInt = parseInt(counterModelRange.numero_serie_contador_start);
            let addCeros: boolean = false;
            if(startCounterInt <= 99999){
                addCeros = await this._utilsService.openQuestionDialog('Completar con ceros', 
                'Algunas series no contienen 6 dígitos,'+
                '¿Desea autocompletar estas series para que tengan el formato correcto?',
                'Si', 'No');
            }
            const endCounterInt = parseInt(counterModelRange.numero_serie_contador_end);
            const difference = endCounterInt - startCounterInt;
            let error: boolean = false;
            for (let i = 0; i <= difference; i++) {
                const counter = { ...counterModelRange };
                let serial = (startCounterInt + i).toString();
                if(addCeros) serial = this._utilsService.addCeros(serial, 6);

                counter.numero_serie_contador = `${counter.anno_o_prefijo}${serial}`;
                
                delete counter['numero_serie_contador_start'];
                delete counter['numero_serie_contador_end'];
                counter.company = parseInt(company!);
                counter.manager = parseInt(manager!);
                const res = await this._apiService.addDocument('counter', counter);
                if (res) this.counters.unshift(counter);
                else error = true;
            }
            if (error) this._utilsService.openSnackBar('Error subiendo contadores', 'error');
            else this._utilsService.openSnackBar('Contadores subidos correctamente');
            this.showLoading(false);
        }
    }

    /**
     * Opens the settings for the counters component.
     * This method calls the `_utilsService.openFilterConfigurationDialog` method
     * to open a dialog for configuring filters on the counters table.
     */
    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.countersTableName,
            this._utilsService.filterCounters
        );
    }

    /*@brief This funtions is for development, adding '0' to counters 
     */
    async addZeroToCounters() { 
        this._spinner.show();
        const counters: Counter[] = await this.getCountersInCurrentFilter();
        let i = 0;
        for (let counter of counters) { 
            if (counter.numero_serie_contador && counter.numero_serie_contador.length == 7) {
                this.loadingText = `Actualizando contadores (${++i} de ${counters.length}) ...`;
                const serial = this._utilsService.addCeros(counter.numero_serie_contador, 6);
                await this._apiService.updateCounter(counter.id!, { numero_serie_contador: serial }, false);
            }
        }
        this._spinner.hide();
    }

    /**
     * Retrieves the count of counters based on the current filter.
     * @returns A Promise that resolves to the number of counters.
     */
    async getCountersCountInCurrentFilter(): Promise<number> {
        let where_clause = this._utilsService.getCountersFilterWhereString();
        return await this._mySqlService.getCountersCount(where_clause);
    }

    /**
     * Retrieves the counters in the current filter.
     * @returns A Promise that resolves to an array of Counter objects.
     */
    async getCountersInCurrentFilter(): Promise<Counter[]> {
        const savedOffset = this._mySqlService.last_counter_offset;
        let counters: Counter[] = [];

        let where_clause = this._utilsService.getCountersFilterWhereString();

        const count = await this.getCountersCountInCurrentFilter();
        const order = this._utilsService.orderCounters;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
        let limit: number = 1000;
        for (let offset = 0; offset < count; offset += limit) {
            this.loadingText = `Obteniendo contadores (${offset} de ${count}) ...`;
            const serverCounters = await this._mySqlService.getCounters(
                undefined,
                where_clause,
                order_clause,
                offset.toString(),
                limit.toString()
            );
            counters = counters.concat(serverCounters);
        }
        this._mySqlService.last_counter_offset = savedOffset;
        return counters;
    }
}
