<mat-dialog-content class=" animate__animated animate__fadeIn animate__slow">
    <div [formGroup]="radiusModuleFormData"
    class=" animate__animated animate__fadeIn animate__slow">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de módulo de radio</p>
                        </div> 
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>SERIE MÓDULO</mat-label>
                                    <input formControlName="radius_module_hrid" matInput type="text" placeholder="SERIE MÓDULO">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>SERIE CONTADOR</mat-label>
                                    <input formControlName="meter_serial_number" matInput type="text" placeholder="SERIE CONTADOR">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>CALIBRE</mat-label>
                                    <input formControlName="meter_caliber" matInput type="text" placeholder="CALIBRE">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>APPEUI</mat-label>
                                    <input formControlName="appeui" matInput type="text" placeholder="APPEUI">
                                </mat-form-field>
                            </div>
                            
                            <div class="row-content">
                                    
                                <mat-form-field appearance="fill">
                                    <mat-label>DEVEUI</mat-label>
                                    <input formControlName="deveui" matInput type="text" placeholder="DEVEUI">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>APPKEY</mat-label>
                                    <input formControlName="appkey" matInput type="text" placeholder="APPKEY">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>EMITTER_REF</mat-label>
                                    <input formControlName="emitter_ref" matInput type="text" placeholder="EMITTER_REF">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>DL_SN</mat-label>
                                    <input formControlName="dl_sn" matInput type="text" placeholder="DL_SN">
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Inventariado</mat-label>
                                    <input
                                        [value]="
                                            this.radiusModule?.inventory_sync! | counterInventorySync
                                        "
                                        matInput
                                        type="text"
                                        placeholder="Inventariado"
                                        readonly
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Depósito</mat-label>
                                    <input
                                        formControlName="deposit"
                                        matInput
                                        type="text"
                                        placeholder="deposit"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 140px" appearance="fill">
                                    <mat-label>Fecha de Inventario</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_inventory_date"
                                        formControlName="inventory_date"
                                        placeholder="Fecha de Inventario"
                                        readonly
                                    />
                                    <mat-datepicker #picker_inventory_date></mat-datepicker>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner [bdColor] = "(radiusModule)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="radiusModuleSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="radiusModule">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (radiusModuleId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>
