import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'counterInventorySync'
})
export class CounterInventorySyncPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: any, ...args: unknown[]): string {
        const counterSyncStatusString =
            this._utilsService.getCounterInventoryStatusFromBoolean(value);

        return counterSyncStatusString;
    }

}
