/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { Company } from '../interfaces/company';
import { MiRutaUser } from '../interfaces/mi-ruta-user';
import { Observation } from '../interfaces/observation';
import { Part } from '../interfaces/part';
import { WaterTask, convertFromServer } from '../interfaces/water-task';
import { Notification, NotificationAndCount } from '../interfaces/notification';
import { Result } from '../interfaces/result';
import { Cause } from '../interfaces/cause';
import { UtilsService } from './utils.service';
import { Zone } from '../interfaces/zone';
import { Itac } from '../interfaces/itac';
import { Info } from '../interfaces/info';
import { FileUpload } from '../classes/file-upload';
import { Observable, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Team } from '../interfaces/team';
import { Mark } from '../interfaces/mark';
import { Emplacement } from '../interfaces/emplacement';
import { WaterRoute } from '../interfaces/water-route';
import { ClassCounter } from '../interfaces/class-counter';
import { Counter, counter_status } from 'src/app/interfaces/counter';
import { Manager } from '../interfaces/manager';
import { LoraResponse } from '../interfaces/lora-response';
import { ImageService } from './image.service';
import { MyLatLng } from '../interfaces/lat-lng';
import { convertFootprintFromServer } from '../interfaces/footprint';
import { Footprint } from 'src/app/interfaces/footprint';
import { Prediction } from '../interfaces/place-predictions';
import { PlaceDetails } from '../interfaces/place-details';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { ActivationData } from '../interfaces/activation-data';
import { Agrupation } from '../interfaces/agrupation';
import { TypeRadius } from '../interfaces/type-radius';
import { TypeCounter } from 'src/app/interfaces/type-counter';
import { WaterTaskUpdate } from '../interfaces/water-task-update';
import { GoogleLocation } from '../interfaces/google-location';
import { ItelazpiDevice } from '../interfaces/itelazpi-device';
import { FcmToken } from '../interfaces/fcm-token';
import { DateStatus } from '../interfaces/date-status';
import { TotalExecutedInAYearPerUser } from '../interfaces/total-executed-in-a-year-per-user';
import { TotalExecutedInAMonthPerUser } from '../interfaces/total-executed-in-a-month-per-user';
import { TotalExecutedInADayPerUser } from '../interfaces/total-executed-in-a-day-per-user';
import { TotalExecutedPerMonth } from '../interfaces/total-executed-per-month';
import { TotalExecutedPerYear } from '../interfaces/total-executed-per-year';
import { AvgExecutedInADayPerMonth } from '../interfaces/avg-executed-in-a-day-per-month';
import { AvgExecutedInADayPerUserPerYear } from '../interfaces/avg-executed-in-a-day-per-user-per-year';
import { AvgExecutedInADayPerYear } from '../interfaces/avg-executed-in-a-day-per-year';
import { AvgExecutedInADayPerUserPerMonth } from '../interfaces/avg-executed-in-a-day-per-user-per-month';
import { AvailabilityDayMap, AvailabilityDaysMap } from '../interfaces/availability-map';
import { Planning } from '../interfaces/planning';
import { Sector } from '../interfaces/sector';
import { UserAction, convertUserActionFromServer } from '../interfaces/user-action';
import { PlanningDetail } from '../interfaces/planning-detail';
import { PlanningDetailExtra } from '../interfaces/planning-detail-extra';
import { UserValidation } from '../interfaces/user-validation';
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    url_server: string = environment.url_server;

    userCredentials: any;
    deleteUser$: any;

    _lastDocument: any;

    /**
     * Represents the API service.
     */
    constructor(
        private http: HttpClient,
        private _utilsService: UtilsService,
        private _imageService: ImageService
    ) {}

    /**
     * Selects a user for the week calendar.
     * 
     * @returns A promise that resolves when the user is selected.
     */
    async selectUserForWeekCalendar(currentUsers?: MiRutaUser[]): Promise<MiRutaUser | null> {
        try {
            const users = (currentUsers && currentUsers.length)? currentUsers: await this.getUsers(['operario']);
            const userSelected = await this._utilsService.openSelectorDialog(
                'Seleccione operario',
                users.map((user) => this._utilsService.userPipe(user.id))
            );
            const user = users.find((u) => this._utilsService.userPipe(u.id) == userSelected);
            if (user) {
                sessionStorage.setItem('calendarSelectedUser', JSON.stringify(user));
                return user;
            }
        } catch (err) {}
        return null;
    }

    /**
     * Retrieves an image from the specified URL.
     * @param url - The URL of the image to retrieve.
     * @returns A Promise that resolves to the image data as a string.
     */
    async getImage(url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this._imageService.getData(url).subscribe(
                (imgData) => {
                    resolve(imgData);
                },
                (err) => {
                    reject();
                }
            );
        });
    }

    /**
     * Retrieves the activation pendent counters from the API.
     * @returns {Promise<Counter[]>} A promise that resolves to an array of Counter objects.
     */
    async getActivationPendentCounters(): Promise<Counter[]> {
        const counters: Counter[] = await this.getDocuments<Counter>('counter', [
            ['activation_pendent', '==', '1'],
        ]);
        return counters;
    }

    /**
     * Assigns a location task to a user.
     * 
     * @param waterTask - The water task to be assigned.
     * @param coords - The coordinates of the location.
     * @param userId - The ID of the user.
     */
    async assignLocationTask(waterTask: WaterTask, coords: MyLatLng, userId: string) {
        await this.updateTask(waterTask.id!.toString(), {
            ultima_modificacion: userId,
            codigo_de_localizacion: coords,
            geolocalizacion: coords,
            pendent_location: false,
            url_geolocalizacion: this._utilsService.getGeolocationUrl(coords),
        });
    }

    /**
     * Retrieves a list of versions from the server.
     * @returns A Promise that resolves with the list of versions.
     */
    async listVersions(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.http
                .get(`${this.url_server}/file-service/desktop-list`)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject(false);
                    }
                });
        });
    }

    /**
     * Activates the Itelazpi module for a given device.
     * 
     * @param device - The Itelazpi device to activate.
     * @returns A promise that resolves to a boolean indicating whether the activation was successful.
     */
    async activateItelazpiModule(device: ItelazpiDevice): Promise<boolean> {
        const company = localStorage.getItem('company');
        return new Promise(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/integration-itelazpi/activate/${company}/`, device)
                .subscribe(async (data: any) => {
                    console.log('============= activateItelazpi request =============');
                    console.log(data);
                    if (data && data.status) {
                        resolve(data);
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    /**
     * Activates the counters by sending activation data to the server.
     * 
     * @param counters - An array or set of counters to activate.
     * @returns A promise that resolves to an array of LoraResponse objects.
     */
    async activateCounters(counters: Counter[] | Set<Counter>): Promise<LoraResponse[]> {
        let { objectToSend, invalidLoras } = this.getArrayObjectToSend(counters);
        let loraResponses: LoraResponse[] = (invalidLoras && invalidLoras.length)? invalidLoras: [];
        if (objectToSend.length) {
            for (let i = 0; i < objectToSend.length; i += 50) {
                let objectToSendPart = objectToSend.slice(i, i + 50);
                const loraResponsesPart = await this.activate(objectToSendPart);
                loraResponses = loraResponses.concat(loraResponsesPart);
            }
            return loraResponses;
        }
        return [];
    }

    /**
     * Converts an array of counters or a set of counters into an array of ActivationData objects.
     * @param counters - An array of Counter objects or a Set of Counter objects.
     * @returns An array of ActivationData objects.
     */
    getArrayObjectToSend(counters: Counter[] | Set<Counter>) {
        let objectToSend: ActivationData[] = [];
        let invalidLoras: LoraResponse[] = [];
        for (let counter of counters) {
            const loraResponse = this._utilsService.checkCounterForActivationAndCause(counter);
            if (!loraResponse.status) {
                invalidLoras.push(loraResponse);
                continue;
            }
            let activationData: ActivationData = this._utilsService.getActivationData(counter);
            objectToSend.push(activationData);
        }
        return { objectToSend, invalidLoras };
    }

    /**
     * Activates an object by sending a POST request to the server.
     * @param objectToSend - The object to send in the request.
     * @returns A promise that resolves with an array of LoraResponse objects if successful, or rejects with `false` if unsuccessful.
     */
    async activate(objectToSend: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/lora/activate/`, objectToSend)
                .subscribe(async (data: any) => {
                    if (data) {
                        let loraResponses: LoraResponse[] = [];
                        if (Array.isArray(data)) loraResponses = data;
                        else loraResponses = [data];
                        resolve(loraResponses);
                    } else {
                        reject(false);
                    }
                });
        });
    }

    /**
     * Deletes a user by their ID.
     * @param userId The ID of the user to delete.
     * @returns A Promise that resolves to `true` if the user was deleted successfully, or `false` otherwise.
     * @throws {string} If the `userId` parameter is not provided.
     */
    async onDeleteUser(userId: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if (userId) {
                this.http
                    .delete(`${this.url_server}/user/${userId}`)
                    .subscribe(async (data: any) => {
                        // console.log('************* user *************');
                        // console.log(data);
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                // console.log('****************** deleteUser fail *************');
                reject('No user id');
            }
        });
    }

    /**
     * Uploads a base64 file to a storage path.
     * @param base64 - The base64 string representing the file.
     * @param storagePath - The path where the file will be stored.
     * @param filename - The name of the file.
     * @param metadata - Additional metadata for the file.
     * @returns A promise that resolves to the URL of the uploaded file.
     */
    async uploadBase64File(
        base64: string,
        storagePath: string,
        filename: string,
        metadata: any
    ): Promise<string> {
        metadata.contentType = filename.includes('.jpg')
            ? 'image/jpeg'
            : 'application/octet-stream';
        return new Promise<string>((resolve, reject) => {
            resolve('');
        });
    }

    /**
     * Uploads an image for a manager.
     * @param formData - The form data containing the image to upload.
     * @param id - The ID of the manager.
     * @returns A promise that resolves with the uploaded image data, or rejects with an error.
     */
    async uploadManagerImage(formData: any, id: number) {
        return new Promise<any>((resolve, reject) => {
            this.http
                .post(`${this.url_server}/manager/${id.toString()}/upload`, formData)
                .subscribe(
                    (data) => {
                        if (data) resolve(data);
                        else reject(data);
                    },
                    (error) => reject()
                );
        });
    }

    /**
     * Uploads a user image to the server.
     * @param formData - The form data containing the image file.
     * @param id - The ID of the user.
     * @returns A promise that resolves with the response data if successful, or rejects with an error.
     */
    async uploadUserImage(formData: any, id: number) {
        return new Promise<any>((resolve, reject) => {
            this.http.post(`${this.url_server}/user/${id.toString()}/upload`, formData).subscribe(
                (data) => {
                    if (data) resolve(data);
                    else reject(data);
                },
                (error) => reject()
            );
        });
    }

    /**
     * Uploads a task image.
     * @param formData - The form data containing the image file.
     * @param task - The WaterTask object associated with the image.
     * @returns A promise that resolves with the uploaded image data, or rejects with an error.
     */
    async uploadTaskImage(formData: any, task: WaterTask) {
        return new Promise<any>((resolve, reject) => {
            this.http
                .post(
                    `${this.url_server}/water-task/upload/${task.id}/${task.company}/${task.GESTOR}/${task.Numero_de_ABONADO}/${task.ANOMALIA}`,
                    formData
                )
                .subscribe(
                    (data) => {
                        if (data) resolve(data);
                        else reject(data);
                    },
                    (error) => reject()
                );
        });
    }

    /**
     * Uploads an image for the specified ITAC.
     * @param formData - The form data containing the image file.
     * @param itac - The ITAC object.
     * @returns A promise that resolves with the response data if successful, or rejects with an error.
     */
    async uploadItacImage(formData: any, itac: Itac) {
        return new Promise<any>((resolve, reject) => {
            this.http
                .post(
                    `${this.url_server}/itac/upload/${itac.id}/${itac.company}/${itac.gestor}/${itac.codigo_itac}`,
                    formData
                )
                .subscribe(
                    (data) => {
                        if (data) resolve(data);
                        else reject(data);
                    },
                    (error) => reject()
                );
        });
    }

    /**
     * Creates a new user in the MiRuta system.
     * @param miRutaUser - The user object to be created.
     * @returns A promise that resolves with the created user data, or rejects if an error occurs.
     */
    async createUser(miRutaUser: MiRutaUser): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.http.post(`${this.url_server}/user/`, miRutaUser).subscribe(async (data: any) => {
                if (data) resolve(data);
                else reject();
            });
        });
    }

    /**
     * Retrieves the user's footprints for a specific day.
     * @param id - The ID of the user.
     * @param day - The specific day for which to retrieve the footprints.
     * @returns A promise that resolves to an array of Footprint objects.
     */
    async getUserFootPrint(id: number, day: string): Promise<Footprint[]> {
        return new Promise<Footprint[]>(async (resolve, reject) => {
            let body = { day: day };
            this.http
                .post(`${this.url_server}/user/${id}/footprints_day`, body)
                .subscribe(async (data: any) => {
                    if (data) {
                        const footprints: Footprint[] = data.map((e: any) =>
                            convertFootprintFromServer(e)
                        );
                        resolve(footprints);
                    } else reject();
                });
        });
    }

    /**
     * Retrieves the user actions for a specific day.
     * @param id - The ID of the user.
     * @param day - The day for which to retrieve the user actions.
     * @returns A promise that resolves to an array of UserAction objects.
     */
    async getUserActionsDay(id: number, day: string): Promise<UserAction[]> {
        return new Promise<UserAction[]>(async (resolve, reject) => {
            let body = { day: day };
            this.http
                .post(`${this.url_server}/user-action/${id}/day`, body)
                .subscribe(async (data: any) => {
                    if (data) {
                        const userActions: UserAction[] = data.map((e: any) =>
                            convertUserActionFromServer(e)
                        );
                        resolve(userActions);
                    } else reject();
                });
        });
    }

    /**
     * Retrieves the footprints of a user.
     * @param id - The ID of the user.
     * @returns A promise that resolves with the user's footprints.
     */
    async getUserFootPrints(id: number): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.http
                .get(`${this.url_server}/user/${id}/footprints`)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Adds a user's footprint to the server.
     * @param id - The ID of the user.
     * @param pos - The position of the footprint.
     * @returns A promise that resolves with the response from the server.
     */
    async addUserFootPrint(id: number, pos: MyLatLng): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            let body = {
                footprint: pos,
                date: new Date(),
            };
            this.http
                .post(`${this.url_server}/user/${id}/footprints`, body)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Logs in the user with the provided email and password.
     * @param email - The user's email.
     * @param password - The user's password.
     * @returns A Promise that resolves to a boolean indicating whether the login was successful.
     */
    async login(email: string, password: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const postParams = {
                username: email,
                password: password,
                app_type: 'desktop_web_version',
                version: this._utilsService.app_version,
            };
            this.http
                .post(`${this.url_server}/auth/login`, postParams)
                .subscribe(async (data: any) => {
                    if (data && data.access_token) {
                        localStorage.setItem('access_token', data.access_token);
                        sessionStorage.setItem('user', JSON.stringify(data.user));
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    /**
     * Logs in to the development server using predefined credentials.
     * 
     * @returns {Promise<boolean>} A promise that resolves to `true` if login is successful, otherwise `false`.
     * 
     * @remarks
     * This method sends a POST request to the development server's login endpoint with the username and password
     * specified in the environment configuration. If the login is successful, the access token is stored in 
     * localStorage and the user information is stored in sessionStorage.
     * 
     * @example
     * ```typescript
     * const isLoggedIn = await apiService.loginDevServer();
     * if (isLoggedIn) {
     *   console.log('Login successful');
     * } else {
     *   console.log('Login failed');
     * }
     * ```
     */
    async loginDevServer(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const postParams = {
                username: environment.dev_user,
                password: environment.dev_password,
                app_type: 'desktop_web_version',
                version: this._utilsService.app_version,
            };
            this.http
                .post(`${environment.dev_server}/auth/login`, postParams)
                .subscribe(async (data: any) => {
                    if (data && data.access_token) {
                        localStorage.setItem('access_token', data.access_token);
                        sessionStorage.setItem('user', JSON.stringify(data.user));
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    }

    async logout() {}

    getWebUniqueId() {
        return navigator.userAgent + navigator.platform;
    }

    /**
     * Adds the FCM token to the server for the logged-in user.
     * @param token - The FCM token to be added.
     * @returns A promise that resolves with the ID of the added token.
     * @throws If the logged-in user is not found.
     */
    async addFCMToken(token: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            const user = this._utilsService.getLoggedInUser();
            if(!user) reject('Not found user');
            const fcmToken: FcmToken = {
                username: user?.username,
                user_id: user?.id,
                token: token,
                device: this.getWebUniqueId(),
                device_type: 'web',
                create_at: new Date,
                date_time_modified: new Date,
            };
            this.http
                .post(`${this.url_server}/fcm-token/`, fcmToken)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data.id);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Adds a Google location to the server.
     * @param googleLocation - The Google location to be added.
     * @returns A promise that resolves with the ID of the added location.
     */
    async addGoogleLocation(googleLocation: GoogleLocation): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/google-location/`, googleLocation)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data.id);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Adds a water task.
     * @param waterTask - The water task to be added.
     * @returns A promise that resolves with the ID of the added task.
     */
    async addTask(waterTask: WaterTask): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/water-task/`, waterTask)
                .subscribe(async (data: any) => {
                    if (data && data.NUMIN) {
                        resolve(data.id);
                    } else reject();
                });
        });
    }

    /**
     * Adds multiple water tasks.
     * 
     * @param waterTasks - An array of WaterTask objects representing the tasks to be added.
     * @returns A Promise that resolves with the response data when the tasks are successfully added, or rejects if an error occurs.
     */
    async addMultipleTasks(waterTasks: WaterTask[]): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/water-task/create/multiple`, waterTasks)
                .subscribe(async (data: any) => {
                    if (data) resolve(data);
                    else reject();
                });
        });
    }

    /**
     * Adds a counter to the server.
     * 
     * @param counter - The counter object to be added.
     * @returns A promise that resolves with the ID of the added counter.
     */
    async addCounter(counter: Counter): Promise<any> {
        let company = localStorage.getItem('company');
        let manager = localStorage.getItem('manager');
        counter.company = parseInt(company!);
        counter.manager = parseInt(manager!);
        counter.date_time_modified = new Date();
        counter.status_contador = counter_status.AVAILABLE;

        return new Promise<any>(async (resolve, reject) => {
            this.http.post(`${this.url_server}/counter/`, counter).subscribe(async (data: any) => {
                if (data) {
                    resolve(data.id);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Adds a new ITAC (Information Technology Asset Change) to the server.
     * @param itac - The ITAC object to be added.
     * @returns A promise that resolves with the ID of the added ITAC, or rejects if an error occurs.
     */
    async addItac(itac: Itac): Promise<any> {
        let company = localStorage.getItem('company');
        let manager = localStorage.getItem('manager');

        itac.company = parseInt(company || '1');
        itac.gestor = parseInt(manager || '1');

        return new Promise<any>(async (resolve, reject) => {
            this.http.post(`${this.url_server}/itac/`, itac).subscribe(async (data: any) => {
                if (data) {
                    resolve(data.id);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Adds a document to the specified table.
     * @param table_name - The name of the table to add the document to.
     * @param data - The data of the document to be added.
     * @returns A promise that resolves with the ID of the added document, or rejects if an error occurs.
     */
    async addDocument(table_name: string, data: any): Promise<any> {
        const timestamp = new Date();
        data.date_time_modified = timestamp;
        localStorage.setItem(`${table_name}s`, '');

        return new Promise<any>(async (resolve, reject) => {
            this.http
                .post(`${this.url_server}/${table_name}/`, data)
                .subscribe(async (data: any) => {
                    if (data) {
                        resolve(data.id);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Deletes a task by its ID.
     * @param taskId - The ID of the task to be deleted.
     * @returns A promise that resolves to a boolean indicating whether the task was successfully deleted.
     */
    async deleteTask(taskId: string | number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (taskId) {
                this.http
                    .delete(`${this.url_server}/water-task/${taskId}`)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject('No task id');
            }
        });
    }

    /**
     * Deletes an ITAC (Internal Technical Assistance Center) by its ID.
     * @param itacId - The ID of the ITAC to be deleted.
     * @returns A Promise that resolves to a boolean indicating whether the deletion was successful.
     *          The Promise resolves to `true` if the ITAC was deleted successfully, and `false` otherwise.
     *          The Promise rejects with an error message if the `itacId` parameter is not provided.
     */
    async deleteItac(itacId: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (itacId) {
                this.http
                    .delete(`${this.url_server}/itac/${itacId}`)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject('No itac id');
            }
        });
    }

    /**
     * Deletes a counter with the specified counterId.
     * @param counterId - The ID of the counter to delete.
     * @returns A promise that resolves to a boolean indicating whether the deletion was successful.
     */
    async deleteCounter(counterId: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (counterId) {
                this.http
                    .delete(`${this.url_server}/counter/${counterId}`)
                    .subscribe(async (data: any) => {
                        // console.log('************* counter *************');
                        // console.log(data);
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                // console.log('****************** deletecounter fail *************');
                reject('No counter id');
            }
        });
    }

    /**
     * Deletes a pending call with the specified pending call Id.
     * @param counterId - The ID of the pending call to delete.
     * @returns A promise that resolves to a boolean indicating whether the deletion was successful.
     */
    async deletePendingCall(pendingCallId: string | number): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (pendingCallId) {
                this.http
                    .delete(`${this.url_server}/vonage/${pendingCallId}`)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else reject('No counter id');
        });
    }

    /**
     * Deletes a document from the specified collection.
     * @param collection_name - The name of the collection.
     * @param documentId - The ID of the document to delete.
     * @returns A promise that resolves to a boolean indicating whether the document was successfully deleted.
     */
    async deleteDocument(collection_name: string, documentId: string): Promise<boolean> {
        localStorage.setItem(`${collection_name}s`, '');

        return new Promise(async (resolve, reject) => {
            if (documentId) {
                const subcription = this.http
                    .delete(`${this.url_server}/${collection_name}/${documentId}`)
                    .subscribe(
                        (data: any) => {
                            subcription.unsubscribe();
                            if (data && data.affected > 0) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        },
                        (error) => {
                            reject();
                        }
                    );
            } else {
                reject();
            }
        });
    }

    /**
     * Executes a batch of tasks.
     * @param taskIds - An array of task IDs.
     * @param taskData - The data associated with the tasks.
     * @param tableUpdate - Optional. Specifies whether to update the table.
     * @returns A promise that resolves to a boolean indicating the success of the batch execution.
     */
    async batchTasks(
        taskIds: string[],
        taskData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            resolve(true);
        });
    }

    /**
     * Performs a batch update of ITACs (Individual Task and Control) using the provided data.
     * @param itacIds - An array of ITAC IDs to be updated.
     * @param itacData - The data to be applied to the ITACs.
     * @param tableUpdate - Optional. Specifies whether the table should be updated after the batch operation. Default is false.
     * @returns A promise that resolves to a boolean indicating the success of the batch operation.
     */
    async batchItacs(
        itacIds: string[],
        itacData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            resolve(true);
        });
    }

    /**
     * Retrieves the company and manager where string.
     * @param where - The where string in JSON format.
     * @returns The modified where string with company and manager filters.
     */
    getCompanyAndManagerWhereString(where: string): string {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        let whereJsonArray: any = [];
        if (where) whereJsonArray = JSON.parse(where);

        const companyJson = { field: 'company', type: 'AND', value: company };
        whereJsonArray.push(companyJson);
        const managerJson = { field: 'GESTOR', type: 'AND', value: manager };
        whereJsonArray.push(managerJson);
        return JSON.stringify(whereJsonArray);
    }

    /**
     * Updates multiple tasks based on the provided conditions.
     * 
     * @param where - The conditions to filter the tasks to be updated.
     * @param taskData - The data to update the tasks with.
     * @param tableUpdate - Optional. Specifies whether the table needs to be updated. Default is false.
     * @returns A Promise that resolves to a boolean indicating whether the update was successful.
     */
    async updateMultipleTasks(
        where: string,
        taskData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        taskData.date_time_modified = new Date();
        return new Promise(async (resolve, reject) => {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                if (!company || !manager) reject(false);

                if (where && taskData) {
                    const params = {
                        where: this.getCompanyAndManagerWhereString(where),
                        data: taskData,
                    };
                    this.http
                        .put(`${this.url_server}/water-task/multiple/where`, params)
                        .subscribe(async (data: any) => {
                            if (data && data.affected > 0) {
                                if (tableUpdate) localStorage.setItem('taskUpdateNeeded', 'true');
                                resolve(true);
                            } else resolve(false);
                        });
                } else reject(false);
            } catch (err) {
                reject(false);
            }
        });
    }

    /**
     * Updates multiple ITACs based on the provided conditions.
     * 
     * @param where - The conditions to filter the ITACs to be updated.
     * @param itacData - The data to update the ITACs with.
     * @param tableUpdate - Optional. Specifies whether to update the table after the ITACs are updated. Defaults to false.
     * @returns A promise that resolves to true if the ITACs are updated successfully, or false otherwise.
     */
    async updateMultipleItacs(
        where: string,
        itacData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        itacData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                if (!company || !manager) reject(false);

                let whereJsonArray: any = [];
                if (where) whereJsonArray = JSON.parse(where);

                const companyJson = { field: 'company', type: 'AND', value: company };
                whereJsonArray.push(companyJson);
                const managerJson = { field: 'gestor', type: 'AND', value: manager };
                whereJsonArray.push(managerJson);

                if (whereJsonArray && itacData) {
                    const params = {
                        where: JSON.stringify(whereJsonArray),
                        data: itacData,
                    };
                    this.http
                        .put(`${this.url_server}/itac/multiple/where`, params)
                        .subscribe(async (data: any) => {
                            if (data && data.affected > 0) {
                                if (tableUpdate) {
                                    localStorage.setItem('itacUpdateNeeded', 'true');
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                } else {
                    reject(false);
                }
            } catch (err) {
                reject(false);
            }
        });
    }

    /**
     * Updates multiple counters based on the provided conditions.
     * @param where - The conditions to filter the counters.
     * @param counterData - The data to update the counters with.
     * @param tableUpdate - Optional. Specifies whether to update the table after the counters are updated. Defaults to false.
     * @returns A promise that resolves to a boolean indicating whether the counters were successfully updated.
     */
    async updateMultipleCounters(
        where: string,
        counterData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        counterData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                if (!company || !manager) reject(false);

                let whereJsonArray: any = [];
                if (where) whereJsonArray = JSON.parse(where);

                const companyJson = { field: 'company', type: 'AND', value: company };
                whereJsonArray.push(companyJson);

                if (whereJsonArray && counterData) {
                    const params = {
                        where: JSON.stringify(whereJsonArray),
                        data: counterData,
                    };
                    this.http
                        .put(`${this.url_server}/counter/multiple/where`, params)
                        .subscribe(async (data: any) => {
                            if (data && data.affected > 0) {
                                if (tableUpdate) {
                                    localStorage.setItem('counterUpdateNeeded', 'true');
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                } else {
                    reject(false);
                }
            } catch (err) {
                reject(false);
            }
        });
    }

    /**
     * Updates multiple water routes based on the provided conditions.
     * @param where - The conditions to filter the water routes.
     * @param water_routeData - The data to update the water routes with.
     * @param tableUpdate - Optional. Specifies whether to update the table. Default is false.
     * @returns A promise that resolves to a boolean indicating whether the update was successful.
     */
    async updateMultipleWaterRoute(
        where: string,
        water_routeData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        water_routeData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                if (!company || !manager) reject(false);

                let whereJsonArray: any = [];
                if (where) whereJsonArray = JSON.parse(where);

                const companyJson = { field: 'company', type: 'AND', value: company };
                whereJsonArray.push(companyJson);

                if (whereJsonArray && water_routeData) {
                    const params = {
                        where: JSON.stringify(whereJsonArray),
                        data: water_routeData,
                    };
                    this.http
                        .put(`${this.url_server}/water-route/multiple/where`, params)
                        .subscribe(async (data: any) => {
                            if (data && data.affected > 0) {
                                if (tableUpdate) {
                                    localStorage.setItem('water_routeUpdateNeeded', 'true');
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                } else {
                    reject(false);
                }
            } catch (err) {
                reject(false);
            }
        });
    }

    /**
     * Updates multiple tasks with the provided task IDs and data.
     * @param {number[]} taskIds - An array of task IDs to update.
     * @param {any} taskData - The data to update the tasks with.
     * @param {boolean} [tableUpdate=false] - Optional parameter to indicate if table update is needed.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating if the tasks were updated successfully.
     */
    async updateTasks(
        taskIds: number[],
        taskData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        taskData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            if (taskIds.length > 0) {
                const params = {
                    ids: taskIds,
                    data: taskData,
                };
                this.http
                    .put(`${this.url_server}/water-task/multiple`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) localStorage.setItem('taskUpdateNeeded', 'true');
                            resolve(true);
                        } else resolve(false);
                    });
            } else reject(false);
        });
    }

    /**
     * Checks the current token user.
     * @returns A Promise that resolves with the user data if the token is valid, or an empty object if the token is invalid.
     */
    async checkCurrentTokenUser(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.http.get(`${this.url_server}/auth/profile`).subscribe(async (data: any) => {
                if (data) {
                    resolve(data);
                } else {
                    resolve({});
                }
            });
        });
    }

    /**
     * Changes the password for a given username.
     * @param username - The username for which the password needs to be changed.
     * @returns A Promise that resolves to a string representing the result of the password change operation.
     */
    async changePassword(username: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (username) {
                const params = {
                    username: username,
                };
                this.http
                    .post(`${this.url_server}/auth/forgot-password`, params)
                    .subscribe(async (data: any) => {
                        if (data) {
                            resolve(data);
                        } else {
                            resolve('');
                        }
                    });
            } else {
                reject('');
            }
        });
    }

    /**
     * Sets a new password for a user.
     * @param {string} token - The token for password reset.
     * @param {string} password - The new password to set.
     * @returns {Promise<boolean>} - A promise that resolves to true if the password is set successfully, or false otherwise.
     */
    async setNewPassword(token: string, password: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (password && password.length > 0 && token) {
                const params = {
                    token: token,
                    password: password,
                };
                this.http
                    .post(`${this.url_server}/auth/change-password`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject(false);
            }
        });
    }

    /**
     * Updates the specified ITACs with the provided data.
     * @param itacIds - An array of ITAC IDs to update.
     * @param itacData - The data to update the ITACs with.
     * @param tableUpdate - Optional. Specifies whether to update the table. Default is false.
     * @returns A Promise that resolves to a boolean indicating whether the update was successful.
     */
    async updateItacs(
        itacIds: number[],
        itacData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        itacData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            if (itacIds.length > 0) {
                const params = {
                    ids: itacIds,
                    data: itacData,
                };
                this.http
                    .put(`${this.url_server}/itac/multiple`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) {
                                localStorage.setItem('itacUpdateNeeded', 'true');
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject(false);
            }
        });
    }

    /**
     * Updates the counters with the specified IDs and data.
     * @param counterIds - An array of counter IDs to update.
     * @param counterData - The data to update the counters with.
     * @param tableUpdate - Optional. Specifies whether to update the table. Defaults to false.
     * @returns A promise that resolves to a boolean indicating whether the counters were updated successfully.
     */
    async updateCounters(
        counterIds: number[],
        counterData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        counterData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            if (counterIds.length > 0) {
                const params = {
                    ids: counterIds,
                    data: counterData,
                };
                this.http
                    .put(`${this.url_server}/counter/multiple`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) {
                                localStorage.setItem('counterUpdateNeeded', 'true');
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject(false);
            }
        });
    }

    /**
     * Updates water routes with the provided IDs and data.
     * @param {number[]} waterRouteIds - The IDs of the water routes to update.
     * @param {any} waterRouteData - The data to update the water routes with.
     * @param {boolean} tableUpdate - Optional. Indicates whether the table needs to be updated. Default is false.
     * @returns {Promise<boolean>} - A promise that resolves to true if the update is successful, or false otherwise.
     */
    async updateWaterRoutes(
        waterRouteIds: number[],
        waterRouteData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        const timestamp = new Date();
        waterRouteData.date_time_modified = timestamp;

        return new Promise(async (resolve, reject) => {
            if (waterRouteIds.length > 0) {
                const params = {
                    ids: waterRouteIds,
                    data: waterRouteData,
                };
                this.http
                    .put(`${this.url_server}/water-route/multiple`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) {
                                localStorage.setItem('waterRouteUpdateNeeded', 'true');
                            }
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                reject(false);
            }
        });
    }

    /**
     * Selects a team from the available teams.
     * 
     * @returns A Promise that resolves to the selected Team object, or null if no team is selected.
     */
    async selectTeam(): Promise<Team | null> {
        const teams = await this.getTeams();
        if (teams && teams.length) {
            const teamSelectedName = await this._utilsService.openSelectorDialog(
                'Seleccione equipo',
                teams.map((team: Team) => team.equipo_operario)
            );
            if (teamSelectedName) {
                const team = teams.find((team: Team) => team.equipo_operario == teamSelectedName);
                if (team) return team;
            }
        }
        this._utilsService.openSnackBar('No hay equipos para esta empresa y gestor','warning');
        return null;
    }

    /**
     * Retrieves a list of teams from the API and allows the user to select a team.
     * If no teams are available, a warning message is displayed.
     * 
     * @returns The ID of the selected team, or -1 if no team is selected or available.
     */
    async selectTeamId(): Promise<number> {
        const team = await this.selectTeam();
        if (team) return team.id;
        return -1;
    }
    
    /**
     * Updates a task with the given task ID and data.
     * @param taskId - The ID of the task to update.
     * @param taskData - The data to update the task with.
     * @param tableUpdate - Optional. Specifies whether to update the table. Default is false.
     * @returns A promise that resolves to a boolean indicating whether the task was updated successfully.
     */
    async updateTask(
        taskId: string | number,
        taskData: any,
        tableUpdate: boolean = false
    ): Promise<boolean> {
        taskData.date_time_modified = new Date();
        return new Promise(async (resolve, reject) => {
            if (taskId) {
                this.http
                    .patch(`${this.url_server}/water-task/${taskId}`, taskData)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) {
                                localStorage.setItem('taskUpdateNeeded', 'true');
                            }
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Updates the specified itac with the provided data.
     * @param itacId - The ID of the itac to update.
     * @param itacData - The data to update the itac with.
     * @param tableUpdate - Optional. Specifies whether to update the table. Default is true.
     * @returns A promise that resolves to true if the itac was updated successfully, or false otherwise.
     */
    async updateItac(itacId: string, itacData: any, tableUpdate: boolean = true): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (itacId) {
                this.http
                    .patch(`${this.url_server}/itac/${itacId}`, itacData)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if (tableUpdate) localStorage.setItem('itacUpdateNeeded', 'true');
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    });
            } else {
                // console.log('****************** updateItac fail *************');
                resolve(false);
            }
        });
    }

    /**
     * Updates a counter with the specified counterId and counterData.
     * @param counterId - The ID of the counter to update.
     * @param counterData - The data to update the counter with.
     * @param updateTable - Optional. Specifies whether to update the table. Defaults to true.
     * @returns A Promise that resolves to a boolean indicating whether the counter was updated successfully.
     */
    async updateCounter(counterId: string | number, counterData: any, updateTable: boolean = true): Promise<boolean> {
        let company = localStorage.getItem('company');

        return new Promise<any>(async (resolve, reject) => {
            if (counterId) {
                this.http
                    .patch(`${this.url_server}/counter/${counterId}`, counterData)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0) {
                            if(updateTable) localStorage.setItem('counterUpdateNeeded', 'true');
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Updates a document in the specified collection.
     * @param {string} collection_name - The name of the collection.
     * @param {string} documentId - The ID of the document to update.
     * @param {any} documentData - The updated data for the document.
     * @returns {Promise<boolean>} - A promise that resolves to true if the document was updated successfully, or false otherwise.
     */
    async updateDocument(
        collection_name: string,
        documentId: string,
        documentData: any
    ): Promise<boolean> {
        const timestamp = new Date();
        documentData.date_time_modified = timestamp;
        localStorage.setItem(`${collection_name}s`, '');

        return new Promise(async (resolve, reject) => {
            if (documentId) {
                const subcription = this.http
                    .patch(`${this.url_server}/${collection_name}/${documentId}`, documentData)
                    .subscribe(
                        (data: any) => {
                            subcription.unsubscribe();
                            if (data && data.affected > 0) resolve(true);
                            else resolve(false);
                        },
                        (error) => {
                            reject();
                        }
                    );
            } else reject();
        });
    }

    /**
     * Retrieves a task by its ID.
     * @param taskId - The ID of the task to retrieve.
     * @returns A Promise that resolves to the task data.
     */
    getTask(taskId: string | number) {
        return this.http.get(`${this.url_server}/water-task/${taskId}`);
    }

    /**
     * Fetches a task from the development server by its ID.
     *
     * @param {string | number} taskId - The ID of the task to retrieve.
     * @returns {Observable<any>} An observable containing the task data.
     */
    getTaskDevServer(taskId: string | number) {
        return this.http.get(`${environment.dev_server}/water-task/${taskId}`);
    }

    /**
     * Retrieves the ITAC (Information Technology Asset Change) with the specified ID.
     * @param itacId - The ID of the ITAC to retrieve.
     * @returns A Promise that resolves to the ITAC object.
     */
    getItac(itacId: string | number) {
        return this.http.get(`${this.url_server}/itac/${itacId}`);
    }

    /**
     * Retrieves a task synchronously from the server.
     * @param taskId - The ID of the task to retrieve.
     * @returns A Promise that resolves to the retrieved WaterTask.
     */
    async getTaskSync(taskId: string | number): Promise<WaterTask> {
        return new Promise<WaterTask>((resolve, reject) => {
            const subcription = this.http.get(`${this.url_server}/water-task/${taskId}`).subscribe(
                (doc: any) => {
                    subcription.unsubscribe();
                    resolve(convertFromServer(doc) as WaterTask);
                },
                (error) => {
                    reject();
                }
            );
        });
    }

    /**
     * Fetches a water task from the development server by its ID.
     * 
     * @param taskId - The ID of the water task to fetch. Can be a string or a number.
     * @returns A promise that resolves to a `WaterTask` object.
     * 
     * @throws Will reject the promise if there is an error during the HTTP request.
     */
    async getTaskSyncDevServer(taskId: string | number): Promise<WaterTask> {
        return new Promise<WaterTask>((resolve, reject) => {
            const subcription = this.http.get(`${environment.dev_server}/water-task/${taskId}`).subscribe(
                (doc: any) => {
                    subcription.unsubscribe();
                    resolve(convertFromServer(doc) as WaterTask);
                },
                (error) => {
                    reject();
                }
            );
        });
    }

    /**
     * Retrieves an array of WaterTask objects synchronously based on the provided task IDs.
     * @param ids - An array of task IDs.
     * @returns A Promise that resolves to an array of WaterTask objects.
     */
    async getTasksSync(ids: number[]): Promise<WaterTask[]> {
        return new Promise<WaterTask[]>((resolve, reject) => {
            const params = { ids: ids };
            const subcription = this.http.post(`${this.url_server}/water-task/ids`, params).subscribe(
                (data: any) => {
                    subcription.unsubscribe();
                    if (data) {
                        let waterTasks: WaterTask[] = [];
                        waterTasks = data.map((task: any) => convertFromServer(task));
                        resolve(waterTasks);
                    }
                },
                (error) => { reject(); }
            );
        });
    }

    /**
     * Retrieves the Itacs synchronously based on the provided IDs.
     * @param ids - An array of numbers representing the IDs of the Itacs to retrieve.
     * @returns A Promise that resolves to an array of Itacs.
     */
    async getItacsSync(ids: number[]): Promise<Itac[]> {
        return new Promise<Itac[]>((resolve, reject) => {
            const params = { ids: ids };
            const subcription = this.http.post(`${this.url_server}/itac/ids`, params).subscribe(
                (data: any) => {
                    subcription.unsubscribe();
                    if (data) {
                        let itacs: Itac[] = [];
                        itacs = data;
                        resolve(itacs);
                    }
                },
                (error) => { reject(); }
            );
        });
    }

    /**
     * Retrieves a WaterTask object synchronously based on the provided NUMIN.
     * @param numin - The NUMIN value used to search for the WaterTask.
     * @returns A Promise that resolves to a WaterTask object.
     */
    async getTaskSyncWithNUMIN(numin: string): Promise<WaterTask> {
        return new Promise<WaterTask>((resolve, reject) => {
            const subcription = this.http.get(`${this.url_server}/water-task/numin/${numin}`).subscribe(
                (doc: any) => {
                    subcription.unsubscribe();
                    resolve(convertFromServer(doc) as WaterTask);
                },
                (error) => {
                    reject();
                }
            );
        });
    }

    /**
     * Retrieves the Itac data synchronously from the server.
     * @param itacId - The ID of the Itac to retrieve.
     * @returns A Promise that resolves with the retrieved Itac data.
     */
    async getItacSync(itacId: string | number): Promise<Itac> {
        return new Promise<Itac>((resolve, reject) => {
            const subcription = this.http.get(`${this.url_server}/itac/${itacId}`).subscribe(
                (doc: any) => {
                    subcription.unsubscribe();
                    resolve(doc);
                },
                (error) => {
                    reject();
                }
            );
        });
    }

    /**
     * Retrieves an Itac object based on the provided emplacement code.
     * @param emplacement_code - The emplacement code used to search for the Itac.
     * @returns A Promise that resolves to the retrieved Itac object.
     */
    async getItacWithCode(emplacement_code: string): Promise<Itac> {
        return new Promise<Itac>((resolve, reject) => {
            const subcription = this.http
                .get(`${this.url_server}/itac/codigo_itac/${emplacement_code}`) //TODO: change this request adding company and manager
                .subscribe(
                    (doc: any) => {
                        subcription.unsubscribe();
                        resolve(doc);
                    },
                    (error) => {
                        reject();
                    }
                );
        });
    }

    /**
     * Retrieves the counter with the specified ID from the server.
     * @param counterId - The ID of the counter to retrieve.
     * @returns A Promise that resolves to the counter data.
     */
    getCounter(counterId: string) {
        return this.http.get(`${this.url_server}/counter/${counterId}`);
    }

    /**
     * Retrieves a document from a collection.
     * 
     * @template T - The type of the document to retrieve.
     * @param {string} collection_name - The name of the collection.
     * @param {string} documentId - The ID of the document to retrieve.
     * @returns {Observable<T>} - An observable that emits the retrieved document.
     */
    getDocument<T>(collection_name: string, documentId: string) {
        return this.http.get(`${this.url_server}/${collection_name}/${documentId}`);
    }

    /**
     * Retrieves a document synchronously from a specified collection.
     * @param collection_name - The name of the collection.
     * @param documentId - The ID of the document to retrieve.
     * @returns A promise that resolves with the retrieved document.
     */
    async getDocumentSync<T>(collection_name: string, documentId: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            const subcription = this.http
                .get(`${this.url_server}/${collection_name}/${documentId}`)
                .subscribe(
                    (doc: any) => {
                        subcription.unsubscribe();
                        resolve(doc);
                    },
                    (error) => {
                        reject();
                    }
                );
        });
    }

    /**
     * Retrieves a manager by their ID.
     * @param managerId The ID of the manager to retrieve.
     * @returns A promise that resolves to the retrieved manager.
     */
    async getManager(managerId: string) {
        return await this.getDocumentSync<Manager>(`manager`, managerId);
    }

    /**
     * Retrieves a user by their ID.
     * @param userId The ID of the user to retrieve.
     * @returns A promise that resolves to the user object.
     */
    async getUser(userId: string) {
        return await this.getDocumentSync<MiRutaUser>('user', userId);
    }

    /**
     * Retrieves user data asynchronously.
     * @param userId - The ID of the user to retrieve.
     * @returns A Promise that resolves to the user data.
     */
    getUserAsync(userId: string) {
        return this.http.get(`${this.url_server}/user/${userId}`);
    }

    /**
     * Retrieves information based on the company and manager stored in the local storage.
     * @returns A promise that resolves to an instance of `Info` or `undefined` if no information is found.
     */
    async getInfo(): Promise<Info | undefined> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const infos = await this.getDocuments<Info>('info', [
            ['company', '==', company],
            ['manager', '==', manager],
        ]);
        if (infos.length > 0) {
            return infos[0];
        }
        return undefined;
    }

    /**
     * Converts a two-dimensional array of conditions into an array of JSON objects.
     * Each JSON object represents a condition and contains the field, value, operation, field type, and date type.
     * @param where - The two-dimensional array of conditions.
     * @returns An array of JSON objects representing the conditions.
     */
    getWhereJson(where?: any[][]): any[] {
        let whereJsonArray: any = [];
        if (where && where.length > 0) {
            for (let line of where) {
                let whereJson: any = {};
                if (line[1].includes('>')) {
                    let startDate = moment(line[2]);
                    const dateString = startDate.format('YYYY-MM-DD HH:mm:ss.zzz');
                    whereJson['field'] = line[0];
                    whereJson['value'] = dateString;
                    whereJson['operation'] = line[1];
                    whereJson['field_type'] = 'DATE';
                    whereJson['date_type'] = 'start_at';
                } else if (line[1].includes('<')) {
                    let endDate = moment(line[2]);
                    const dateString = endDate.format('YYYY-MM-DD HH:mm:ss.zzz');
                    whereJson['field'] = line[0];
                    whereJson['value'] = dateString;
                    whereJson['operation'] = line[1];
                    whereJson['field_type'] = 'DATE';
                    whereJson['date_type'] = 'end_at';
                    whereJson['type'] = 'AND';
                } else {
                    whereJson['field'] = line[0];
                    whereJson['value'] = line[2];
                    whereJson['type'] = 'AND';
                }
                whereJsonArray.push(whereJson);
            }
        }
        return whereJsonArray;
    }

    /**
     * Retrieves documents from the specified table based on the provided parameters.
     * 
     * @param table_name - The name of the table to retrieve documents from.
     * @param where - Optional. An array of conditions to filter the documents.
     * @param orderBy - Optional. An array specifying the field and type to order the documents.
     * @param limit - Optional. The maximum number of documents to retrieve.
     * @returns A promise that resolves to an array of documents.
     */
    async getDocuments<T>(
        table_name: string,
        where?: any[][],
        orderBy?: string[],
        limit?: number
    ): Promise<any[]> {
        return new Promise((resolve, reject) => {
            if (where) {
                let params: any = {};
                let whereJsonArray: any = this.getWhereJson(where);
                if (orderBy) {
                    let orderJson: any = {};
                    orderJson['field'] = orderBy[0];
                    orderJson['type'] = orderBy[1].toUpperCase();
                    params['order'] = JSON.stringify(orderJson);
                }
                params['where'] = JSON.stringify(whereJsonArray);
                if (limit) params['limit'] = limit.toString();

                this.http
                    .post(`${this.url_server}/${table_name}/where`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.data) {
                            resolve(data.data);
                        } else {
                            reject();
                        }
                    });
            } else {
                this.http.get(`${this.url_server}/${table_name}`).subscribe(async (data: any) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject();
                    }
                });
            }
        });
    }

    /**
     * Retrieves the PDF for a specific task.
     * @param id - The ID of the task.
     * @param barcodeSerialData - The serial barcode data.
     * @param barcodeSerialDVData - The serial DV barcode data.
     * @returns A promise that resolves with the PDF blob.
     */
    async getTaskPdf(
        id: number,
        barcodeSerialData: string,
        barcodeSerialDVData: string,
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            let params = {
                serial_barcode: barcodeSerialData,
                serial_dv_barcode: barcodeSerialDVData,
            };
            this.http
                .post(`${this.url_server}/water-task/${+id}/pdf`, params, { responseType: 'blob' })
                .toPromise()
                .then((blob: any) => {
                    resolve(blob);
                })
                .catch((err) => {
                    console.error('download error = ', err);
                    reject(err);
                });
        });
    }

    /**
     * Searches for water tasks based on the provided search term.
     * @param term - The search term to filter the water tasks.
     * @returns A promise that resolves to an array of WaterTask objects matching the search term.
     */
    async searchTasks(term: string): Promise<WaterTask[]> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        return new Promise(async (resolve, reject) => {
                const params: any = {};
                params.company = company;
                params.manager = manager;
                params.term = term;
                this.http
                    .post(`${this.url_server}/water-task/search`, params)
                    .subscribe(async (data: any) => {
                        if (data) {
                            let waterTasks: WaterTask[] = [];
                            waterTasks = data.map((task: any) => convertFromServer(task));
                            resolve(waterTasks);
                        }
                        else reject([]);
                    });
        });
    }

    /**
     * Retrieves the operator's water tasks for a specific day.
     * @param userId - The ID of the operator.
     * @param day - The specific day for which to retrieve the tasks.
     * @returns A promise that resolves to an array of WaterTask objects.
     * @throws If `day` or `userId` is null.
     */
    async getOperatorWeek(userId: number, day: string): Promise<WaterTask[]> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        return new Promise(async (resolve, reject) => {
            if (day && userId) {
                const params: any = {};
                params.company = company;
                params.manager = manager;
                params.user_id = userId;
                params.day = day;
                this.http
                    .post(`${this.url_server}/water-task/get_week`, params)
                    .subscribe(async (data: any) => {
                        if (data) {
                            let waterTasks: WaterTask[] = [];
                            waterTasks = data.map((task: any) => convertFromServer(task));
                            resolve(waterTasks);
                        }
                        else reject([]);
                    });
            } else {
                reject(new Error("Day or userId is null in getOperatorWeek of ApiService"));
            }
        });
    }

    /**
     * Retrieves the availability for a specific day.
     * @param day - The day for which to retrieve the availability.
     * @param userId - (Optional) The ID of the user for whom to retrieve the availability.
     * @returns A Promise that resolves to an AvailabilityDayMap object representing the availability for the specified day.
     * @throws If the day parameter is null.
     */
    async getDayAvailability(day: string, userId?: number): Promise<AvailabilityDayMap> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        return new Promise(async (resolve, reject) => {
            if (day) {
                const params: any = {};
                params.day = day;
                params.company = company;
                params.manager = manager;
                params.user_id = userId;
                this.http
                    .post(`${this.url_server}/water-task/day_availability`, params)
                    .subscribe(async (data: any) => {
                        if (data)  resolve(data);
                        else reject([]);
                    });
            } else {
                reject(new Error("Day is null in getDayAvailability of ApiService"));
            }
        });
    }

    /**
     * Retrieves the availability of days for a given user.
     * @param days - An array of days for which availability needs to be retrieved.
     * @param userId - Optional. The ID of the user for whom availability needs to be retrieved.
     * @returns A Promise that resolves to an object representing the availability of days.
     * @throws If the 'days' parameter is null.
     */
    async getDaysAvailability(days: string[], userId?: number): Promise<AvailabilityDaysMap> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        return new Promise(async (resolve, reject) => {
            if (days && days.length > 1) {
                const params: any = {};
                params.days = days;
                params.company = company;
                params.manager = manager;
                params.user_id = userId;
                this.http
                    .post(`${this.url_server}/water-task/days_availability`, params)
                    .subscribe(async (data: any) => {
                        if (data)  resolve(data);
                        else reject([]);
                    });
            } else {
                reject(new Error("Days is null in getDaysAvailability of ApiService"));
            }
        });
    }


    /**
     * Makes a statistics request to the specified endpoint with an optional where clause.
     * @param end_point - The endpoint to send the request to.
     * @param where_clause - The optional where clause for filtering the data.
     * @returns A promise that resolves to an array of type T.
     */
    async statisticsRequest<T>(end_point: string, where_clause: string): Promise<T[]> {
        return new Promise(async (resolve, reject) => {
            if (where_clause) {
                const params: any = {};
                if (where_clause) params.where = where_clause;
                this.http
                    .post(`${this.url_server}/${end_point}/where`, params)
                    .subscribe(async (data: any) => {
                        if (data && data.data)  resolve(data.data);
                        else reject([]);
                    });
            } else resolve([]);
        });
    }

    async avgExecutedInADayPerMonth(where_clause: string): Promise<AvgExecutedInADayPerMonth[]> {
        const end_point = 'avg-executed-in-a-day-per-month';
        return this.statisticsRequest<AvgExecutedInADayPerMonth>(end_point, where_clause);
    }

    async getAvgInADayPerUserPerYear(where_clause: string): Promise<AvgExecutedInADayPerUserPerYear[]> {
        const end_point = 'avg-executed-in-a-day-per-user-per-year';
        return this.statisticsRequest<AvgExecutedInADayPerUserPerYear>(end_point, where_clause);
    }

    async getAvgInADayPerYear(where_clause: string): Promise<AvgExecutedInADayPerYear[]> {
        const end_point = 'avg-executed-in-a-day-per-year';
        return this.statisticsRequest<AvgExecutedInADayPerYear>(end_point, where_clause);
    }

    async getAvgInADayPerUserPerMonth(where_clause: string): Promise<AvgExecutedInADayPerUserPerMonth[]> {
        const end_point = 'avg-executed-in-a-day-per-user-per-month';
        return this.statisticsRequest<AvgExecutedInADayPerUserPerMonth>(end_point, where_clause);
    }

    async getTotalExecutedInAYearPerUser(where_clause: string): Promise<TotalExecutedInAYearPerUser[]> {
        const end_point = 'total-executed-in-a-year-per-user';
        return this.statisticsRequest<TotalExecutedInAYearPerUser>(end_point, where_clause);
    }

    async getTotalExecutedPerYear(where_clause: string): Promise<TotalExecutedPerYear[]> {
        const end_point = 'total-executed-per-year';
        return this.statisticsRequest<TotalExecutedPerYear>(end_point, where_clause);
    }

    async getTotalExecutedPerMonth(where_clause: string): Promise<TotalExecutedPerMonth[]> {
        const end_point = 'total-executed-per-month';
        return this.statisticsRequest<TotalExecutedPerMonth>(end_point, where_clause);
    }

    async getTotalExecutedInAMonthPerUser(where_clause: string): Promise<TotalExecutedInAMonthPerUser[]> {
        const end_point = 'total-executed-in-a-month-per-user';
        return this.statisticsRequest<TotalExecutedInAMonthPerUser>(end_point, where_clause);
    }

    async getTotalExecutedInADayPerUser(where_clause: string): Promise<TotalExecutedInADayPerUser[]> {
        const end_point = 'total-executed-in-a-day-per-user';
        return this.statisticsRequest<TotalExecutedInADayPerUser>(end_point, where_clause);
    }

    async getItacPdf(id: number, filename: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.url_server}/itac/${+id}/pdf`, {}, { responseType: 'blob' })
                .toPromise()
                .then((blob: any) => {
                    resolve(blob);
                })
                .catch((err) => {
                    console.error('download error = ', err);
                    reject(err);
                });
        });
    }

    async getTeams(): Promise<Team[]> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');

        const data = localStorage.getItem('teams');
        let teams;
        if (data) {
            teams = JSON.parse(data);
            this._utilsService.teams = teams;
        }
        if (teams) return teams;
        return new Promise((resolve, reject) => {
            if (!company || !manager) resolve([]);
            
            let whereJsonArray: any = [];
            const companyJson = { field: 'company', type: 'AND', value: company };
            whereJsonArray.push(companyJson);
            const managerJson = { field: 'manager', type: 'AND', value: manager };
            whereJsonArray.push(managerJson);
            const params = {
                where: JSON.stringify(whereJsonArray),
            };
            this.http.post(`${this.url_server}/team/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    this._utilsService.teams = data.data;
                    localStorage.setItem('teams', JSON.stringify(data.data));
                    resolve(data.data);
                } else {
                    resolve([]);
                }
            });
        });
    }

    async getNewNotificationCount(): Promise<number> {
        return new Promise((resolve, reject) => {
            const company = localStorage.getItem('company');
            const manager = localStorage.getItem('manager');
            const user = this._utilsService.getLoggedInUser();
            if (!company || !manager || !user) resolve(0);
            
            let whereJsonArray: any = [];
            whereJsonArray.push({ field: 'is_seen', type: 'AND', value: 0 });
            whereJsonArray.push({ field: 'user_id', type: 'AND', value: user!.id });
            const params = { where: JSON.stringify(whereJsonArray) };

            this.http.post(`${this.url_server}/notification/count`, params).subscribe(async (data: any) => {
                if (data && data.count) resolve(data.count);
                else resolve(0);
            });
        });
    }

    getNotification(notificationId: number | string) {
        return this.http.get<Notification>(`${this.url_server}/notification/${notificationId}`);
    }

    getNotificationSync(notificationId: number | string): Promise<Notification> {
        return firstValueFrom(this.getNotification(notificationId));
    }

    async updateNotification(notificationId: string | number): Promise<boolean> {
        let notificationData = { seen_at: new Date(), is_seen: true };
        return new Promise(async (resolve, reject) => {
            if (notificationId) {
                this.http
                    .patch(`${this.url_server}/notification/${notificationId}`, notificationData)
                    .subscribe(async (data: any) => {
                        if (data && data.affected > 0)  resolve(true);
                        else reject(false);
                    });
            } else resolve(false);
        });
    }

    async getNotificationCount(): Promise<number> {
        return new Promise((resolve, reject) => {
            const company = localStorage.getItem('company');
            const manager = localStorage.getItem('manager');
            const user = this._utilsService.getLoggedInUser();
            if (!company || !manager || !user) resolve(0);
            
            let whereJsonArray: any = [];
            whereJsonArray.push({ field: 'user_id', type: 'AND', value: user!.id });
            const params = { where: JSON.stringify(whereJsonArray) };

            this.http.post(`${this.url_server}/notification/count`, params).subscribe(async (data: any) => {
                if (data && data.count) resolve(data.count);
                else resolve(0);
            });
        });
    }

    async getNotificationsAndCount(page: number): Promise<NotificationAndCount> {
        return new Promise((resolve, reject) => {
            const user = this._utilsService.getLoggedInUser();
            if (!user) reject('No user logged in');
            const params = { 
                where: JSON.stringify([{ field: 'user_id', type: 'AND', value: user!.id }]),
                limit: 20,
                offset: page * 20,
                order: JSON.stringify({ field: 'created_at', type: 'DESC' })
            };
            this.http.post(`${this.url_server}/notification/where`, params).subscribe(async (data: any) => {
                if (data && data.data) resolve({ notifications: data.data, total: data.count });
                else reject('No notification data found');
            });
        });
    }

    async getNotifications(page: number): Promise<Notification[]> {
        return (await this.getNotificationsAndCount(page)).notifications;
    }

    async selectUsers(): Promise<MiRutaUser[]> {
        const company = localStorage.getItem('company');
        let users = await this.getUsers(['operario']);
        users = users.filter((user) =>
            this._utilsService.checkCompany(user.companies, company || '')
        );
        const selectedNames = await this._utilsService.openMultipleOptionsDialog(
            'Seleccione operarios',
            users.map((user: MiRutaUser) => this._utilsService.userPipe(user.id)),
            []
        );
        if (selectedNames && selectedNames.length > 0) {
            const mUsers = users.filter((u: MiRutaUser) =>
                selectedNames.includes(this._utilsService.userPipe(u.id))
            );
            if (mUsers) return mUsers;
        }
        return [];
    }

    //TODO: when selecting users type is replacing all users, fix this
    async getUsers(userTypes?: string[], whereJsonArrayParams?: any, companyUser?: boolean): Promise<MiRutaUser[]> {
        const company = localStorage.getItem('company');
        const data = localStorage.getItem('users');
        let users;
        if (data) {
            users = JSON.parse(data);
            this._utilsService.users = users;
        }
        if (users) return users;

        return new Promise((resolve, reject) => {
            let whereJsonArray: any = [];
            if (userTypes && userTypes.length > 0) {
                let whereJson: any = {};
                for (let type of userTypes) {
                    whereJson['field'] = type;
                    whereJson['value'] = 1;
                }
                whereJsonArray.push(whereJson);
            }
            if (whereJsonArrayParams) for (const json in whereJsonArrayParams) whereJsonArray.push(json);
            
            if(companyUser) whereJsonArray.push({ field: 'companies', value: company }); //TODO: test this
            const params = { where: JSON.stringify(whereJsonArray) };
            this.http.post(`${this.url_server}/user/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    this._utilsService.users = data.data;
                    localStorage.setItem('users', JSON.stringify(data.data));
                    resolve(data.data);
                } else {
                    reject();
                }
            });
        });
    }

    async getParts(): Promise<Part[]> {
        const data = localStorage.getItem('parts');
        let parts;
        if (data) {
            parts = JSON.parse(data);
            // console.log('============= parts =============');
            // console.log(parts);
        }
        if (parts) return parts;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/part`).subscribe(async (data: any) => {
                if (data) {
                    let parts: Part[] = data;
                    localStorage.setItem('parts', JSON.stringify(data));
                    resolve(parts);
                } else {
                    reject();
                }
            });
        });
    }

    async getObservations(): Promise<Observation[]> {
        const data = localStorage.getItem('observations');
        let observations;
        if (data) {
            observations = JSON.parse(data);
        }
        if (observations) return observations;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/observation`).subscribe(async (data: any) => {
                if (data) {
                    localStorage.setItem('observations', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getSectors(): Promise<Sector[]> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');

        const data = localStorage.getItem('sectors');
        let sectors;
        if (data) {
            sectors = JSON.parse(data);
            this._utilsService.sectors = sectors;
        }
        if (sectors) return sectors;
        return new Promise((resolve, reject) => {
            if (!company || !manager)  resolve([]);
            
            let whereJsonArray: any = [];
            whereJsonArray.push({ field: 'company', type: 'AND', value: company });
            whereJsonArray.push({ field: 'manager', type: 'AND', value: manager });
            const params = { where: JSON.stringify(whereJsonArray) };
            this.http.post(`${this.url_server}/sector/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    this._utilsService.sectors = data.data;
                    localStorage.setItem('sectors', JSON.stringify(data.data));
                    resolve(data.data);
                } else resolve([]);
            });
        });
    }
    
    async getClassCounter(): Promise<ClassCounter[]> {
        const data = localStorage.getItem('class-counters');
        let classCounters;
        if (data) {
            classCounters = JSON.parse(data);
            // console.log('============= classCounters =============');
            // console.log(classCounters);
        }
        if (classCounters) return classCounters;

        return new Promise((resolve, reject) => {
            let classCounters: ClassCounter[] = [];
            this.http.get(`${this.url_server}/class-counter`).subscribe(async (data: any) => {
                if (data) {
                    localStorage.setItem('class-counters', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getTypesRadius(): Promise<TypeRadius[]> {
        const data = localStorage.getItem('types_radius');
        let types_radius;
        if (data) {
            types_radius = JSON.parse(data);
            this._utilsService.typesRadius = types_radius;
        }
        if (types_radius) return types_radius;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/type_radius`).subscribe(async (data: any) => {
                if (data) {
                    let types_radius: TypeRadius[] = data;
                    this._utilsService.typesRadius = types_radius;
                    localStorage.setItem('types_radius', JSON.stringify(data));
                    resolve(types_radius);
                } else {
                    reject();
                }
            });
        });
    }

    async getDateStatuses(): Promise<DateStatus[]> {
        const data = localStorage.getItem('date_statuses');
        let date_statuses;
        if (data) {
            date_statuses = JSON.parse(data);
            this._utilsService.dateStatus = date_statuses;
        }
        if (date_statuses) return date_statuses;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/date-status`).subscribe(async (data: any) => {
                if (data) {
                    let date_statuses: DateStatus[] = data;
                    this._utilsService.dateStatus = date_statuses;
                    localStorage.setItem('date_statuses', JSON.stringify(data));
                    resolve(date_statuses);
                } else {
                    reject();
                }
            });
        });
    }

    async getTypesCounters(): Promise<TypeCounter[]> {
        const data = localStorage.getItem('type_counters');
        let type_counters;
        if (data) {
            type_counters = JSON.parse(data);
            this._utilsService.typesCounters = type_counters;
        }
        if (type_counters) return type_counters;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/type_counter`).subscribe(async (data: any) => {
                if (data) {
                    let type_counters: TypeCounter[] = data;
                    this._utilsService.typesCounters = type_counters;
                    localStorage.setItem('type_counters', JSON.stringify(data));
                    resolve(type_counters);
                } else {
                    reject();
                }
            });
        });
    }

    async getEmplacements(): Promise<Emplacement[]> {
        const data = localStorage.getItem('emplacements');
        let emplacements;
        if (data) {
            emplacements = JSON.parse(data);
            this._utilsService.emplacements = emplacements;
        }
        if (emplacements) return emplacements;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/emplacement`).subscribe(async (data: any) => {
                if (data) {
                    let emplacements: Emplacement[] = data;
                    this._utilsService.emplacements = emplacements;
                    localStorage.setItem('emplacements', JSON.stringify(data));
                    resolve(emplacements);
                } else {
                    reject();
                }
            });
        });
    }

    async getMarks(): Promise<Mark[]> {
        const data = localStorage.getItem('marks');
        let marks;
        if (data) {
            marks = JSON.parse(data);
            this._utilsService.marks = marks;
        }
        if (marks) return marks;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/mark`).subscribe(async (data: any) => {
                if (data) {
                    let marks: Mark[] = data;
                    this._utilsService.marks = marks;
                    localStorage.setItem('marks', JSON.stringify(data));
                    resolve(marks);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Retrieves the planning information based on the provided planning code.
     * 
     * @param planningCode - The code of the planning to retrieve.
     * @returns A promise that resolves to a string representing the planning code and name, or an empty string if no matching planning is found.
     */
    async getPlanning(planningCode: string): Promise<string> {
        let plannings: Planning[] = await this.getPlannings();
        for(let planning of plannings) {
            if (planning.codigo_planning.trim() == planningCode.trim()) {
                return `${planning.codigo_planning} - ${planning.planning}`;
            }
        }
        return '';
    }

    /**
     * Retrieves the plannings from local storage or makes an HTTP request to fetch them from the server.
     * If the plannings are already available in local storage, they are returned immediately.
     * Otherwise, an HTTP GET request is made to the server to fetch the plannings, and the retrieved data is stored in local storage for future use.
     * 
     * @returns A promise that resolves to an array of Planning objects representing the plannings.
     * @throws If the HTTP request fails or no plannings are available.
     */
    async getPlannings(): Promise<Planning[]> {
        const data = localStorage.getItem('plannings');
        let plannings;
        if (data) {
            plannings = JSON.parse(data);
            this._utilsService.plannings = plannings;
        }
        if (plannings) return plannings;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/planning`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.plannings = data;
                    localStorage.setItem('plannings', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getResults(): Promise<Result[]> {
        const data = localStorage.getItem('results');
        let results;
        if (data) {
            results = JSON.parse(data);
            this._utilsService.results = results;
        }
        if (results) return results;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/result`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.results = data;
                    localStorage.setItem('results', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getZones(): Promise<Zone[]> {
        const where = this.getCompanyWhereClause();
        const params = { where: where };

        const data = localStorage.getItem('zones-' + where);
        let zones;
        if (data) {
            zones = JSON.parse(data);
            this._utilsService.zones = zones;
        }
        if (zones) return zones;

        return new Promise((resolve, reject) => {
            this.http.post(`${this.url_server}/zone/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    this._utilsService.zones = data.data;
                    localStorage.setItem('zones-' + where, JSON.stringify(data.data));
                    resolve(data.data);
                } else {
                    reject();
                }
            });
        });
    }

    async getCauses(): Promise<Cause[]> {
        const data = localStorage.getItem('causes');
        let causes;
        if (data) {
            causes = JSON.parse(data);
            this._utilsService.causes = causes;
        }
        if (causes) return causes;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/cause`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.causes = data;
                    localStorage.setItem('causes', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getPlanningDetails(): Promise<PlanningDetail[]> {
        const data = localStorage.getItem('planning-detailss');
        let planningDetails;
        if (data) {
            planningDetails = JSON.parse(data);
            this._utilsService.planningDetails = planningDetails;
        }
        if (planningDetails) return planningDetails;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/planning-details`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.planningDetails = data;
                    localStorage.setItem('planning-detailss', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getPlanningDetailExtras(): Promise<PlanningDetailExtra[]> {
        const data = localStorage.getItem('planning-detail-extras');
        let planningDetailExtras;
        if (data) {
            planningDetailExtras = JSON.parse(data);
            this._utilsService.planningDetailExtras = planningDetailExtras;
        }
        if (planningDetailExtras) return planningDetailExtras;

        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/planning-detail-extra`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.planningDetailExtras = data;
                    localStorage.setItem('planning-detail-extras', JSON.stringify(data));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    async getCompanies(): Promise<Company[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/company`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.companies = data;
                    localStorage.setItem('companys', JSON.stringify(this._utilsService.companies));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Retrieves the list of managers from the server.
     * @returns A promise that resolves with an array of Manager objects.
     */
    async getManagers(): Promise<Manager[]> {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.url_server}/manager`).subscribe(async (data: any) => {
                if (data) {
                    this._utilsService.managers = data;
                    localStorage.setItem('managers', JSON.stringify(this._utilsService.managers));
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Selects an agrupdation and returns its ID.
     * @returns The ID of the selected agrupdation, or null if no agrupdation is selected.
     */
    async selectAgrupdationId(): Promise<number | null> {
        try {
            const agrupations: Agrupation[] = await this.getAgrupations();
            const agrupationsNames = agrupations.map((agrupation: Agrupation) => agrupation.agrupation!);
            const agrupation = await this._utilsService.openSelectorDialog(
                'Selecione agrupación',
                agrupationsNames
            );
            if (agrupation) {
                const agrupationId = agrupations.find((a) => a.agrupation == agrupation)?.agrupationId;
                if(agrupationId) return agrupationId;
            }
        } catch (err) {}
        return null;
    }

    /**
     * Retrieves the list of agrupations based on the company's where clause.
     * @returns A promise that resolves to an array of Agrupation objects.
     */
    async getAgrupations(): Promise<Agrupation[]> {
        const where_clause = this.getCompanyWhereClause();
        const params = {
            where: where_clause,
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.url_server}/agrupation/where`, params)
                .subscribe(async (data: any) => {
                    try {
                        if (data && data.data) {
                            this._utilsService.agrupations = data.data;
                            localStorage.setItem(
                                'agrupations',
                                JSON.stringify(this._utilsService.agrupations)
                            );
                            resolve(data.data);
                        } else {
                            resolve([]);
                        }
                    } catch (err) {
                        reject();
                    }
                });
        });
    }

    async getTasksAfter(order?: string): Promise<WaterTask[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }

    getParamsFromArray(where?: any[][]) {
        let whereJsonArray: any = [];
        if (where && where.length > 0) {
            for (let line of where) {
                let whereJson: any = {};
                whereJson['field'] = line[0];
                whereJson['value'] = line[2];
                whereJson['type'] = 'AND';
                whereJsonArray.push(whereJson);
            }
        }
        const params = {
            where: JSON.stringify(whereJsonArray),
        };
        return params;
    }

    async getTasks(where?: any[][], limit: number = 1000, stringWhere?: string): Promise<WaterTask[]> {
        return new Promise((resolve, reject) => {
            let params: any;
            if (where) params = this.getParamsFromArray(where);
            if (stringWhere) params = { where: stringWhere };
            if (limit) params = { ...params, limit: limit.toString() };
            this.http
                .post(`${this.url_server}/water-task/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async getTaskUpdates(task_id?: number, limit?: number): Promise<WaterTaskUpdate[]> {
        return new Promise((resolve, reject) => {
            let params: any;
            let where: any = [['task_id', '==', task_id]];
            if (where) params = this.getParamsFromArray(where);
            if (limit) params = { ...params, limit: limit.toString() };
            let orderJson: any = { field: 'id', type: 'DESC' };
            params = { ...params, order: JSON.stringify(orderJson) };
            this.http
                .post(`${this.url_server}/water-task-updates/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Retrieves user validations for a specific day.
     *
     * @param user_id - The ID of the user.
     * @param day - The specific day to retrieve validations for.
     * @param limit - Optional. The maximum number of validations to retrieve.
     * @returns A promise that resolves to an array of UserValidation objects.
     */
    async getUserValidations(user_id: number, day: Date, limit?: number): Promise<UserValidation[]> {
        return new Promise((resolve, reject) => {
            let dayString = moment(day).format('%YYYY-MM-DD%');
            let params: any;
            let where: any = [
                ['date_time_modified', '==', dayString],
                ['user_id', '==', user_id], 
            ];
            if (where) params = this.getParamsFromArray(where);
            if (limit) params = { ...params, limit: limit.toString() };
            let orderJson: any = { field: 'id', type: 'ASC' };
            params = { ...params, order: JSON.stringify(orderJson) };

            this.http
                .post(`${this.url_server}/user-validation/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Fetches task updates for a specific user on a given day.
     *
     * @param {string} username - The username of the user whose task updates are to be fetched.
     * @param {Date} day - The specific day for which the task updates are to be fetched.
     * @param {number} [limit] - Optional limit on the number of task updates to fetch.
     * @returns {Promise<WaterTaskUpdate[]>} A promise that resolves to an array of WaterTaskUpdate objects.
     */
    async getTaskUpdatesUser(username: string, day: Date, limit?: number): Promise<WaterTaskUpdate[]> {
        return new Promise((resolve, reject) => {
            let dayString = moment(day).format('%YYYY-MM-DD%');
            let params: any;
            let where: any = [
                ['update_date', '==', dayString],
                ['user', '==', username], 
            ];
            if (where) params = this.getParamsFromArray(where);
            if (limit) params = { ...params, limit: limit.toString() };
            let orderJson: any = { field: 'id', type: 'ASC' };
            params = { ...params, order: JSON.stringify(orderJson) };

            this.http
                .post(`${this.url_server}/water-task-updates/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    /**
     * Fetches Itac records based on the specified conditions.
     *
     * @param where - An optional array of conditions to filter the Itac records.
     * @returns A promise that resolves to an array of Itac records.
     */
    async getItacs(where?: any[][]): Promise<Itac[]> {
        return new Promise((resolve, reject) => {
            let params = this.getParamsFromArray(where);
            this.http.post(`${this.url_server}/itac/where`, params).subscribe(async (data: any) => {
                if (data && data.data) {
                    resolve(data.data);
                } else {
                    reject();
                }
            });
        });
    }

    async getWaterRoutes(where?: any[][]): Promise<WaterRoute[]> {
        return new Promise((resolve, reject) => {
            let params = this.getParamsFromArray(where);
            this.http
                .post(`${this.url_server}/water-route/where`, params)
                .subscribe(async (data: any) => {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        reject();
                    }
                });
        });
    }

    async saveColumnsOnUser(tableName: string, columns: string) {
        let user: MiRutaUser = this._utilsService.getLoggedInUser()!;
        if (user) {
            if(!user.user_columns_order) user.user_columns_order = {};
            const field_name = `columns_${tableName}`;
            let data: any | MiRutaUser = {};
            data[`${field_name}`] = columns;
            data['user_columns_order'] = { ...user.user_columns_order, ...data };
            delete data[`${field_name}`];
            const result: boolean = await this.updateDocument('user', user.id.toString(), data);
            if (result) {
                user.user_columns_order = data['user_columns_order'];
                sessionStorage.setItem('user', JSON.stringify(user));
            }
        }
    }

    /**
     * Retrieves the ID of the logged-in user.
     * @returns The ID of the logged-in user as a string, or an empty string if no user is logged in.
     */
    getLoggedUserId() {
        const user = this._utilsService.getLoggedInUser();
        if (user) return user.id.toString();
        return '';
    }

    getUserImage() {
        const user = this._utilsService.getLoggedInUser();
        if (user) return user.photo;
        return '';
    }

    public uploadAqualiaFile(file: File, dateString: string): Promise<boolean> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const url = `${this.url_server}/integration/files/upload/${company}/${manager}/${dateString}`;
        if (manager) return this.uploadFile(file, url);
        else
            return new Promise<any>((resolve, reject) => {
                reject();
            });
    }

    public alreadyUploadDays(): Promise<any[]> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');

        const url = `${this.url_server}/integration/files/list/${company}/${manager}`;

        return new Promise((resolve, reject) => {
            this.http.get(url).subscribe(async (data: any) => {
                if (data && data.status) {
                    resolve(data.available.map((dayJson: any) => dayJson.name));
                } else {
                    resolve([]);
                }
            });
        });
    }

    public isAvailableZipCreation(dateString: string): Promise<boolean> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const url = `${this.url_server}/integration/files/zip/available/${company}/${manager}/${dateString}`;
        return new Promise((resolve, reject) => {
            this.http.get(url).subscribe(async (data: any) => {
                if (data && data.status) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    public createZipFile(dateString: string): Promise<boolean> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const url = `${this.url_server}/integration/files/zip/${company}/${manager}/${dateString}`;
        return new Promise((resolve, reject) => {
            this.http.post(url, {}).subscribe(async (data: any) => {
                if (data && data.status) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    public uploadFile(file: File, url: string): Promise<boolean> {
        let formData = new FormData();
        formData.append('file', file, file.name);

        return new Promise((resolve, reject) => {
            this.http.post(url, formData).subscribe(async (data: any) => {
                if (data && data.status) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Searches for a place using the provided place ID.
     * @param placeId - The ID of the place to search for.
     * @returns A promise that resolves to the details of the found place.
     */
    async searchPlace(placeId: string): Promise<PlaceDetails> {
        return new Promise((resolve, reject) => {
            let params = { place_id: placeId };
            this.http.post(`${this.url_server}/map/place`, params).subscribe(async (data: any) => {
                if (data && data.status === 'OK') {
                    resolve(data);
                } else {
                    reject();
                }
            });
        });
    }

    /**
     * Retrieves the location with prediction.
     * @param prediction - The prediction object.
     * @returns A promise that resolves to a MyLatLng object representing the location.
     */
    async getLocationWithPrediction(prediction: Prediction): Promise<MyLatLng | null> {
        const place: PlaceDetails = await this.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            return this._utilsService.createLatLng(lat, lng);
        }
        return {} as any;
    }

    /**
     * Searches for predictions based on the given place.
     * @param place - The place to search predictions for.
     * @returns A promise that resolves to an array of predictions.
     */
    async searchPrediction(place: string): Promise<Prediction[]> {
        return new Promise((resolve, reject) => {
            let params = { place: place };
            this.http
                .post(`${this.url_server}/map/predictions`, params)
                .subscribe(async (data: any) => {
                    if (data && data.status === 'OK' && data.predictions) resolve(data.predictions);
                    else resolve([]);
                });
        });
    }

    /**
     * Searches for predictions based on the given place and returns the first result.
     * @param place - The place to search for predictions.
     * @returns A Promise that resolves to the first prediction result, or an empty object if no predictions are found.
     */
    async searchPredictionAndGetFirstResult(place: string): Promise<Prediction> {
        const predictions = await this.searchPrediction(place);
        if (predictions.length) return predictions[0];
        else return {} as any;
    }

    /**
     * Retrieves the WHERE clause for filtering data based on the company.
     * @param where_clause - The existing WHERE clause (optional).
     * @returns The updated WHERE clause with the company filter.
     */
    getCompanyWhereClause(where_clause?: string) {
        let company = localStorage.getItem('company');
        let whereJson = JSON.parse(where_clause || '[]');
        let companyJson: any = {};

        companyJson['field'] = 'company';
        companyJson['value'] = company;
        companyJson['type'] = 'AND';

        whereJson.push(companyJson);

        return JSON.stringify(whereJson);
    }

    /**
     * Retrieves and displays images from a specified folder based on the provided WaterTask.
     * 
     * @param {WaterTask} w - The WaterTask object containing details such as Numero_de_ABONADO and ANOMALIA.
     * @returns {Promise<any>} A promise that resolves with the retrieved data or an empty object if no data is found.
     * 
     * @remarks
     * This method constructs a URL using the company and manager information stored in localStorage,
     * along with the properties of the WaterTask object. It then makes an HTTP GET request to fetch
     * the images and logs the data or error to the console.
     */
    async showFolderImages(w: WaterTask): Promise<any> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const url = `list-all-files/${company}/${manager}/${w.Numero_de_ABONADO}/${w.ANOMALIA}`;
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.url_server}/water-task/${url}`)
                .subscribe(async (data: any) => {
                    try {
                        if (data) resolve(data);
                        else resolve({});
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                        reject();
                    }
                });
        });
    }

    /**
     * Extracts and constructs the image path for a given water task.
     *
     * @param {WaterTask} task - The water task object containing task details.
     * @param {string} image - The image string to be processed.
     * @param {string} prefix - The prefix to be used in the constructed path.
     * @returns {string} - The constructed image path.
     */
    extractImage(task: WaterTask, image: string, prefix: string): string {
        const company = localStorage.getItem('company')!;
        const manager = localStorage.getItem('manager')!;
        const subscriber = task.Numero_de_ABONADO!;
        const split = image.split(subscriber);
        const lastPart = split[split.length - 1];
        const path = `${this.url_server}/water-task/${prefix}/${task.id}/${company}/${manager}/${subscriber}${lastPart}`;
        return path;
    }

    /**
     * Rotates the task image for a given water task.
     * @param waterTask - The water task object.
     * @param photo_field - The field name of the photo in the water task object.
     * @returns A promise that resolves to a boolean indicating the success of the rotation.
     */
    rotateTaskImage(waterTask: WaterTask, photo_field: string): Promise<boolean> {
        const photo_url = waterTask[photo_field as keyof WaterTask];
        const id = waterTask.id;
        const params = {
            photo_url: photo_url,
            photo_field: photo_field,
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.url_server}/water-task/${id}/image/rotate`, params)
                .subscribe(async (data: any) => {
                    try {
                        if (data.status) resolve(true);
                        else resolve(false);
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                        reject();
                    }
                });
        });
    }

    /**
     * Rotates the image of an Itac object.
     * @param itac - The Itac object.
     * @param photo_field - The field name of the photo in the Itac object.
     * @returns A Promise that resolves to a boolean indicating whether the rotation was successful.
     */
    rotateItacImage(itac: Itac, photo_field: string): Promise<boolean> {
        const photo_url = itac[photo_field as keyof Itac];
        const id = itac.id;
        const params = {
            photo_url: photo_url,
            photo_field: photo_field,
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.url_server}/itac/${id}/image/rotate`, params)
                .subscribe(async (data: any) => {
                    try {
                        if (data.status) resolve(true);
                        else resolve(false);
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                        reject();
                    }
                });
        });
    }
}
