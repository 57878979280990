/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    getFormControls,
    priority_status,
    WaterTask,
    defaultAnomaly,
    isFileField,
    getPhotoFields,
    order_status,
} from 'src/app/interfaces/water-task';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { faBarcode, faFilePdf, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Observation } from 'src/app/interfaces/observation';
import { Part } from 'src/app/interfaces/part';
import { Subscription } from 'rxjs';
import { Zone } from 'src/app/interfaces/zone';
import { Cause } from 'src/app/interfaces/cause';
import { Result } from 'src/app/interfaces/result';
import { Emplacement } from 'src/app/interfaces/emplacement';
import * as moment from 'moment';
import { ClassCounter } from 'src/app/interfaces/class-counter';
import { DatePipe, Location } from '@angular/common';
import { Track } from 'ngx-audio-player';
import { FileSaverService } from 'src/app/services/file-saver.service';
import { task_status, convertFromServer, getFieldName, isNotFileField } from '../../interfaces/water-task';
import { DialogImageComponent } from '../share/dialog-image/dialog-image.component';
import { MatDialog } from '@angular/material/dialog';
import { IpcService } from '../../services/ipc.service';
import { saveAs } from 'file-saver';
import { FreeMessages } from '../../interfaces/free-messages';
import { MySqlService } from '../../services/mysql.service';
import { Counter, counter_status } from 'src/app/interfaces/counter';
import { TypeRadius } from '../../interfaces/type-radius';
import { Mark } from '../../interfaces/mark';
import { TypeCounter } from 'src/app/interfaces/type-counter';
import { Team } from 'src/app/interfaces/team';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { FechasNoContesta } from 'src/app/interfaces/fechas-no-contesta';
import { Planning } from '../../interfaces/planning';
import { PlanningDetail } from '../../interfaces/planning-detail';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { PlanningDetailExtra } from '../../interfaces/planning-detail-extra';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
    msaapDisplayTitle = true;
    msaapDisplayPlayList = false;
    msaapDisplayVolumeControls = true;
    msaapDisplayRepeatControls = true;
    msaapDisplayArtist = false;
    msaapDisplayDuration = false;
    msaapDisablePositionSlider = true;

    // Material Style Advance Audio Player Playlist
    msaapPlaylist?: Track[];

    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBarcode = faBarcode;
    loading: boolean = false;
    taskId: string = '';
    task?: WaterTask;
    originalTask?: WaterTask;

    observations: string[] = [];
    parts: string[] = [];
    zones: string[] = [];
    causes: string[] = [];
    planningDetails: string[] = [];
    planningDetailExtras: string[] = [];
    results: string[] = [];
    plannings: string[] = [];
    classes: string[] = [];
    emplacements: string[] = [];
    typesRadius: string[] = [];
    typeCounters: string[] = [];
    marks: string[] = [];
    
    planningsControl: FormControl;
    resultsControl: FormControl;
    zonesControl: FormControl;
    typeCountersControl: FormControl;
    marksControl: FormControl;
    emplacementsControl: FormControl;
    
    tel1_no_answer = false;
    tel1_incorrect = false;
    tel1_dont_know = false;
    tel1_correct = false;
    tel2_no_answer = false;
    tel2_dont_know = false;
    tel2_incorrect = false;
    tel2_correct = false;

    counter?: Counter;

    taskFormData: FormGroup = getFormControls();

    taskSubscription$?: Subscription;

    percentage: number = 0;
    uploading = false;
    photoColumn: string = '';

    timerInputChanged: any;
    currentIndex: any = -1;
    showFlag: any = false;
    images: Array<any> = [];

    form: FormGroup;

    requiredStatus = task_status.REQUIRED;

    orderType_dirty = false;
    priority_dirty = false;

    imageList: string[] = []; //for server folder images 

    emplacementSubscription$?: Subscription;

    /**
     * Constructs a new instance of the TaskComponent.
     * 
     * @param _apiService - The ApiService instance used for making API requests.
     * @param _mySqlService - The MySqlService instance used for interacting with MySQL database.
     * @param utilsService - The UtilsService instance used for utility functions.
     * @param spinner - The NgxSpinnerService instance used for displaying spinners.
     * @param datePipe - The DatePipe instance used for formatting dates.
     * @param fileSaverService - The FileSaverService instance used for saving files.
     * @param electronService - The IpcService instance used for interacting with Electron.
     * @param location - The Location instance used for managing the current browser URL.
     * @param router - The Router instance used for navigating between routes.
     * @param route - The ActivatedRoute instance used for accessing route parameters.
     * @param formBuilder - The FormBuilder instance used for creating form controls.
     * @param matDialog - The MatDialog instance used for displaying dialog boxes.
     */
    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        public utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public datePipe: DatePipe,
        public fileSaverService: FileSaverService,
        public electronService: IpcService,
        public location: Location,
        private router: Router,
        private route: ActivatedRoute,
        public formBuilder: FormBuilder,
        private matDialog: MatDialog
    ) {
        this.form = this.formBuilder.group({
            image: [null],
        });
        this.route.params.subscribe((params) => { this.taskId = params['id']; });

        this.planningsControl = new FormControl();
        this.resultsControl = new FormControl();
        this.zonesControl = new FormControl();
        this.typeCountersControl = new FormControl();
        this.marksControl = new FormControl();
        this.emplacementsControl = new FormControl();
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any initialization logic, such as fetching data from APIs or setting up subscriptions.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        await this._apiService.getTeams();
        await this._apiService.getUsers(['administrador', 'operario']);
        await this.addTablesValues();

        if (this.taskId == 'new') {
            this.addNewTaskData();
            this.showLoading(false);
        } else {
            this.taskSubscription$ = this._apiService
                .getTask(this.taskId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this.utilsService.openSnackBar('Error obteniendo datos de tarea', 'error');
                        this.showLoading(false);
                        return;
                    }
                    await this.addData(doc);
                    this.showLoading(false);
                });
        }

    }

    /**
     * Verifies if the current task belongs to the same company and manager as the logged-in user.
     * @returns A boolean value indicating whether the task belongs to the same company and manager.
     */
    verifyCompanyAndManager(): boolean {
        if(this.taskId == 'new') return true;
        const company = parseInt(localStorage.getItem('company') || '0');
        const manager = parseInt(localStorage.getItem('manager') || '0');
        return company == this.task?.company && manager == this.task?.GESTOR;
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It is used to perform any necessary cleanup before the component is removed from the DOM.
     */
    ngOnDestroy(): void {
        if (this.taskSubscription$) this.taskSubscription$!.unsubscribe();
    }

    /**
     * Retrieves the image URL based on the given photo type.
     * If the task has a valid server image URL for the specified photo type, it is returned.
     * Otherwise, a default image URL is returned.
     * 
     * @param photoType - The type of photo to retrieve (e.g., 'photo1', 'photo2', etc.).
     * @returns The image URL for the specified photo type.
     */
    getImage(photoType: any): string {
        if(this.task){
            if(this.utilsService.checkIfServerImage(this.task[photoType as keyof WaterTask])){
                return this.task[photoType as keyof WaterTask]! as string;
            }
        }
        return 'assets/img/noimage.png';
    }

    /**
     * Adds new task data.
     */
    addNewTaskData(): void {
        this.task = {};
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        this.taskFormData.controls['company'].setValue(company);
        this.taskFormData.controls['GESTOR'].setValue(manager);
        if(this.utilsService.isClientUser()) this.taskFormData.controls['status_tarea'].setValue(task_status.REQUIRED);
        else this.taskFormData.controls['status_tarea'].setValue(task_status.IDLE);
        this.task.status_tarea = this.taskFormData.controls['status_tarea'].value;
    }

    /**
     * Adds data to the task.
     * 
     * @param doc - The data to be added.
     * @returns A Promise that resolves when the data has been added.
     */
    async addData(doc: any): Promise<void> {
        this.task = convertFromServer(doc) as WaterTask;
        this.checkPriorityStatus();
        this.addResultData();
        this.addPlanningData();
        this.taskFormData = this.utilsService.getFormFromTask(this.task!);
        this.addAudioData();
        this.checkPhonesStatus(this.task!.telefonos_cliente?.map((st) => st.value) || []);
        this.setPhotos();
        await this.setInputsValueChanged();
        this.addFromControls();
        this.originalTask = { ...this.task };
    }

    /**
     * Checks the priority status of the task and updates the end_hibernation_priority if necessary.
     */
    checkPriorityStatus(): void {
        if (this.task && this.task!.prioridad != priority_status.HIBERNATE) {
            this.task.end_hibernation_priority = this.task!.prioridad || priority_status.LOW;
        }
    }

    /**
     * Adds result data to the task.
     */
    addResultData(): void {
        try {
            if(this.task){
                for (const result of this.results) {
                    if (
                        this.task.resultado &&
                        result.includes(this.task.resultado + ' -')
                    ) {
                        this.task.resultado = result;
                    }
                }
            }
        } catch (err) {}
    }

    /**
     * Adds planning data to the task.
     */
    addPlanningData(): void {
        try {
            if(this.task){
                for (const planning of this.plannings) {
                    if (
                        this.task.planning &&
                        planning.includes(this.task.planning + ' -')
                    ) {
                        this.task.planning = planning;
                    }
                }
            }
        } catch (err) {}
    }

    /**
     * Adds audio data to the component.
     */
    addAudioData(): void {
        if(this.task){
            if (this.task.audio_detalle && this.task.audio_detalle.includes('http')) {
                this.msaapPlaylist = [
                    {
                        title: 'Detalles de tarea',
                        link: this.task.audio_detalle,
                        artist: 'Fontanero',
                        duration: 5,
                    },
                ];
            }
        }
    }

    /**
     * Retrieves values from various API services and assigns them to corresponding properties.
     * 
     * @returns A promise that resolves when all the API calls are completed.
     */
    async addTablesValues(): Promise<void> {
        this.classes = (await this._apiService.getClassCounter())
        .map((classCounter: ClassCounter) => `${classCounter.codigo_clase} - ${classCounter.clase}`)
        .sort();
        this.observations = (await this._apiService.getObservations())
            .map((observation: Observation) => `${observation.codigo_observacion} - ${observation.observacion}`)
            .sort();
        this.parts = (await this._apiService.getParts())
            .map((part: Part) => `${part.pieza}`)
            .sort();
        this.zones = (await this._apiService.getZones())
            .map((zone: Zone) => `${zone.codigo_zona} - ${zone.zona}`)
            .sort();
        this.causes = (await this._apiService.getCauses())
            .map((cause: Cause) => `${cause.codigo_causa} - ${cause.causa}`) 
            .sort();
        this.plannings = (await this._apiService.getPlannings())
            .map((planning: Planning) => `${planning.codigo_planning} - ${planning.planning}`);
        this.planningDetails = (await this._apiService.getPlanningDetails())
            .map((planningDetails: PlanningDetail) => `${planningDetails.planning_details}`)
            .sort();
        this.planningDetailExtras = (await this._apiService.getPlanningDetailExtras())
            .map((planningDetailExtras: PlanningDetailExtra) => `${planningDetailExtras.detail}`)
            .sort();
        this.results = (await this._apiService.getResults())
            .map((result: Result) => `${result.codigo_resultado} - ${result.resultado}`)
            .sort();
        this.emplacements = (await this._apiService.getEmplacements())
            .map((emplacement: Emplacement) => `${emplacement.codigo_emplazamiento} - ${emplacement.emplazamiento}`)
            .sort();
        this.typesRadius = (await this._apiService.getTypesRadius())
            .map((typeRadius: TypeRadius) => `${typeRadius.radio}`)
            .sort();
        this.typeCounters = (await this._apiService.getTypesCounters())
            .map((typeCounter: TypeCounter) => `${typeCounter.tipo}`)
            .sort();
        this.marks = (await this._apiService.getMarks())
            .map((mark: Mark) => `${mark.codigo_marca} - ${mark.marca} - ${mark.modelo}`)
            .sort();
    }

    /**
     * Updates the plannings based on the provided value.
     * 
     * @param value - The value used to filter the plannings.
     * @returns A promise that resolves when the plannings are updated.
     */
    async updatePlannings(value: string): Promise<void> { 
        const plannings = (await this._apiService.getPlannings())
        .map((planning: Planning) => `${planning.codigo_planning} - ${planning.planning}`);
        this.plannings = plannings.filter((planning: string)=> planning.includes(value));
        if (this.utilsService.isFieldValid(value)) { 
            this.taskFormData.controls['planning'].setValue(value);
            const planningCode = value.split('-')[0].trim();
            const allPlannings = await this._apiService.getPlanningDetails();
            this.planningDetails = allPlannings.filter((detail: PlanningDetail) => detail.codigo_planning == planningCode)
                .map((planningDetails: PlanningDetail) => `${planningDetails.planning_details}`)
                .sort();
        }
    }
    
    /**
     * Sets up the form controls and subscribes to their value changes.
     * If a task is provided, it populates the form controls with the task's values.
     * 
     * @returns void
     */
    addFromControls(): void {
        if(this.task){
            this.typeCountersControl.setValue(this.task.TIPOFLUIDO_DEVUELTO);
            this.planningsControl.setValue(this.task.planning);
            this.resultsControl.setValue(this.task.resultado);
            this.zonesControl.setValue(this.task.zona);
            this.marksControl.setValue(this.task.MARCADV);
            this.emplacementsControl.setValue(this.task.EMPLAZADV);
        }

        this.planningsControl.valueChanges.subscribe(async (value: string) => {
            await this.updatePlannings(value);
        });

        this.resultsControl.valueChanges.subscribe(async (value: string) => {
            const results = (await this._apiService.getResults())
            .map((result: Result) => `${result.codigo_resultado} - ${result.resultado}`)
            .sort();
            this.results = results.filter((result: string)=> result.includes(value));
            this.taskFormData.controls['resultado'].setValue(value);
        });

        this.zonesControl.valueChanges.subscribe(async (value: string) => {
            const zones = (await this._apiService.getZones())
            .map((zone: Zone) => `${zone.codigo_zona} - ${zone.zona}`)
            .sort();
            this.zones = zones.filter((zone: string)=> zone.includes(value));
            this.taskFormData.controls['zona'].setValue(value);
        });
        
        this.typeCountersControl.valueChanges.subscribe(async (value: string) => {
            const typeCounters = (await this._apiService.getTypesCounters())
            .map((typeCounter: TypeCounter) => `${typeCounter.tipo}`)
            .sort();
            this.typeCounters = typeCounters.filter((typeCounter: string)=> typeCounter.includes(value.toUpperCase()));
            this.taskFormData.controls['TIPOFLUIDO_DEVUELTO'].setValue(value);
        });
        
        this.marksControl.valueChanges.subscribe(async (value: string) => {
            if(value) value = value.toUpperCase();
            const marks = (await this._apiService.getMarks())
            .map((mark: Mark) => `${mark.codigo_marca} - ${mark.marca} - ${mark.modelo}`)
            .sort();
            this.marks = marks.filter((mark: string)=> mark.includes(value));
            this.taskFormData.controls['MARCADV'].setValue(value);
        });
        
        this.emplacementSubscription$ = this.emplacementsControl.valueChanges.subscribe(async (value: string) => {
            await this.updateEmplacements(value);
        });
    }

    /**
     * Updates the emplacements based on the provided value.
     * 
     * @param value - The value to update the emplacements with.
     * @returns A promise that resolves when the emplacements have been updated.
     */
    async updateEmplacements(value: string): Promise<void> { 
        if(value) value = value.toUpperCase();
        const serverEmplacements = await this._apiService.getEmplacements();
        
        const emplacements = serverEmplacements 
            .map((emplacement: Emplacement) => `${emplacement.codigo_emplazamiento} - ${emplacement.emplazamiento}`)
            .sort();
        this.emplacements = emplacements.filter((emplacement: string)=>{
            const split =  emplacement.split('-');
            if(split && split.length && split[0]){
                const code = split[0].trim();
                if(code && code.includes(value)) return true;
            }
            return false;
        });
        this.setEmplacement(value);
    }

    /**
     * Sets the emplacement value and updates the form controls accordingly.
     * 
     * @param emplacement - The emplacement string to be set. If the string has a length of 2 or more,
     * it will be truncated to the first 2 characters. The truncated value is then set to the 
     * `emplacementsControl` and `EMPLAZADV` form controls. Additionally, the `RESTO_EM` form control 
     * is updated with the corresponding `resto` value from the `utilsService.emplacements` array.
     */
    setEmplacement(emplacement: string) {
        if (emplacement) {
            if(emplacement.length >= 2){
                emplacement = emplacement.substring(0, 2);
                if(emplacement != this.emplacementsControl.value) this.emplacementsControl.setValue(emplacement);
                this.taskFormData.controls['EMPLAZADV'].setValue(emplacement);
            }
            const rest = this.utilsService.emplacements.find(
                (emp) => emp.codigo_emplazamiento == emplacement
            )?.resto;
            this.taskFormData.controls['RESTO_EM'].setValue(rest);
        }
    }

    /**
     * Sets the photos for the task.
     */
    setPhotos(): void {
        if (this.task) {
            const photoFields = getPhotoFields();
            this.images = [];
            for (const photoField of photoFields) {
                const photo = this.task[photoField as keyof WaterTask]!;
                if (photo && typeof photo === 'string' && photo.includes('http')) {
                    let serieDV = this.task.seriedv;
                    if (this.utilsService.isFieldValid(this.task!.control_digit)) {
                        serieDV += this.task!.control_digit!;
                    }
                    this.images.push({
                        image: photo,
                        thumbImage: photo,
                        photoType: photoField,
                        title: `${photoField.toUpperCase().replace('_', ' ').replace('_', ' ')} ${
                            this.task.SERIE ? '   -----   SERIE : ' + this.task.SERIE : ''
                        }${
                            this.task.LECT_LEV ? '   -----   LECTURA : ' + this.task.LECT_LEV : ''
                        }${serieDV ? '   -----   SERIE DV : ' + serieDV : ''} ${
                            this.task.LECTURA_CONTADOR_NUEVO
                                ? '   -----   LECTURA NUEVO : ' + this.task.LECTURA_CONTADOR_NUEVO
                                : ''
                        }${
                            this.task.numero_serie_modulo
                                ? '   -----   MÓDULO : ' + this.task.numero_serie_modulo
                                : ''
                        }${
                            this.task.numero_precinto
                                ? '   -----   PRECINTO : ' + this.task.numero_precinto
                                : ''
                        }`,
                    });
                }
            }
        }
    }

    /**
     * Rotates the image of a task.
     * @param photoType - The type of photo to rotate.
     * @returns A Promise that resolves when the image rotation is complete.
     */
    async rotateImage(photoType: string): Promise<void> {
        const photo_url: any = this.task![photoType as keyof WaterTask];
        if (!this.utilsService.isValidImage(photo_url)) {
            this.utilsService.openSnackBar('No hay imágen disponible', 'warning');
            return;
        }

        this.uploading = true;

        const res = await this._apiService.rotateTaskImage(this.task!, photoType);
        if (res) {
            const timestamp = new Date().getTime();
            const img = document.getElementById(photoType);
            (img as any).src = `${photo_url}?t=` + timestamp;
        } else this.utilsService.openSnackBar('Error rotando foto', 'error');
        
        this.uploading = false;
    }

    /**
     * Asynchronously retrieves and processes images from the server and bucket.
     * 
     * This method calls the `_apiService.showFolderImages` method with the current task,
     * then processes the returned server and bucket files by extracting images using the 
     * `_apiService.extractImage` method. The processed images are then combined and assigned 
     * to the `imageList` property.
     * 
     * @returns {Promise<void>} A promise that resolves when the images have been successfully retrieved and processed.
     */
    async showFolderImages(): Promise<void> { 
        const res = await this._apiService.showFolderImages(this.task!);
        const serverFiles = res.serverFiles.map((file: string) => this._apiService.extractImage(this.task!, file, 'image'));
        const bucketFiles = res.bucketFiles.map((file: string) => this._apiService.extractImage(this.task!, file, 'file'));

        this.imageList = [...serverFiles, ...bucketFiles];
        if(this.imageList.length == 0) {
            this.utilsService.openSnackBar('No hay imágenes disponibles en el servidor', 'warning');
        }
    }

    /**
     * Displays the image corresponding to the given photo type.
     * @param photoType - The type of the photo to display.
     */
    showImage(photoType: string) {
        const photo_url: any = this.task![photoType as keyof WaterTask];
        if (!this.utilsService.isValidImage(photo_url)) {
            this.utilsService.openSnackBar('No hay imágen disponible', 'warning');
            return;
        }
        
        let i = 0;
        for (const imageObject of this.images) {
            if ((imageObject as any).photoType == photoType) {
                this.currentIndex = i;
                break;
            }
            i++;
        }
        const dialogConfig = {
            data: {},
            panelClass: 'carrousel',
        };

        let photosData = {
            photos: this.images,
            currentIndex: this.currentIndex,
        };

        dialogConfig.data = photosData;
        this.matDialog.open(DialogImageComponent, dialogConfig);
    }

    /**
     * Closes the event handler and resets the showFlag and currentIndex properties.
     */
    closeEventHandler(): void {
        this.showFlag = false;
        this.currentIndex = -1;
    }

    /**
     * Sets up the value change subscriptions for the input controls.
     * This method is responsible for subscribing to the valueChanges event of each input control
     * and performing the necessary actions when the value changes.
     * @returns A Promise that resolves when the setup is complete.
     */
    async setInputsValueChanged(): Promise<void> {
        this.taskFormData.controls['TIPORDEN'].valueChanges.subscribe((_) => {
            this.orderType_dirty = true;
        });
        this.taskFormData.controls['Numero_de_ABONADO'].valueChanges.subscribe((_) => {
            this.utilsService.validateValue(this.taskFormData.controls['Numero_de_ABONADO']);
        });
        this.taskFormData.controls['NUMIN'].valueChanges.subscribe((_) => {
            this.utilsService.validateValue(this.taskFormData.controls['NUMIN']);
        });
        this.taskFormData.controls['causa_origen'].valueChanges.subscribe(async (cause: any) => {
            this.assignCauseOriginValues(cause);
        });
        this.taskFormData.controls['AREALIZARDV'].valueChanges.subscribe(async (cause: any) => {
            this.assignCauseValues(cause);
        });
        this.taskFormData.controls['prioridad'].valueChanges.subscribe(async (priority: any) => {
            await this.onPriorityChange(priority);
        });
        this.taskFormData.controls['codigo_de_geolocalizacion'].valueChanges.subscribe(
            async (code: any) => {
                this.timerInputChanged = setTimeout(async () => {
                    if (code) {
                        clearTimeout(this.timerInputChanged);
                        if(this.utilsService.validateValue(this.taskFormData.controls['codigo_de_geolocalizacion'])) return;
                        await this.assignItacValues(code);
                    }
                }, 1000);
            }
        );
    }

    /**
     * Assigns cause values to the task form data.
     * 
     * @param cause - The cause string.
     */
    assignCauseValues(cause: string): void {
        if (cause) {
            const anomaly = cause.split('-')[0].trim();
            const orderAction = this.utilsService.getOrderActionFromCauseCode(anomaly);
            this.taskFormData.controls['intervencidv'].setValue(orderAction);
        }
    }

    /**
     * Assigns cause origin values to the task form data.
     * 
     * @param cause - The cause string.
     */
    assignCauseOriginValues(cause: string): void {
        if (cause) {
            const anomaly = cause.split('-')[0].trim();
            if (anomaly) {
                this.taskFormData.controls['ANOMALIA'].setValue(anomaly);
                let waterTask: WaterTask = {};
                waterTask.ANOMALIA = anomaly;
                waterTask.CALIBRE = this.taskFormData.controls['CALIBRE'].value;
                waterTask.MARCA = this.taskFormData.controls['MARCA'].value;
                waterTask = this.utilsService.autocompleteCauseFields(waterTask);
                const keys = Object.keys(waterTask);
                for (let key of keys) {
                    if (key != 'causa_origen') {
                        this.taskFormData.controls[key].setValue(
                            waterTask[key as keyof WaterTask]
                        );
                    }
                }
            }
        }
    }

    /**
     * Handles the change event of the priority selection.
     * @param priority - The selected priority.
     * @returns A promise that resolves when the priority change is handled.
     */
    async onPriorityChange(priority: any): Promise<void> {
        this.priority_dirty = true;
        try {
            if (priority == priority_status.HIBERNATE) await this.setHibernationPriority();
            else this.taskFormData.controls['hibernacion'].setValue(false);
        }
        catch (err) {
            const text = 'No se asigno la hibernación correctamente';
            this.utilsService.openSnackBar(text, 'error');
            this.taskFormData.controls['prioridad'].setValue(
                this.taskFormData.controls['end_hibernation_priority'].value
            );
        }
    }

    /**
     * Sets the hibernation priority for the task.
     * Prompts the user to select the end date and time for hibernation.
     * If the user selects both a date and time, updates the task form data with the hibernation details.
     * Displays a success message if the hibernation is set successfully.
     * Displays an error message and reverts the priority if the hibernation is not set.
     */
    async setHibernationPriority() {
        const hourText = 'Seleccione hora de fin de hibernación';
        const dateText = 'Seleccione fecha fin de hibernación';
        const endHibernationDate = await this.utilsService.openDateSelectorDialog(dateText);
        const endHibernationTime = await this.utilsService.openTimeSelectorDialog(hourText);
        if (endHibernationDate && endHibernationTime) {
            const momentDate: moment.Moment = moment(endHibernationDate);
            momentDate.set({
                hour: endHibernationTime.getHours(),
                minute: endHibernationTime.getMinutes(),
                second: 0,
            });
            this.taskFormData.controls['hibernacion'].setValue(true);
            this.taskFormData.controls['end_hibernation_date'].setValue(
                momentDate.toDate().toISOString()
            );
            const message = 'Tarea hibernada correctamente, por favor actualice la tarea para guardar cambios';
            this.utilsService.openSnackBar(message);
        } else {
            const text = 'No se asigno la hibernación correctamente'; 
            this.utilsService.openSnackBar(text, 'error');
            this.taskFormData.controls['prioridad'].setValue(
                this.taskFormData.controls['end_hibernation_priority'].value
            );
        }
    }

    /**
     * Assigns ITAC values to the task form data based on the provided code.
     * @param code - The code used to retrieve ITAC and water route information.
     */
    async assignItacValues(code: string): Promise<void> {
        const companyId = localStorage.getItem('company');
        const managerId = localStorage.getItem('manager');
        const itacs = await this._apiService.getItacs([
            ['codigo_itac', '==', code],
            ['company', '==', companyId],
            ['gestor', '==', managerId],
        ]);
        if (itacs && itacs.length > 0) {
            const itac = itacs[0];
            if (itac.zona) this.taskFormData.controls['zona'].setValue(itac.zona);
            
            if (itac.geolocalizacion) {
                this.taskFormData.controls['pendent_location'].setValue(false);
                this.taskFormData.controls['geolocalizacion'].setValue(itac.geolocalizacion);
                this.taskFormData.controls['codigo_de_localizacion'].setValue(itac.geolocalizacion);
                this.taskFormData.controls['url_geolocalizacion'].setValue(
                    this.utilsService.getGeolocationUrl(itac.geolocalizacion)
                );
            }
        }
        if (code.includes('-')) code = code.split('-')[0].trim();
        
        const waterRoutes = await this._apiService.getWaterRoutes([
            ['codigo_ruta', '==', code],
            ['company', '==', companyId],
            ['manager', '==', managerId],
        ]);
        if (waterRoutes && waterRoutes.length > 0) {
            const waterRoute = waterRoutes[0];
            this.taskFormData.controls['zona'].setValue(waterRoute.barrio);
            this.taskFormData.controls['ruta'].setValue(waterRoute.ruta);
            this.taskFormData.controls['tipoRadio'].setValue(waterRoute.radio_portal);
            this.taskFormData.controls['bloque'].setValue(
                this.utilsService.getDayOfZona(waterRoute.barrio!)
            );
        }
    }

    /**
     * Opens the parts selection dialog and updates the task form data with the selected parts.
     * @returns {Promise<void>} A promise that resolves when the parts selection is complete.
     */
    async openPartsSelection(): Promise<void> {
        try {
            const partsSelected = await this.utilsService.openPartsSelectionDialog(
                this.taskFormData.controls['piezas'].value
            );
            if (partsSelected) this.taskFormData.controls['piezas'].setValue(partsSelected);
        } catch (err) {}
    }

    /**
     * Edits the team for the task.
     * @returns A Promise that resolves when the team is successfully edited.
     */
    async editTeam(): Promise<void> {
        try {
            const teamId = await this._apiService.selectTeamId();
            if(teamId > 0){
                const data = { equipo: teamId, ultima_modificacion: this._apiService.getLoggedUserId() };
                const success = await this._apiService.updateTask(this.taskId, data, true);
                if (success) {
                    if(this.task) this.task.equipo = teamId;
                    this.taskFormData.controls['equipo'].setValue(teamId);
                    this.utilsService.openSnackBar('Tarea actualizada correctamente');
                }
                else this.utilsService.openSnackBar('Error actualizando tarea', 'error');
            }
        } catch (err) {}
    }

    async editOperators(): Promise<void> {
        try {
            const miRutaUsers = await this.selectOperators();
            if(miRutaUsers.length){
                const data = { OPERARIO: miRutaUsers, ultima_modificacion: this._apiService.getLoggedUserId() };
                const success = await this._apiService.updateTask(this.taskId, data, true);
                if (success) {
                    if(this.task) this.task.OPERARIO = miRutaUsers;
                    this.taskFormData.controls['OPERARIO'].setValue(miRutaUsers);
                    this.utilsService.openSnackBar('Tarea actualizada correctamente');
                }
                else this.utilsService.openSnackBar('Error actualizando tarea', 'error');
            }
        } catch (err) {}
    }

    /**
     * Retrieves and filters a list of MiRutaUser objects based on the user's selection.
     * 
     * @returns A promise that resolves to an array of MiRutaUser objects.
     */
    async selectOperators(): Promise<MiRutaUser[]> {
        try {
            const company = localStorage.getItem('company');
            let users = await this._apiService.getUsers(['operario']);
            users = users.filter((user) =>
                this.utilsService.checkCompany(user.companies, company || '')
            );
            const usersSelectedName = await this.utilsService.openMultipleOptionsDialog(
                'Seleccione operarios',
                users.map((user: MiRutaUser) => this.utilsService.userPipe(user.id)),
                []
            );
            if (usersSelectedName && usersSelectedName.length > 0) {
                const miRutaUsers = users.filter((user: MiRutaUser) =>
                    usersSelectedName.includes(this.utilsService.userPipe(user.id))
                );
                if (miRutaUsers) return miRutaUsers;
            }
        } catch (err) {}
        return [];
    }

    /**
     * Edits the state of the task.
     * If the taskId is 'new', the method returns early.
     * Opens a selector dialog to choose a new status for the task.
     * Updates the task with the new status if a status is selected.
     * Displays a success or error message based on the update result.
     */
    async editState(): Promise<void> {
        try {
            if(this.taskId == 'new') return;
            const statuses = this.utilsService.getTaskStatuses()
            const listStatus = statuses.map((s)=> this.utilsService.getTaskStatusFromInt(s));
            const statusSelected = await this.utilsService.openSelectorDialog('Seleccione estado', listStatus);
            if(statusSelected){
                const status = this.utilsService.getTaskStatusFromString(statusSelected);
                const data = { status_tarea: status, ultima_modificacion: this._apiService.getLoggedUserId() };
                const success = await this._apiService.updateTask(this.taskId, data, true);
                if (success) {
                    if(this.task) this.task.status_tarea = status;
                    this.taskFormData.controls['status_tarea'].setValue(status);
                    this.utilsService.openSnackBar('Tarea actualizada correctamente');
                }
                else this.utilsService.openSnackBar('Error actualizando tarea', 'error');
            }
        } catch (err) {}
    }

    /**
     * Downloads an image associated with the current task.
     * 
     * This method retrieves the selected company and manager information from local storage,
     * constructs the filename for the image, and then uses the `fileSaverService` to download the image.
     * 
     * @param image - The URL or path of the image to be downloaded.
     * 
     * @remarks
     * - The method assumes that `this.task` is defined.
     * - The company and manager information are expected to be stored in local storage under the keys 'companyJson' and 'managerJson' respectively.
     * - The `utilsService` is used to generate the image filename.
     * - The `fileSaverService` handles the actual downloading of the image.
     */
    downloadImage(image: string): void { 
        if (this.task) {
            const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
            const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
            const filename = this.utilsService.getImageName(image);
            const photo = { 'photo': image, 'downloadName': filename };
            this.fileSaverService.downloadPhoto(photo, this.task, companySelected, managerSelected);
        }
    }

    /**
     * Opens a PDF document for the task.
     * If there are no valid images available, it displays a warning message.
     * If the application is running in an Electron environment, it downloads the PDF and opens the folder.
     * Otherwise, it saves the PDF locally.
     */
    async openPDF(): Promise<void> {
        let noneValidImage = true;
        const photos = getPhotoFields();

        for (let photo of photos) {
            let key: keyof WaterTask = photo! as keyof WaterTask;
            if (this.utilsService.isValidImage(this.task![key] as string)) {
                noneValidImage = false;
            }
        }
        if (noneValidImage) {
            this.utilsService.openSnackBar(
                'No hay imágenes disponibles para crear el pdf',
                'warning'
            );
            return;
        }

        this.showLoading(true);
        let barcodeSerialData: string = '';
        let barcodeSerialDVData: string = '';
        if (this.task?.SERIE) {
            try {
                barcodeSerialData = this.utilsService.textToBase64Barcode(this.task?.SERIE);
            } catch (err) {}
        }
        if (this.task?.seriedv) {
            let serieDV = this.task.seriedv;
            if (this.utilsService.isFieldValid(this.task!.control_digit)) {
                serieDV += this.task!.control_digit!;
            }
            try {
                barcodeSerialDVData = this.utilsService.textToBase64Barcode(serieDV!);
            } catch (err) {}
        }
        const pdfName = `TAREA_${this.task?.Numero_de_ABONADO}_${this.task?.ANOMALIA}.pdf`;
        const blob = await this._apiService.getTaskPdf(
            this.task!.id!,
            barcodeSerialData,
            barcodeSerialDVData,
        );
        if (this.electronService.isElectronApp()) {
            const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
            const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
            const path = `C:\\Mi_Ruta\\Empresas\\${
                companySelected.nombre_empresa || companySelected.id
            }\\Gestores\\${managerSelected.gestor || managerSelected.id}\\fotos_tareas\\${
                this.task!.Numero_de_ABONADO
            }\\${this.task!.ANOMALIA || defaultAnomaly}`;
            const pdfBase64 = await this.utilsService.convertBlobToBase64(blob);

            const message = {
                message: 'download_pdf',
                pdfBase64: pdfBase64,
                path: path,
                filename: pdfName,
            };
            this.electronService.sendMessageSync(message);
            this.electronService.sendMessage('open-folder', {
                path: `${path}\\${pdfName}`,
            });
        } else {
            if(blob) saveAs(blob, pdfName);
        }
        this.showLoading(false);
    }

    /**
     * Shows counter options based on the selected result from `utilsService.openSerialNumberOptionSelector()`.
     * @param title - The title of the counter option.
     * @param barcodeText - The barcode text.
     */
    async showCounterOptions(title: string, barcodeText: string): Promise<void> {
        try {
            const res = await this.utilsService.openSerialNumberOptionSelector();
            if (res == 'Barcode with digit') this.setBarcodeWithDigit(title, barcodeText);                
            else if (res == 'Barcode') this.openBarcode(title, barcodeText);
            else if (res == 'Add counter')  await this.addCounter(title);
        } catch (err) {}
    }

    /**
     * Asynchronously searches for counter data and fills the relevant fields.
     * 
     * This method checks if the 'seriedv' field in the task form data is valid.
     * If valid, it calls the `fillCounterData` method with the value of the 'seriedv' field
     * and a description string 'Nº Serie instalado'.
     * 
     * @returns {Promise<void>} A promise that resolves when the counter data has been filled.
     */
    async searchCounterAndFillData(): Promise<void> {
        const serialNumber = this.taskFormData.controls['seriedv'].value;
        if(this.utilsService.isFieldValid(serialNumber)) {
            this.showLoading(true);
            const found = await this.fillCounterData(serialNumber, 'Nº Serie instalado');
            if(!found) {
                this.utilsService.openSnackBar('No se encontró el contador', 'warning');
            }
            this.showLoading(false);
        } 
    }
    
    /**
     * Copies the old emplacement value from the task form data and sets it if it is valid.
     * 
     * This method retrieves the old emplacement value from the form control 'EMPLAZA'.
     * If the value is valid, it fetches the list of emplacements from the server and 
     * finds the matching emplacement. If a matching emplacement is found, it sets 
     * the emplacement using the `setEmplacement` method.
     * 
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async copyOldEmplacement(): Promise<void> {
        const oldEmplacement = this.taskFormData.controls['EMPLAZA'].value;
        if(this.utilsService.isFieldValid(oldEmplacement)) {
            if(this.utilsService.isBatteryEmplacement(oldEmplacement)) {
                this.copyBatteryEmplacementData(oldEmplacement);
                return;
            }
            const serverEmplacements = await this._apiService.getEmplacements();
            const emplacement = serverEmplacements.find((emp) => emp.emplazamiento == oldEmplacement);

            if(emplacement) this.setEmplacement(emplacement.emplazamiento);
        }
    }

    /**
     * Copies the battery emplacement data from the given old emplacement string.
     * 
     * This method splits the old emplacement string by the '-' character. If the split
     * results in a non-empty array and the first element is not empty, it trims the first
     * element and sets it as the value for the 'EMPLAZADV' control. The rest of the string
     * (after removing the first element) is trimmed and set as the value for the 'RESTO_EM' control.
     * 
     * @param oldEmplacement - The old emplacement string to copy data from.
     * @returns void
     */
    async copyBatteryEmplacementData(oldEmplacement: string): Promise<void> {
        if(this.utilsService.isFieldNotValid(oldEmplacement)) return;

        const split = oldEmplacement.split('-');
        if(split.length && split[0]) {
            const code = split[0].trim();

            if(this.emplacementSubscription$) this.emplacementSubscription$.unsubscribe();

            this.setControlValue('EMPLAZADV', code);
            this.emplacementsControl.setValue(code);
            this.setControlValue('RESTO_EM', oldEmplacement.replace(code, '').trim());
            
            this.emplacementSubscription$ = this.emplacementsControl.valueChanges.subscribe(async (value: string) => {
                await this.updateEmplacements(value);
            });
        }
    }

    /**
     * Sets the barcode for a given title and barcode text.
     * 
     * @param {string} title - The title of the barcode.
     * @param {string} barcodeText - The text of the barcode.
     * @returns {void}
     */
    openBarcode(title: string, barcodeText: string): void {
        if (barcodeText) {
            if (title == 'Nº Serie instalado') this.showBarcode(title, barcodeText);
            else this.showBarcode(title, barcodeText);
        } else {
            this.utilsService.openSnackBar('No hay contador disponible', 'warning');
        }
    }

    /**
     * @brief Sets the barcode with the digit.
     * @param title - The title of the barcode.
     * @param barcodeText - The text of the barcode.
     * @returns void
     */
    setBarcodeWithDigit(title: string, barcodeText: string): void {
        if (this.task!.seriedv) {
            let serieDV = this.task!.seriedv;
            if (this.utilsService.isFieldValid(this.task!.control_digit)) {
                serieDV += this.task!.control_digit!;
            }
            if (title == 'Nº Serie instalado') this.showBarcode(title, serieDV!);
            else this.showBarcode(title, barcodeText);
        } else {
            this.utilsService.openSnackBar('No hay contador disponible', 'warning');
        }
    }

    /**
     * @brief Adds a counter with the specified title.
     * 
     * @param title - The title of the counter.
     * @returns A Promise that resolves when the counter is added.
     */
    async addCounter(title: string): Promise<void> {
        const serialNumber = await this.utilsService.openFillCounter();
        if (serialNumber) {
            this.showLoading(true);
            const found = await this.fillCounterData(serialNumber, title);
            if(!found) {
                this.utilsService.openSnackBar('No se encontró el contador', 'warning');
            } 
            this.showLoading(false);
        }
    }

    /**
     * Fills the counter data based on the provided serial number and counter type.
     * 
     * @param serialNumber - The serial number of the counter to be filled.
     * @param counterType - The type of the counter to be filled.
     * @returns A promise that resolves when the counter data has been filled.
     * 
     * @remarks
     * This method constructs a query to fetch counter data from a MySQL service
     * based on the provided serial number. It then sets the counter data using
     * the `setCounterData` method.
     * 
     * @throws Will throw an error if the MySQL service fails to fetch the counter data.
     */
    async fillCounterData(serialNumber: string, counterType: string): Promise<boolean> {
        if(this.utilsService.isFieldNotValid(serialNumber)) return false;

        let whereJsonArray: any = this.utilsService.getJsonArray('numero_serie_contador', 'AND', serialNumber);
        const counters = await this._mySqlService.getCounters(
            undefined,
            JSON.stringify(whereJsonArray), 
            undefined, 
            undefined, 
            '1'
        );
        if(counters && counters.length) {
            this.setCounterData(counters[0], counterType);
            return true;
        }
        return false;
    }

    /**
     * @brief Sets the counter data for a given serial number, counter, and title.
     * @param {Counter} counter - The counter object.
     * @param {string} counterType - The title indicating whether the counter is being installed or removed.
     * @returns {void}
     */
    setCounterData(counter: Counter, counterType: string): void {
        if (counter) {
            counter.status_contador = counter_status.INSTALLED;
            if (!counter.lectura_inicial) counter.lectura_inicial = this.task!.LECTURA_CONTADOR_NUEVO || 0;
            counter.date_time_modified = new Date();
            counter.fecha_instalado = new Date();
            this.counter = counter;
            const mark = `${counter.codigo_marca} - ${counter.marca} - ${counter.modelo}`;
            let classCounter = `${counter.codigo_clase} - ${counter.clase}`;
            if(counter.codigo_clase){
                classCounter = this.classes.find(c => c.includes(counter.codigo_clase!.trim() + ' -'))!;
            }
            if (counterType == 'Nº Serie retirado') this.setRetiredCounterData(counter, mark, classCounter);
            else if (counterType == 'Nº Serie instalado') this.setInstalledCounterData(counter, mark, classCounter);
        }
    }

    /**
     * Sets the retired counter data in the task form.
     *
     * @param {Counter} counter - The counter object containing the retired counter data.
     * @param {string} mark - The mark of the counter.
     * @param {string} classCounter - The class of the counter.
     * 
     * This method updates the task form controls with the provided counter data.
     * It sets the values for 'SERIE', 'MARCA', 'CALIBRE', 'LARGO', 'RUEDAS', 'TIPOFLUIDO', and 'TIPO'.
     * Additionally, if the counter has a 'tipo_radio' property, it sets the value for 'tipoRadio' control.
     */
    setRetiredCounterData(counter: Counter, mark: string, classCounter: string) {
        this.setControlValue('SERIE', counter.numero_serie_contador);
        this.setControlValue('MARCA', mark);
        this.setControlValue('CALIBRE', counter.calibre);
        this.setControlValue('LARGO', counter.longitud);
        this.setControlValue('RUEDAS', counter.ruedas);
        this.setControlValue('TIPOFLUIDO', counter.tipo_fluido);
        this.setControlValue('TIPO', classCounter);
        if(counter.tipo_radio) this.setControlValue('tipoRadio', counter.tipo_radio);
    }

    /**
     * Sets the installed counter data in the form controls.
     *
     * @param {Counter} counter - The counter object containing the data to be set.
     * @param {string} mark - The mark value to be set in the form control.
     * @param {string} classCounter - The class counter value to be set in the form control.
     */
    setInstalledCounterData(counter: Counter, mark: string, classCounter: string) {
        const hrid = this.taskFormData.controls['numero_serie_modulo'].value;

        this.setControlValue('seriedv', counter.numero_serie_contador);
        this.setControlValue('MARCADV', mark);
        this.setControlValue('CALIBREDV', counter.calibre);
        this.setControlValue('LONGDV', counter.longitud);
        this.setControlValue('RUEDASDV', counter.ruedas);
        this.setControlValue('TIPOFLUIDO_DEVUELTO', counter.tipo_fluido);
        this.setControlValue('TIPO_DEVUELTO', classCounter);

        if(counter.tipo_radio) this.setControlValue('TIPORADIO_DEVUELTO', counter.tipo_radio);
        if(this.utilsService.isFieldNotValid(hrid) && counter.numero_serie_modulo) {
            this.setControlValue('numero_serie_modulo', counter.numero_serie_modulo);
        }
    }

    /**
     * Sets the value of a specified control in the task form.
     *
     * @param field - The name of the control whose value needs to be set.
     * @param value - The value to set for the specified control.
     */
    setControlValue(field: string, value: any) {
        this.utilsService.setFormControlValue(this.taskFormData, field, value);
    }

    /**
     * Displays a barcode dialog with the specified title and barcode text.
     * @param title - The title of the barcode dialog.
     * @param barcodeText - The text to be displayed as the barcode.
     */
    async showBarcode(title: string, barcodeText: string) {
        await this.utilsService.openBarcodeDialog(title, barcodeText);
    }

    /**
     * Selects a file and performs necessary validations before saving the form data.
     * @param input_file - The input element used for file selection.
     * @param column - The column associated with the selected file.
     */
    async selectFile(input_file: any, column: string): Promise<void> {
        this.saveFormData();
        if (!this.task?.ANOMALIA || !this.task?.GESTOR || !this.task?.Numero_de_ABONADO || !this.task?.SERIE) {
            const text = 'Seleccione anomalía, gestor, serie y numero de abonado';
            this.utilsService.openSnackBar(text, 'error');
            return;
        }
        this.photoColumn = column;
        input_file.click();
    }

    /**
     * Uploads a file to the server.
     * 
     * @param event - The event object containing the selected file.
     */
    async uploadFile(event: any): Promise<void> {
        const files = event.target.files;
        const file = files.item(0);
        this.showLoading(false);
        if (file) {
            this.uploading = true;
            this.form.patchValue({ image: file });
            this.form?.get('image')?.updateValueAndValidity();
            await this.uploadImage();
            this.uploading = false;
            this.setPhotos();
        }
    }

    /**
     * @brief Selects a date appointment for the task.
     * 
     * @returns {Promise<void>} A promise that resolves when the date appointment is selected.
     * @throws {Error} If the date appointment selection fails.
     */
    async selectDateAppointment(): Promise<void> {
        try {
            const { startDate, endDate, dateStatus } = await this.utilsService.selectDateRange(true, this.task);

            this.taskFormData.controls['date_status'].setValue(dateStatus);
            this.task!.date_status = dateStatus;

            this.taskFormData.controls['fecha_hora_cita'].setValue(startDate.toDate());
            this.taskFormData.controls['fecha_hora_cita_end'].setValue(endDate.toDate());
            this.taskFormData.controls['cita_pendiente'].setValue(true);

            this.task!.nuevo_citas = this.utilsService.getNewDateString(startDate, endDate);
            this.taskFormData.controls['nuevo_citas'].setValue(this.task!.nuevo_citas);

            this.updatePlanningMRXToMR16();

            this.resetPriority();
        } catch (error) {
            this.utilsService.openSnackBar('Cita no seleccionada', 'warning');
        }
    }

    /**
     * Updates the planning of the task to MR16 if the current planning includes MR2, MR3, MR4, or MR13.
     * It sets the planning, planning date modified, and form controls accordingly.
     */
    updatePlanningMRXToMR16() {
        if (this.task && this.task.planning) {
            if (this.task.planning.includes('MR2 ') || this.task.planning.includes('MR3 ') ||
                this.task.planning.includes('MR4 ') || this.task.planning.includes('MR13 ')) {
                for (const planning of this.plannings) {
                    if (planning.includes('MR16 -')) {
                        this.task.planning = planning;
                        this.planningsControl.setValue(planning);
                        this.taskFormData.controls['planning'].setValue(planning);
                        this.taskFormData.controls['planning_date_modified'].setValue(new Date());
                        break;
                    }
                }
            }
        }
    }

    /**
     * Resets the priority of the task.
     * If the task is in hibernation or has a priority of HIBERNATE, it sets the priority to LOW.
     * If the task has an end_hibernation_priority, it sets the priority to that value.
     * Updates the hibernacion and prioridad form controls.
     */
    resetPriority(): void {
        if(this.task && (this.task.hibernacion || this.task.prioridad == priority_status.HIBERNATE)){
            if(this.task.end_hibernation_priority){
                this.task.prioridad = this.task.end_hibernation_priority;
            }
            else this.task.prioridad = priority_status.LOW;
            this.task.hibernacion = false;

            this.taskFormData.controls['hibernacion'].setValue(false);
            this.taskFormData.controls['prioridad'].setValue(this.task.prioridad);
        }
    }

    /**
     * Opens the task location.
     * If the user is a client and the task status is not 'REQUIRED',
     * it will navigate to the task's geolocation URL if available.
     * Otherwise, it will navigate to the 'task-location' route with the task ID.
     */
    openTaskLocation(): void {
        if (this.utilsService.isClientUser() && this.task?.status_tarea != task_status.REQUIRED) {
            if (this.task?.url_geolocalizacion) {
                this.utilsService.goToLink(this.task?.url_geolocalizacion);
            }
        } else {
            this.router.navigate(['/task-location', this.taskId]);
        }
    }

    /**
     * Saves the form data by updating the corresponding properties of the task object.
     */
    saveFormData(): void {
        if(this.task){
            this.task = this.utilsService.saveTaskFormData(this.taskFormData, this.task);
        }
    }

    /**
     * Saves the changes made to the task.
     * If the data is not valid, the method returns early.
     * Shows a loading indicator while saving the changes.
     * If the task ID is 'new', initializes the task object.
     * Saves the form data.
     * Updates the counter if it exists.
     * If the task ID is 'new', adds a new task.
     * Otherwise, updates the task information.
     * Hides the loading indicator after saving the changes.
     */
    async saveChanges(): Promise<void> {
        if (!this.isValidData()) return;
        this.showLoading(true);
        if (this.taskId == 'new') this.task = {};
        this.saveFormData();
        if (this.counter) await this._apiService.updateCounter(this.counter.id!.toString(), this.counter);
        if (this.taskId == 'new') await this.addNew();
        else await this.updateInfo();
        this.showLoading(false);
    }

    /**
     * Checks if the data in the task form is valid.
     * @returns {boolean} Returns true if the data is valid, false otherwise.
     */
    isValidData(): boolean {
        let fields: string[] = [];
        for (const control of Object.keys(this.taskFormData.controls)) {
            if (!this.taskFormData.controls[control].valid) fields.push(getFieldName(control))
        }
        if (this.taskFormData.valid) return true;
        this.utilsService.openInformationDialog('Campos inválidos o inexistentes', [fields.join(' - ')]);
        return false;
    }

    /**
     * Adds a new task.
     * 
     * @returns {Promise<void>} A promise that resolves when the task is added successfully.
     * @throws {Error} If there is an error adding the task.
     */
    async addNew(): Promise<void> { 
        try {
            let info;
            let idOrdenStart = 0;
            if (!this.task?.idOrdenCABB) {
                info = await this._apiService.getInfo();
                if (info) {
                    idOrdenStart = info.lastIDOrden!;
                    idOrdenStart++;
                }
                this.task!.idOrdenCABB = idOrdenStart;
            }
            this.task = this.utilsService.fullFillTask(this.task!, this.task!.idOrdenCABB);
            const taskId = await this._apiService.addTask(this.task!);
            if (taskId) {
                this.task!.id = taskId;
                this.taskId = taskId;
                if (info) {
                    info.lastIDOrden = idOrdenStart; // I substract 1 because of the last wrong increment
                    info.lastIDSAT = idOrdenStart;
                    await this._apiService.updateDocument('info', info.id!, info);
                }
                this.utilsService.openSnackBar('Tarea añadida correctamente');
                this.router.navigate(['/task', taskId]);
            } else {
                this.utilsService.openSnackBar('Error añadiendo tarea', 'error');
            }
        } catch (error) {
            this.utilsService.openSnackBar('Error añadiendo tarea', 'error');
        }
    }

    /**
     * Updates the task information.
     * @returns {Promise<void>} A promise that resolves when the task is updated successfully.
     */
    async updateInfo(): Promise<void> {
        // this.utilsService.removeNullFields(this.task);
        let success: boolean = await this._apiService.updateTask(this.taskId, this.task, true);
        if (success) this.utilsService.openSnackBar('Tarea actualizada correctamente');
        else this.utilsService.openSnackBar('Error actualizando tarea', 'error');
    }

    /**
     * Sends a message using the provided phone numbers.
     * If at least one phone number is available, it sets the phone numbers on the task object
     * and opens a WhatsApp message dialog using the `utilsService.openWhatsappMessageDialog` method.
     * If no phone numbers are available, it displays an error message using the `utilsService.openSnackBar` method.
     */
    async sendMessage() {
        if (
            this.taskFormData.controls['telefono1'].value ||
            this.taskFormData.controls['telefono2'].value
        ) {
            this.task!.telefono1 = this.taskFormData.controls['telefono1'].value;
            this.task!.telefono2 = this.taskFormData.controls['telefono2'].value;
            await this.utilsService.openWhatsappMessageDialog(this.task!);
        } else {
            this.utilsService.openSnackBar('No hay teléfonos disponibles', 'error');
        }
    }

    /**
     * Clears the date and related fields in the task form data.
     * Prompts the user for confirmation before clearing the date.
     */
    async clearDate() {
        const question = '¿Seguro desea eliminar la cita?';
        if (await this.utilsService.openQuestionDialog('Confirmación', question)) {
            this.taskFormData.controls['nuevo_citas'].setValue('');
            this.taskFormData.controls['cita_pendiente'].setValue(false);
            this.taskFormData.controls['fecha_hora_cita'].setValue(null);
            this.taskFormData.controls['fecha_hora_cita_end'].setValue(null);
        }
    }

    /**
     * Copies the information from the form controls to the corresponding destination controls.
     * If any of the destination controls are not provided, default values will be used.
     */
    async copyInformation() {
        let marca = this.taskFormData.controls['MARCA'].value;
        let largo = this.taskFormData.controls['LARGO'].value;
        let tipo_fluido = this.taskFormData.controls['TIPOFLUIDO'].value;
        let clase_contador = this.taskFormData.controls['TIPO'].value;
        let calibre = this.taskFormData.controls['CALIBRE'].value;
        let ruedas = this.taskFormData.controls['RUEDAS'].value;
        let tipoRadio = this.taskFormData.controls['tipoRadio'].value;
        let serie = this.taskFormData.controls['SERIE'].value;

        if (!largo) largo = '115';
        if (!tipo_fluido) tipo_fluido = 'FRIA';
        if (!clase_contador) clase_contador = 'U - Chorro Único';

        if (!marca) {
            largo = '115';
            tipo_fluido = 'FRIA';
            clase_contador = 'U - Chorro Único';
        }
        this.taskFormData.controls['MARCADV'].setValue(marca);
        this.taskFormData.controls['LONGDV'].setValue(largo);
        this.taskFormData.controls['TIPOFLUIDO_DEVUELTO'].setValue(tipo_fluido);
        this.taskFormData.controls['TIPO_DEVUELTO'].setValue(clase_contador);
        this.taskFormData.controls['CALIBREDV'].setValue(calibre);
        this.taskFormData.controls['RUEDASDV'].setValue(ruedas);
        this.taskFormData.controls['tipoRadio'].setValue(tipoRadio);
        this.taskFormData.controls['seriedv'].setValue(serie);
    }

    /**
     * Closes the task if the data is valid and the user confirms the action.
     * Saves the form data, opens a service information dialog, and reloads the page if the result is true.
     * If the application is running in an Electron environment, it navigates back; otherwise, it closes the window.
     */
    async onCloseTask() {
        if (!this.isValidData()) return;
        if (await this.utilsService.openQuestionDialog('Confirmación', '¿Seguro desea cerrar la tarea?')) {
            try {
                this.saveFormData();
                const result = await this.utilsService.openServicesInformDialog(this.task!);
                if (result) {
                    this.reload();
                    if (this.electronService.isElectronApp()) this.location.back();
                    else window?.top?.close();
                }
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
    }

    /**
     * Shows or hides the loading spinner based on the given state.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('taskSpinner', {
                type: this.utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('taskSpinner');
        }
    }

    /**
     * Opens an ITAC based on the provided code.
     * If the ITAC exists, it navigates to the ITAC details page.
     * If the ITAC doesn't exist, it prompts the user to create a new ITAC.
     *
     * @param code - The code of the ITAC to open.
     * @returns Promise<void>
     */
    async openITAC(code: string) {
        if(this.utilsService.validateValue(this.taskFormData.controls['codigo_de_geolocalizacion'])) return;
        this.showLoading(true);
        const itac = await this._apiService.getItacWithCode(code);
        if (itac) {
            this.showLoading(false);
            this.router.navigate(['/itac', itac.id]);
        } else {
            this.showLoading(false);
            if (this.utilsService.isClientUser()) {
                this.utilsService.openSnackBar('No hay Itac disponible', 'warning');
                return;
            }
            const result = await this.utilsService.openQuestionDialog(
                'No exite ITAC',
                '¿Desea crear nuevo ITAC?'
            );
            this.showLoading(true);
            if (result) {
                let company = localStorage.getItem('company');
                const itacId = await this._apiService.addDocument('itac', {
                    codigo_itac: code,
                    company: parseInt(company!),
                    itac: this.utilsService.getDirOfTask(this.task!, false),
                    zona: this.taskFormData.controls['zona'].value,
                    acceso: this.taskFormData.controls['acceso_devuelto'].value,
                    gestor: this.taskFormData.controls['GESTOR'].value,
                    equipo: this.taskFormData.controls['equipo'].value,
                    operario: this.taskFormData.controls['OPERARIO'].value,
                    prioridad: this.taskFormData.controls['prioridad'].value,
                    bloque: this.taskFormData.controls['bloque'].value,
                    descripcion: 'Nueva itac',
                    geolocalizacion:
                        this.task?.codigo_de_localizacion ?? this.task?.geolocalizacion,
                });
                if (itacId) {
                    this.router.navigate(['/itac', itacId]);
                }
            }
        }
        this.showLoading(false);
    }

    /**
     * Clears the messages in the task after user confirmation.
     * 
     * This method opens a confirmation dialog asking the user if they are sure they want to delete the messages.
     * If the user confirms, it clears the `MENSAJE_LIBRE` array in the task and sets the corresponding form control value to null.
     * 
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async clearMessages(): Promise<void>{
        try {
            const res = await this.utilsService.openQuestionDialog('Confirmación', '¿Seguro desea eliminar los mensajes?');
            if(res){
                this.task!.MENSAJE_LIBRE = [];
                this.taskFormData.controls['MENSAJE_LIBRE'].setValue([]);
            }
        } catch (err) {}
    }

    /**
     * Edits the messages of the task.
     * @returns {Promise<void>} A promise that resolves when the messages are edited.
     */
    async editMessages(): Promise<void>{
        try {
            let freeMessages: string[] = [];
            if (this.task?.MENSAJE_LIBRE) {
                for(const m of this.task?.MENSAJE_LIBRE){
                    if(this.utilsService.isFieldValid(m.value)) freeMessages.push(m.value);
                }
            }
            const res = await this.utilsService.openEditTextListDialog(freeMessages);
            if (res) {
                const mess: FreeMessages[] = res.map((m: string) => ({ value: m }));
                this.task!.MENSAJE_LIBRE = mess;
                this.taskFormData.controls['MENSAJE_LIBRE'].setValue(res);
            }
        } catch (err) {}
    }

    /**
     * Shows the phone setting dialog and updates the task's phone status and no answer dates.
     * @returns {Promise<void>} A promise that resolves when the phone setting dialog is closed.
     */
    async showPhoneSetting(): Promise<void> {
        try {
            this.setPhones();
            const { phoneStatus, noAnswerDates } = await this.utilsService.openPhoneStatusDialog(this.task);
            let statuses = [];
            for (const status of phoneStatus) statuses.push({ value: status });
            this.task!.telefonos_cliente = statuses;
            this.taskFormData.controls['telefonos_cliente'].setValue(phoneStatus);

            this.setNoAnswerDates(noAnswerDates);

            this.checkPhonesStatus(phoneStatus);
        } catch (err) {}
    }
    
    /**
     * Sets the phone numbers for the task.
     */
    setPhones(): void {
        if(this.task) {
            if(this.taskFormData.controls['telefono1'].value){
                this.task!.telefono1 = this.taskFormData.controls['telefono1'].value;
            }
            if(this.taskFormData.controls['telefono2'].value){
                this.task!.telefono2 = this.taskFormData.controls['telefono2'].value;
            }
        }
    }

    /**
     * Sets the no answer dates for the task.
     * 
     * @param noAnswerDates - An array of `FechasNoContesta` representing the no answer dates.
     */
    setNoAnswerDates(noAnswerDates: FechasNoContesta[]): void {
        if(this.task && noAnswerDates && noAnswerDates.length > 0) {
            if(this.task.fechas_no_contesta && this.task.fechas_no_contesta.length){
                this.task.fechas_no_contesta = this.task.fechas_no_contesta.concat(noAnswerDates);
                this.taskFormData.controls['fechas_no_contesta'].setValue(this.task.fechas_no_contesta);
            }
            else {
                this.task.fechas_no_contesta = noAnswerDates;
                this.taskFormData.controls['fechas_no_contesta'].setValue(this.task.fechas_no_contesta);
            }
        }
    }

    /**
     * Checks the status of the phones and updates the corresponding properties.
     * @param phoneStatus - An array of phone status strings.
     */
    checkPhonesStatus(phoneStatus: string[]): void {
        this.checkPhone1Status(phoneStatus);
        this.checkPhone2Status(phoneStatus);
    }

    /**
     * Checks the status of phone 1 based on the provided phoneStatus array.
     * Updates the corresponding flags based on the status.
     * @param phoneStatus - An array of phone status strings.
     */
    checkPhone1Status(phoneStatus: string[]): void {
        if (phoneStatus.includes('TEL1 Nº incorrecto')) this.tel1_incorrect = true;
        else this.tel1_incorrect = false;
        
        if (phoneStatus.includes('TEL1 No contesta')) this.tel1_no_answer = true;
        else this.tel1_no_answer = false;
        
        if (phoneStatus.includes('TEL1 No sabe')) this.tel1_dont_know = true;
        else this.tel1_dont_know = false;
        
        if (phoneStatus.includes('TEL1 Nº correcto')) this.tel1_correct = true;
        else {
            this.tel1_correct = false;
            if (phoneStatus.includes('TEL1 Contesta')) this.tel1_correct = true;
        }
    }

    /**
     * Checks the status of the phone numbers and updates the corresponding flags.
     * @param phoneStatus - An array of phone status strings.
     */
    checkPhone2Status(phoneStatus: string[]): void {
        if (phoneStatus.includes('TEL2 Nº incorrecto')) this.tel2_incorrect = true;
        else this.tel2_incorrect = false;
        
        if (phoneStatus.includes('TEL2 No contesta')) this.tel2_no_answer = true;
        else this.tel2_no_answer = false;
        
        if (phoneStatus.includes('TEL2 No sabe')) this.tel2_dont_know = true;
        else this.tel2_dont_know = false;
        
        if (phoneStatus.includes('TEL2 Nº correcto')) this.tel2_correct = true;
        else {
            this.tel2_correct = false;
            if (phoneStatus.includes('TEL2 Contesta')) this.tel2_correct = true;
        }
    }

    /**
     * Reloads the current route.
     */
    reload(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Uploads an image for the task.
     * @returns A Promise that resolves to void.
     */
    async uploadImage(): Promise<void> {
        var formData: any = new FormData();
        const imageData = this.form?.get('image')?.value;
        formData.append('image', imageData);
        formData.append('data', this.photoColumn);

        try {
            const taskData = await this._apiService.uploadTaskImage(formData, this.task!);
            this.task = taskData;
            this.taskFormData.controls[this.photoColumn].setValue(
                this.task![this.photoColumn as keyof WaterTask]
            );
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
    }

    /**
     * Handles the change event for the pendent location toggle.
     * 
     * @param event - The change event emitted by the MatSlideToggle.
     * 
     * Logs the event and the current value of the 'pendent_location' control in the task form data.
     */
    pendentLocationChange(event: MatSlideToggleChange): void {
        const pendent_location = this.taskFormData.controls['pendent_location'].value;
    }
}