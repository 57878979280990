import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Zone } from 'src/app/interfaces/zone';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Subscription } from 'rxjs';
import { Prediction } from '../../../interfaces/place-predictions';
import { FormControl } from '@angular/forms';
import { PlaceDetails } from '../../../interfaces/place-details';
import { bilbaoCenter } from '../../../interfaces/water-task';

@Component({
    selector: 'app-zone-location',
    templateUrl: './zone-location.component.html',
    styleUrls: ['./zone-location.component.scss'],
})
export class ZoneLocationComponent implements OnInit {
    @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

    markers: google.maps.Marker[] = [];
    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<
        string,
        google.maps.MarkerOptions
    >();
    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 15;
    center!: google.maps.LatLngLiteral; 
    options: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        disableDoubleClickZoom: true,
    };

    markerZones: Zone[] = [];

    dirSelected: string = '';

    zone?: Zone;
    zoneId?: string = '';

    zoneSubcription$?: Subscription;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        public filterDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.zoneId = params['id'];
            console.log('**************** activatedRoute ***************');
            console.log(this.zoneId);
        });
    }

    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            this.center = {
                lat: lat, //position.coords.latitude,
                lng: lng, //position.coords.longitude,
            };
        }
    }

    async ngOnInit(): Promise<void> {
        this.showLoading(true);

        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                //Add a delay to input change
                this.placePredictions = await this._apiService.searchPrediction(value);
            }, 1000);
        });

        const company = localStorage.getItem('company');
        this.zoneSubcription$ = this._apiService
            .getDocument('zone', this.zoneId!)
            .subscribe(async (doc: any) => {
                if (!doc) return;
                
                const zone = doc as Zone;
                this.zone = zone;
                this.zone.id = parseInt(this.zoneId!);

                const lat = this.zone?.geolocalizacion?.lat;
                const lng = this.zone?.geolocalizacion?.lng;

                if (!this.center) {
                    this.center = {
                        lat: lat || bilbaoCenter.lat, 
                        lng: lng || bilbaoCenter.lng, 
                    };
                }
                this.addMarker(this.zone);
                this.showLoading(false);
            });
    }

    ngOnDestroy(): void {
        this.zoneSubcription$!.unsubscribe();
    }

    async onClickedMarker(marker: google.maps.Marker) {
        this.infoWindow.open();
        console.log(marker.getPosition()?.toUrlValue());
        const lat = marker.getPosition()!.lat();
        const lng = marker.getPosition()!.lng();

        this.center = {
            lat: lat, //position.coords.latitude,
            lng: lng, //position.coords.longitude,
        };
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    setMarkers(arrayZone: Zone[]) {
        this.markerOptionsMap.clear();
        this.markers = [];
        arrayZone.forEach((zone) => {
            if (zone.geolocalizacion) {
                this.addMarker(zone);
            }
        });
    }

    getMarkerOption(zone: Zone) {
        const markerIconName: string = `assets/img/markers/${this.getCustomMarker(zone)}.svg`;
        const markerIcon: google.maps.Icon = {
            url: markerIconName,
            scaledSize: new google.maps.Size(40, 40),
        };
        const markerOption: google.maps.MarkerOptions = {
            icon: markerIcon,
            // animation: google.maps.Animation.DROP,
            draggable: true,
        };
        return markerOption;
    }

    addMarker(zone: Zone) {
        if (zone.geolocalizacion && zone.geolocalizacion.geohash) {
            const lat = zone.geolocalizacion.lat;
            const lng = zone.geolocalizacion.lng;
            const posHome = new google.maps.LatLng(lat, lng);
            const markerHome = new google.maps.Marker({
                position: posHome,
                title: 'Casa', //This is the hover text of marker
                label: this.zone?.zona, //This is the text uppon the marker
                draggable: true,
            });
            this.markerOptionsMap.set('Casa', this.getMarkerOption(zone));
            this.markers = [];
            this.markers.push(markerHome);
        }
    }

    onDrag(event: any, marker: google.maps.Marker) {
        // console.log('************ onDrag marker ***************');
        // console.log(marker);
        // console.log('************ onDrag marker ***************');
        // console.log('************ event ***************');
        // console.log(event);
        // console.log('************ event ***************');
    }

    async onDragEnd(event: any, marker: google.maps.Marker) {
        this.showLoading(true);

        const lat = event?.latLng?.lat(); //lat drop
        const lng = event?.latLng?.lng(); //lng drop

        const geoFirePoint = this._utilsService.createLatLng(lat, lng);
        if(geoFirePoint) {
            this.zone!.geolocalizacion = geoFirePoint;
            
            const result = await this._apiService.updateDocument('zone', this.zoneId!, this.zone);
            
            if (result) this._utilsService.openSnackBar('Posición actualizada correctamente');
            else this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
        }
        this.showLoading(false);
    }

    getCustomMarker(zone: Zone): string {
        // diaria_radio_hibernar_priority_marker
        let stringMarker = 'cita_priority_marker';
        return stringMarker;
    }

    async mapDblclick(event: google.maps.MapMouseEvent) {
        this.showLoading(true);

        const lat = event?.latLng?.lat() || 0; //lat drop
        const lng = event?.latLng?.lng() || 0; //lng drop

        const geoFirePoint = this._utilsService.createLatLng(lat, lng);
        console.log(geoFirePoint);

        if (geoFirePoint) {
            this.zone!.geolocalizacion = geoFirePoint;
            const result = await this._apiService.updateDocument('zone', this.zoneId!, this.zone);
            
            if (result) this._utilsService.openSnackBar('Posición actualizada correctamente');
            else this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
        }
        this.showLoading(false);
    }

    click(event: google.maps.MapMouseEvent) {
        console.log('*********** click ***********');
        console.log(event);
        console.log('*********** click ***********');
    }

    // getZoneSubscriberTextLine(zone: Zone){
    //   let abonado = `ABONADO ${zone.Numero_de_ABONADO}, ${zone.NOMBRE_ABONADO}`;
    //   return abonado;
    // }
    // getZoneCounterTextLine(zone: Zone){
    //   let type = `${zone.tipo_tarea}, CAL ${zone.CALIBRE}mm, CT ${zone.SERIE}`;
    //   return type;
    // }
}
