<mat-dialog-content class="animate__animated animate__fadeIn animate__slow">
    <div [formGroup]="counterFormData">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div
                            class="row-content-tittle"
                            style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px"
                        >
                            <p style="font-size: large">Datos de contador</p>

                            <button
                                mat-raised-button
                                color="primary"
                                class="button"
                                [disabled]="
                                    !counterFormData.controls['geolocalizacion'].value ||
                                    !counterFormData.controls['agrupationId'].value ||
                                    !counterFormData.controls['numero_serie_contador'].value ||
                                    !counterFormData.controls['numero_serie_modulo'].value
                                "
                                (click)="activateRadio()"
                            >
                                Activar Radio
                            </button>
                        </div>

                        <div>
                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Estado</mat-label>
                                    <mat-select formControlName="status_contador">
                                        <mat-option
                                            *ngFor="let option of [0, 1]"
                                            [value]="option"
                                            >{{ option | counterStatus }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Encargado</mat-label>
                                    <input
                                        formControlName="encargado"
                                        matInput
                                        type="text"
                                        placeholder="Encargado"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Número serie</mat-label>
                                    <input
                                        formControlName="numero_serie_contador"
                                        matInput
                                        type="text"
                                        placeholder="Número serie"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 140px" appearance="fill">
                                    <mat-label>Fecha Instalado</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_fecha_instalado"
                                        formControlName="fecha_instalado"
                                        placeholder="Fecha Instalado"
                                        readonly
                                    />
                                    <mat-datepicker-toggle
                                        matSuffix
                                        [for]="picker_fecha_instalado"
                                    ></mat-datepicker-toggle>
                                    <mat-datepicker #picker_fecha_instalado></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Calibre</mat-label>
                                    <input
                                        formControlName="calibre"
                                        matInput
                                        type="text"
                                        placeholder="Calibre"
                                        [matAutocomplete]="autoCaliber"
                                    />
                                </mat-form-field>
                                <mat-autocomplete #autoCaliber="matAutocomplete">
                                    <mat-option
                                        *ngFor="let caliber of calibers"
                                        [value]="caliber"
                                        [matTooltip]="caliber"
                                    >
                                        {{ caliber }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-form-field appearance="fill">
                                    <mat-label>Longitud</mat-label>
                                    <input
                                        formControlName="longitud"
                                        matInput
                                        type="text"
                                        placeholder="Longitud"
                                        [matAutocomplete]="autoLong"
                                    />
                                </mat-form-field>

                                <mat-autocomplete #autoLong="matAutocomplete">
                                    <mat-option
                                        *ngFor="let longitude of longitudes"
                                        [value]="longitude"
                                        [matTooltip]="longitude"
                                    >
                                        {{ longitude }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-form-field appearance="fill">
                                    <mat-label>Ruedas</mat-label>
                                    <input
                                        formControlName="ruedas"
                                        matInput
                                        type="text"
                                        placeholder="Ruedas"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de fluido</mat-label>
                                    <input
                                        formControlName="tipo_fluido"
                                        matInput
                                        type="text"
                                        placeholder="Tipo de fluido"
                                        [matAutocomplete]="autoFluid"
                                    />
                                </mat-form-field>
                                <mat-autocomplete #autoFluid="matAutocomplete">
                                    <mat-option
                                        *ngFor="let typeCounter of typeCounters"
                                        [value]="typeCounter"
                                        [matTooltip]="typeCounter"
                                    >
                                        {{ typeCounter }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Código Marca</mat-label>
                                    <input
                                        formControlName="codigo_marca"
                                        matInput
                                        type="text"
                                        placeholder="Código Marca"
                                        [matAutocomplete]="autoMark"
                                    />
                                </mat-form-field>
                                <mat-autocomplete #autoMark="matAutocomplete">
                                    <mat-option
                                        *ngFor="let mark of marks_code"
                                        [value]="mark"
                                        [matTooltip]="mark"
                                    >
                                        {{ mark }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-form-field appearance="fill">
                                    <mat-label>Marca</mat-label>
                                    <input
                                        formControlName="marca"
                                        matInput
                                        type="text"
                                        placeholder="Marca"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Modelo</mat-label>
                                    <input
                                        formControlName="modelo"
                                        matInput
                                        type="text"
                                        placeholder="Modelo"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Lectura inicial</mat-label>
                                    <input
                                        formControlName="lectura_inicial"
                                        matInput
                                        type="text"
                                        placeholder="Lectura inicial"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Inventariado</mat-label>
                                    <input
                                        [value]="
                                            this.counter?.inventory_sync! | counterInventorySync
                                        "
                                        matInput
                                        type="text"
                                        placeholder="Inventariado"
                                        readonly
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Depósito</mat-label>
                                    <input
                                        formControlName="deposit"
                                        matInput
                                        type="text"
                                        placeholder="deposit"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Dígito de control</mat-label>
                                    <input
                                        formControlName="control_digit"
                                        matInput
                                        type="text"
                                        placeholder="control_digit"
                                        readonly
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 140px" appearance="fill">
                                    <mat-label>Fecha de Inventario</mat-label>
                                    <input
                                        matInput
                                        [matDatepicker]="picker_inventory_date"
                                        formControlName="inventory_date"
                                        placeholder="Fecha de Inventario"
                                        readonly
                                    />
                                    <mat-datepicker #picker_inventory_date></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Código clase</mat-label>
                                    <input
                                        formControlName="codigo_clase"
                                        matInput
                                        type="text"
                                        placeholder="clase"
                                        [matAutocomplete]="autoClassCounter"
                                    />
                                </mat-form-field>
                                <mat-autocomplete #autoClassCounter="matAutocomplete">
                                    <mat-option
                                        *ngFor="let classCounter of classes_code"
                                        [value]="classCounter"
                                        [matTooltip]="classCounter"
                                    >
                                        {{ classCounter }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-form-field appearance="fill">
                                    <mat-label>Clase</mat-label>
                                    <input
                                        formControlName="clase"
                                        matInput
                                        type="text"
                                        placeholder="Clase"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de radio</mat-label>
                                    <input
                                        formControlName="tipo_radio"
                                        matInput
                                        type="text"
                                        placeholder="Tipo de radio"
                                        [matAutocomplete]="auto"
                                    />
                                </mat-form-field>
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                        *ngFor="let radio of radios"
                                        [value]="radio"
                                        [matTooltip]="radio"
                                    >
                                        {{ radio }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Agrupación</mat-label>
                                    <mat-select formControlName="agrupationId">
                                        <mat-option
                                            *ngFor="let option of agrupationIds"
                                            [value]="option"
                                            >{{ option | agrupationPipe }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Número serie del módulo</mat-label>
                                    <input
                                        formControlName="numero_serie_modulo"
                                        matInput
                                        type="text"
                                        placeholder="Modulo"
                                    />
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>URL Google Maps</mat-label>
                                    <input
                                        [value]="
                                            _utilsService.getGeolocationUrl(
                                                counterFormData.controls['geolocalizacion'].value
                                            )
                                        "
                                        matInput
                                        type="text"
                                        placeholder="URL Google Maps"
                                        readonly
                                    />
                                    <button
                                        *ngIf="counterFormData.controls['geolocalizacion'].value"
                                        matTooltip="Abrir ubicación de contador en Google Maps"
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Open google maps"
                                    >
                                        <a
                                            [href]="
                                                _utilsService.getGeolocationUrl(
                                                    counterFormData.controls['geolocalizacion']
                                                        .value
                                                )
                                            "
                                            target="_blank"
                                        >
                                            <img
                                                src="assets/img/google_maps_icon.svg"
                                                width="20"
                                                alt="Open google maps"
                                            />
                                        </a>
                                    </button>
                                </mat-form-field>
                                <button
                                    *ngIf="counterFormData.controls['numero_serie_contador'].value"
                                    matTooltip="Obtener geolocalización de tarea"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Get location"
                                    (click)="getTaskLocation()"
                                >
                                    <mat-icon>map</mat-icon>
                                </button>
                            </div>

                            <div
                                class="row-content-slide"
                                style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px"
                            >
                                <mat-form-field appearance="fill">
                                    <mat-label>Radio Activada</mat-label>
                                    <input
                                        [value]="
                                            this.counter?.radio_activated! | counterActivatedStatus
                                        "
                                        matInput
                                        type="text"
                                        placeholder="Radio Activada"
                                        readonly
                                    />
                                </mat-form-field>

                                <mat-slide-toggle
                                    style="margin-left: 5%; margin-top: 2%"
                                    color="primary"
                                    [checked]="this.counter?.activate_in_field_enabled"
                                    formControlName="activate_in_field_enabled"
                                    >Activación en campo</mat-slide-toggle
                                >
                                <button
                                    mat-raised-button
                                    style="margin-left: 40px; margin-top: 5px; margin-bottom: 30px"
                                    color="primary"
                                    class="button"
                                    [disabled]="
                                        !counterFormData.controls['numero_serie_contador'].value
                                    "
                                    (click)="openActivations()"
                                >
                                    Ver activaciones
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="counter ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="counterSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="counter">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ counterId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>
