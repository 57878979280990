/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Counter, getCounterFormControls } from 'src/app/interfaces/counter';
import { counter_status } from '../../interfaces/counter';
import { MyLatLng } from 'src/app/interfaces/lat-lng';
import { IpcService } from 'src/app/services/ipc.service';
import { Router } from '@angular/router';
import { ClassCounter } from 'src/app/interfaces/class-counter';
import { Mark } from 'src/app/interfaces/mark';
import { TypeCounter } from 'src/app/interfaces/type-counter';
import { TypeRadius } from 'src/app/interfaces/type-radius';
import { Longitude } from 'src/app/interfaces/longitude';
import { Agrupation } from 'src/app/interfaces/agrupation';
import { Caliber } from 'src/app/interfaces/caliber';
import { LoraResponse } from '../../interfaces/lora-response';

/**
 * Represents the CounterComponent class.
 * This component is responsible for managing the counter functionality.
 */
@Component({
    selector: 'app-counter',
    templateUrl: './counter.component.html',
    styleUrls: ['./counter.component.scss'],
})
export class CounterComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    counterId: string = '';
    counter?: Counter;
    opcion1: boolean = false;
    counterFormData: FormGroup = getCounterFormControls();

    counterSubcription$?: Subscription;

    results: string[] = [];

    classes: ClassCounter[] = [];
    marks: Mark[] = [];
    typeCounters: string[] = [];
    calibers: string[] = [];
    longitudes: string[] = [];
    radios: string[] = [];
    agrupations: string[] = [];
    agrupationIds: number[] = [];
    marks_code: string[] = [];
    classes_code: string[] = [];

    /**
     * Represents the CounterComponent class.
     * This component is responsible for managing the counter functionality.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private _electronService: IpcService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
        this.counterId = data.counterId;
    }

    /**
     * Initializes the component.
     * If `counterId` is provided, it retrieves the counter data from the API and populates the form.
     * If `counterId` is not provided, it fetches additional data from the API and sets up form controls.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        if (this.counterId) {
            this.showLoading(true);
            this.counterSubcription$ = this._apiService
                .getDocument(`counter`, this.counterId)
                .subscribe(async (doc: any) => {
                    this.addCounterData(doc);
                });
        }
        else await this.fillFormData();

        const groups = await this._apiService.getAgrupations();
        this.agrupations = groups.map((agrupation: Agrupation) => `${agrupation.agrupationId}`).sort();
        this.agrupationIds = groups.map((agrupation: Agrupation) => agrupation.agrupationId!);
    }

    /**
     * Lifecycle hook that is called when the component is being destroyed.
     * It is used to perform any necessary cleanup before the component is removed from the DOM.
     */
    ngOnDestroy(): void {
        this.counterSubcription$?.unsubscribe();
    }

    /**
     * Adds counter data to the component.
     * 
     * @param doc - The counter data to be added.
     */
    addCounterData(doc: any) {
        if (!doc) {
            this._utilsService.openSnackBar('Error obteniendo datos de counter', 'error');
            this.showLoading(false);
            return;
        }
        const counter = doc as Counter;
        counter.id = parseInt(this.counterId);
        this.counter = counter;
        const counterFormData = getCounterFormControls();
        const keys = Object.keys(counter);
        for (let key of keys) {
            try {
                let value: any = counter[key as keyof Counter];
                if (value || value === 0) {
                    if (this._utilsService.isValidDate(value)) value = value;
                    counterFormData.controls[key].setValue(value);
                }
            } catch (err) {
                console.log('============= key =============');
                console.log(key);
            }
        }
        this.counterFormData = counterFormData;
        this.showLoading(false);
    }

    /**
     * Fills the form data by fetching necessary data from the API.
     * - Fetches classes from the API and assigns them to the `classes` property.
     * - Fetches marks from the API and assigns them to the `marks` property.
     * - Maps the `marks` array to create `marks_code` array with formatted strings.
     * - Maps the `classes` array to create `classes_code` array with formatted strings.
     * - Fetches type counters from the API and assigns them to the `typeCounters` property.
     * - Fetches calibers from the API, parses them as integers, and assigns them to the `calibers` property.
     * - Fetches longitudes from the API, parses them as integers, and assigns them to the `longitudes` property.
     * - Fetches radios from the API and assigns them to the `radios` property.
     * - Subscribes to changes in the 'codigo_marca' form control and updates the form values accordingly.
     * - Subscribes to changes in the 'codigo_clase' form control and updates the form values accordingly.
     */
    async fillFormData() {
        this.classes = await this._apiService.getClassCounter();
        this.marks = await this._apiService.getMarks();
        this.marks_code = this.marks.map(
            (mark: Mark) => `${mark.codigo_marca} - ${mark.marca} - ${mark.modelo}`
        );
        this.classes_code = this.classes.map(
            (classCounter: ClassCounter) =>
                `${classCounter.codigo_clase} - ${classCounter.clase}`
        );
        this.typeCounters = (await this._apiService.getDocuments<TypeCounter>('type_counter'))
            .map((typeCounter: TypeCounter) => `${typeCounter.tipo}`)
            .sort();
        this.calibers = (await this._apiService.getDocuments<Caliber>('caliber'))
            .map((caliber: Caliber) => parseInt(`${caliber.calibre}`.trim()))
            .sort((a, b) => a - b)
            .map((caliber: number) => `${caliber}`);
        this.longitudes = (await this._apiService.getDocuments<Longitude>('longitude'))
            .map((longitude: Longitude) => parseInt(`${longitude.longitud}`.trim()))
            .sort((a, b) => a - b)
            .map((longitude: number) => `${longitude}`);
        this.radios = (await this._apiService.getDocuments<TypeRadius>('type_radius'))
            .map((typeRadius: TypeRadius) => `${typeRadius.radio}`)
            .sort();
        
        this.setFormControls();
    }

    /**
     * Sets up the form controls for the counter component.
     */
    setFormControls() {
        this.counterFormData.controls['codigo_marca'].valueChanges.subscribe(
            async (value: any) => {
                if (value && value.length >= 3) {
                    value = value.substring(0, 3);
                    this.counterFormData.controls['codigo_marca'].setValue(value);
                    const mark = this.marks.find((mark: Mark) => mark.codigo_marca == value);
                    if (mark) {
                        this.counterFormData.controls['marca'].setValue(mark.marca);
                        this.counterFormData.controls['modelo'].setValue(mark.modelo);
                    }
                }
            }
        );
        this.counterFormData.controls['codigo_clase'].valueChanges.subscribe(
            async (value: any) => {
                if (value && value.length >= 1) {
                    value = value.substring(0, 1);
                    this.counterFormData.controls['codigo_clase'].setValue(value);
                    const classCounter = this.classes.find(
                        (classCounter: ClassCounter) => classCounter.codigo_clase == value
                    );
                    if (classCounter) {
                        this.counterFormData.controls['clase'].setValue(classCounter.clase);
                    }
                }
            }
        );
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - The loading state to set. If `true`, the spinner will be shown. If `false`, the spinner will be hidden.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('counterSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('counterSpinner');
        }
    }

    /**
     * Saves the form data by extracting values from the counterFormData controls
     * and assigning them to the corresponding properties of the counter object.
     */
    saveFormData() {
        const keys = Object.keys(this.counterFormData.controls);
        let counter: any = {};
        for (let key of keys) {
            let value = this.counterFormData.controls[key].value; //this.counter[key as keyof Counter];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                counter![key as keyof Counter] = value;
            } catch (error) {}
        }
        if (!counter['status_contador']) counter['status_contador'] = counter_status.AVAILABLE;
        counter['date_time_modified'] = new Date();
        this.counter = counter;
    }

    /**
     * Saves the changes made to the counter.
     * If the counter already exists, it updates the existing counter.
     * If the counter is new, it adds the counter to the database.
     * Shows success or error messages using the SnackBar service.
     * Closes the counter dialog after saving the changes.
     */
    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.counterId) {
            const result: boolean = await this._apiService.updateDocument(
                `counter`,
                this.counterId,
                this.counter
            );
            if (result) this._utilsService.openSnackBar('Contador actualizado correctamente');
            else this._utilsService.openSnackBar('Error actualizando contador', 'error');
            
        } else {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                this.counter!.company = parseInt(company!);
                this.counter!.manager = parseInt(manager!);
                const counterId = await this._apiService.addDocument(`counter`, this.counter);
                if (counterId) {
                    this.counter!.id = counterId;
                    this.counterId = counterId;
                    this._utilsService.openSnackBar('Contador añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo contador', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo contador', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeCounterDialog(this.counter!);
    }

    /**
     * Activates the radio for the counter.
     * Retrieves tasks based on the counter's serial number and updates the counter's geolocation if available.
     * Activates the counter using the API service and displays a success message if activation is successful.
     * Closes the counter dialog and hides the loading spinner.
     */
    async activateRadio() {
        if (this._utilsService.isFieldNotValid(this.counter!.agrupationId)) {
            const agrupationId = await this._apiService.selectAgrupdationId();
            if (agrupationId) this.counter!.agrupationId = agrupationId;
            else return;
        }
        this.showLoading(true);
        const tasks = await this._apiService.getTasks([['seriedv', '==', this.counter!.numero_serie_contador]]);
        if (tasks.length) {
            const geolocation = this._utilsService.getRightCoordinates(tasks[0]);
            if (geolocation) this.counter!.geolocalizacion = geolocation;
        }
        if (this.counterId && this.counter) {
            let counters: Counter[] = [this.counter];
            const loraResponses: LoraResponse[] = await this._apiService.activateCounters(counters);
            let activationOk: boolean = true;
            for (let loraResponse of loraResponses) { 
                if (loraResponse.code) {
                    this._utilsService.openSnackBar(`Error activando la radio: ${loraResponse.message}`, 'error');
                    activationOk = false;
                    break;
                }
            }
            if (loraResponses && loraResponses.length && activationOk) {
                this._utilsService.openSnackBar('Radio activada correctamente');
            } 
        }
        this.showLoading(false);
        this._utilsService.closeCounterDialog(this.counter!);
    }

    /**
     * Opens the activation logs for the counter.
     * If the application is running in an Electron environment, it closes the counter dialog and navigates to the activation logs page.
     * If the application is running in a web browser, it opens a new tab with the activation logs page.
     */
    async openActivations() {
        let serial_number: string = '';
        if (this.counter && this.counter.numero_serie_contador) {
            serial_number = this.counter.numero_serie_contador;
        }
        if (this._electronService.isElectronApp()) {
            this._utilsService.closeCounterDialog(this.counter!);
            this.router.navigate(['/activation-logs', serial_number]);
        } else {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(['/activation-logs', serial_number])
            );
            window.open(url, '_blank');
        }
    }

    /**
     * Retrieves the task location for the counter.
     * If a task is found with the same counter serial number, assigns the task's geolocation to the counter.
     * Updates the form control with the geolocation value.
     * Displays a snackbar message indicating the result.
     */
    async getTaskLocation(): Promise<void> {
        const tasks = await this._apiService.getTasks([
            ['seriedv', '==', this.counter?.numero_serie_contador],
        ]);
        if (tasks && tasks.length > 0) {
            const waterTask = tasks[0];
            if (waterTask.codigo_de_localizacion) {
                const geolocation: MyLatLng = waterTask.codigo_de_localizacion;
                if (this.counter) this.counter!.geolocalizacion = geolocation;
                this.counterFormData.controls['geolocalizacion'].setValue(geolocation);
                this._utilsService.openSnackBar('Geolocalización asignada');
                return;
            }
        }
        this._utilsService.openSnackBar('No hay tarea con este contador asignado', 'warning');
    }
}
