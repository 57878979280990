/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faPhone, faClock, faBuilding, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PendingCall, getPendingCallFormControls } from '../../../interfaces/pending-call';
import { IpcService } from '../../../services/ipc.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pending-call',
    templateUrl: './pending-call.component.html',
    styleUrls: ['./pending-call.component.scss']
})
export class PendingCallComponent implements OnInit, OnDestroy {
    faPhone = faPhone;
    faClock = faClock;
    faBuilding = faBuilding;
    faTrash = faTrash;
    loading: boolean = false;
    pendingCallId: string = '';
    pendingCall?: PendingCall;

    pendingCallFormData: FormGroup = getPendingCallFormControls();

    pendingCallSubscription$?: Subscription;

    percentage: number = 0;
    uploading = false;

    /**
     * Represents the PendingCallComponent class.
     * This component is responsible for displaying and managing the Vonage pending call data.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder,
        private router: Router,
        public electronService: IpcService,
    ) {
        this.pendingCallId = data.pendingCallId;
    }

    /**
     * Initializes the component.
     * If `pendingCallId` is provided, it retrieves the pending call data from the API
     * and populates the form controls with the retrieved data.
     * If the data is not found, it displays an error message.
     */
    async ngOnInit(): Promise<void> {
        if (this.pendingCallId) {
            this.getDocumentData();
        }
    }

    /**
     * Fetches document data for a pending call and processes it.
     * 
     * This method subscribes to the observable returned by the `_apiService.getDocument` method,
     * which fetches the document data for a pending call identified by `this.pendingCallId`.
     * 
     * If the document data is successfully retrieved, it is passed to the `addData` method for further processing.
     * If the document data is not found, an error message is displayed using the `_utilsService.openSnackBar` method.
     * 
     * The loading indicator is hidden after the document data is processed or if an error occurs.
     * 
     * @returns {void}
     */
    getDocumentData(): void {   
        this.showLoading(true);
        this.pendingCallSubscription$ = this._apiService
            .getDocument(`vonage`, this.pendingCallId)
            .subscribe(async (doc: any) => {
                if (!doc) {
                    this._utilsService.openSnackBar('Error obteniendo datos del llamadas pendientes', 'error');
                    this.showLoading(false);
                    return;
                }
                this.addData(doc);
                this.showLoading(false);
            });
    }

    /**
     * Adds data to the pending call form.
     * 
     * @param doc - The document containing the pending call data.
     */
    addData(doc: any){
        const pendingCall = doc as PendingCall;
        pendingCall.id = parseInt(this.pendingCallId);
        
        this.pendingCall = pendingCall;
        
        const formData = getPendingCallFormControls();
        const keys = Object.keys(pendingCall);
        for (let key of keys) {
            let value: any = pendingCall[key as keyof PendingCall];
            if (value) {
                if (this._utilsService.isValidDate(value)) value = value;
                formData.controls[key].setValue(value);
            }
        }
        this.pendingCallFormData = formData;
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It unsubscribes from the pendingCallSubscription$ observable if it exists.
     */
    ngOnDestroy(): void {
        this.pendingCallSubscription$?.unsubscribe();
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - The loading state to set. If `true`, the spinner will be shown; if `false`, the spinner will be hidden.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('pendingCallSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('pendingCallSpinner');
        }
    }

    /**
     * Asynchronously changes the status of a pending call.
     * 
     * This method checks if there is a pending call ID. If there is, it updates the status of the call to pending
     * by sending a request to the API service. Depending on the response, it shows a success or error message
     * using the utility service.
     * 
     * @returns {Promise<void>} A promise that resolves when the status change operation is complete.
     */
    async changeStatus(): Promise<void> {
        if(this.pendingCallId) {
            this.showLoading(true);
            const res = await this._apiService.updateDocument('vonage', this.pendingCallId, { is_pending: false });
            this.showLoading(false);
            
            if(res) this._utilsService.openSnackBar('Estado de la llamada actualizado');
            else this._utilsService.openSnackBar('Error actualizando el estado de la llamada', 'error');

            if(this.pendingCallSubscription$) this.pendingCallSubscription$.unsubscribe();
            this.getDocumentData();
        }
    }

    /**
     * Opens a task based on the pending call's task ID.
     * 
     * If the application is running in an Electron environment, it navigates to the task route within the app.
     * Otherwise, it opens the task route in a new browser tab.
     * 
     * @returns {Promise<void>} A promise that resolves when the navigation or URL opening is complete.
     */
    async openTask(): Promise<void> {
        const taskId = this.pendingCall?.task_id;
        if(taskId) {
            if (this.electronService.isElectronApp()) {
                this.router.navigate(['/task', taskId]);
            } else {
                const url = this.router.serializeUrl(this.router.createUrlTree(['/task', taskId]));
                window.open(url, '_blank');
            }
        }
    }
}
