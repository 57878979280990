/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Itac } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { FilterComponent } from '../share/filter/filter.component';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MySqlService } from 'src/app/services/mysql.service';
import { PlaceDetails } from '../../interfaces/place-details';
import { Prediction } from '../../interfaces/place-predictions';
import { ElectronService } from 'ngx-electron';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { bilbaoCenter, priority_status } from '../../interfaces/water-task';

@Component({
    selector: 'app-itac-location',
    templateUrl: './itac-location.component.html',
    styleUrls: ['./itac-location.component.scss'],
})
export class ItacLocationComponent implements OnInit {
    @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    filterDialogRef!: MatDialogRef<FilterComponent, any>;

    markers: google.maps.Marker[] = [];
    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<
        string,
        google.maps.MarkerOptions
    >();
    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 15;
    center!: google.maps.LatLngLiteral;
    options = {
        origin: new google.maps.LatLng(bilbaoCenter.lat, bilbaoCenter.lng),
        types: [],
        componentRestrictions: { country: 'ES' },
        fields: ['place_id', 'geometry', 'name'],
        bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(19.43488390019791, -164.271592),
            new google.maps.LatLng(51.943852774877634, -79.896592)
        ),
        strictBounds: false,
    };
    mapOptions: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        disableDoubleClickZoom: true,
    };

    markerItacs: Itac[] = [];

    dirSelected: string = '';

    itac?: Itac;
    itacId?: string = '';

    itacSubcription$?: Subscription;

    autocomplete?: any;
    formData: FormGroup = new FormGroup({
        address: new FormControl(),
    });

    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        public filterDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private activatedRoute: ActivatedRoute,
        public _electronService: ElectronService,
        public location: Location
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.itacId = params['id'];
        });
        this.itac = this._utilsService.itacSelected;
    }

    /**
     * @brief Toggles 3D mode on the map by adjusting the tilt option.
     * @details This function sets the tilt option of the map to 45 when active is true (enabling 3D mode),
     * and sets it to 0 when active is false (disabling 3D mode).
     * @param active - A boolean indicating whether 3D mode should be active.
     */
    onMap3D(event: MatSlideToggleChange) {
        this.mapOptions = { ...this.mapOptions, tilt: event.checked ? 45: 0 };
    }

    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            this.center = {
                lat: lat, //position.coords.latitude,
                lng: lng, //position.coords.longitude,
            };
            setTimeout(async () => {
                try {
                    const result = await this._utilsService.openQuestionDialog(
                        'Confirmación',
                        '¿Desea situar marcador en esta localización?'
                    );
                    if (result) {
                        this.showLoading(true);
                        await this._updateMarkerPosition(lat, lng);
                        this.showLoading(false);
                    }
                } catch (err) {}
            }, 1000);
        }
    }

    async ngOnInit(): Promise<void> {
        this.showLoading(true);

        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                //Add a delay to input change
                try {
                    this.placePredictions = await this._apiService.searchPrediction(value);
                } catch (err) {}
            }, 1000);
        });

        this.itacSubcription$ = this._apiService
            .getItac(this.itacId!)
            .subscribe(async (doc: any) => {
                if (!doc) {
                    this._utilsService.openSnackBar('Error obteniendo datos de ITAC', 'error');
                    this.showLoading(false);
                    return;
                }
                
                const itac = doc as Itac;
                this.itac = itac;
                this.itac.id = parseInt(this.itacId!);

                const lat = this.itac?.geolocalizacion?.lat;
                const lng = this.itac?.geolocalizacion?.lng;

                const dir = this.itac.itac;
                this.inputSearchControl.setValue(dir);
                if (!this.center) {
                    this.center = {
                        lat: lat || bilbaoCenter.lat, //position.coords.latitude,
                        lng: lng || bilbaoCenter.lng, //position.coords.longitude,
                    };
                }
                this.addMarker(this.itac);
                this.showLoading(false);
            });
    }

    ngOnDestroy(): void {
        this.itacSubcription$!.unsubscribe();
    }

    getDirOfItac(itac: Itac) {
        return this.itac?.itac;
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    getItacImage(itac: Itac) {
        return this._utilsService.getItacImage(itac);
    }

    async getLocations() {
        let arrayItac: Itac[] = [];
        arrayItac = await this._mySqlService.getItacs();
        this.setMarkers(arrayItac);
        this.showLoading(false);
    }

    setMarkers(arrayItac: Itac[]) {
        this.markerOptionsMap.clear();
        this.markers = [];
        arrayItac.forEach((itac) => {
            if (itac.geolocalizacion) {
                this.addMarker(itac);
            }
        });
    }

    getMarkerOption(itac: Itac) {
        const markerIconName: string = `assets/img/markers/${this._utilsService.getCustomMarker(itac)}.svg`;
        const markerIcon: google.maps.Icon = {
            url: markerIconName,
            scaledSize: new google.maps.Size(40, 40),
        };
        const markerOption: google.maps.MarkerOptions = {
            icon: markerIcon,
            // animation: google.maps.Animation.DROP,
            draggable: true,
        };
        return markerOption;
    }

    addMarker(itac: Itac) {
        if (itac.geolocalizacion) {
            const lat = itac.geolocalizacion.lat;
            const lng = itac.geolocalizacion.lng;
            const posHome = new google.maps.LatLng(lat, lng);
            const markerHome = new google.maps.Marker({
                position: posHome,
                title: 'Casa', //This is the hover text of marker
                label: this.itac?.itac, //This is the text uppon the marker
                draggable: true,
            });
            this.markerOptionsMap.set('Casa', this.getMarkerOption(itac));
            this.markers = [];
            this.markers.push(markerHome);
        }
    }

    onDrag(event: any, marker: google.maps.Marker) {
    }

    async onDragEnd(event: any, marker: google.maps.Marker) {
        this.showLoading(true);

        const lat = event?.latLng?.lat(); //lat drop
        const lng = event?.latLng?.lng(); //lng drop

        const latLng = this._utilsService.createLatLng(lat, lng);

        if(latLng) {
            this.itac!.geolocalizacion = latLng;
            this.itac!.pendent_location = false;
            
            const result = await this._apiService.updateItac(this.itacId!, this.itac);
            
            if (result) {
                this._utilsService.openSnackBar('Posición actualizada correctamente');
                await this.updateTasksInPosition();
            }
            else this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
        }
        this.showLoading(false);
    }

    async mapDblclick(event: google.maps.MapMouseEvent) {
        this.showLoading(true);

        const lat = event?.latLng?.lat() || 0; //lat drop
        const lng = event?.latLng?.lng() || 0; //lng drop
        await this._updateMarkerPosition(lat, lng);

        this.showLoading(false);
    }

    async _updateMarkerPosition(lat: number, lng: number) {
        const latLng = this._utilsService.createLatLng(lat, lng);

        if(!latLng) return; 

        this.itac!.geolocalizacion = latLng;
        this.itac!.pendent_location = false;

        const result = await this._apiService.updateItac(this.itacId!, this.itac);

        if (result) {
            if (this.itac) this.addMarker(this.itac);
            this._utilsService.openSnackBar('Posición actualizada correctamente');
            await this.updateTasksInPosition();
        } else {
            this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
        }
    }

    async updateTasksInPosition() {
        const tasks = await this._apiService.getTasks([
            ['codigo_de_geolocalizacion', '==', this.itac?.codigo_itac],
        ]);

        if (tasks.length < 1) return;

        const positionCounter = this.itac?.geolocalizacion;

        let data: any = {};
        if (positionCounter) {
            data['codigo_de_localizacion'] = positionCounter;
            data['pendent_location'] = false;
            data['url_geolocalizacion'] = this._utilsService.getGeolocationUrl(positionCounter);
        }
        const ids = tasks.map((task) => task.id!);

        const res = await this._apiService.updateTasks(ids, data, true);
        if (res) return;
        setTimeout(() => {
            this._utilsService.openSnackBar('Error actualizando de posición tareas', 'error');
        }, 3000);
    }

    click(event: google.maps.MapMouseEvent) {
        console.log('*********** click ***********');
        console.log(event);
        console.log('*********** click ***********');
    }
}
